import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {fetchDocControls} from "../../../../actions/doc_control_actions";
import {getMediaTypesForDocControl} from "../../../../actions/fieldValue_actions";
import paginationFactory from "react-bootstrap-table2-paginator";
// import {AssetCheckoutModal} from "./asset_checkout_modal";
// import {AssetCheckinModal} from "./asset_checkin_modal";
// import {UploadDialogForOtherEntities} from './assets_upload';
import {DOC_CONTROL_DOC, extractCSVDataForEntity} from "../../../../actions/person_csvupload_action";
import {CommonUtils, DateUtils} from "../../../../actions/common_utils";
import {ChangeStorageModal} from "./change_storage_modal";
import {DestroyDocControlModal} from './destroy_doc_modal';
import {UploadDialogForOtherEntities} from "../asset/assets_upload";
import {CSV_UPLOAD_DOC_CONTROLS} from "../../../../actions/action_constants";
import { DocList_Filter } from "./docList_filter";
class DocControlListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            sizePerPage: 10,
            filter:{
                controlNumber:"",
                description:"",
                classificationLevel:"",
                workingPaperStr:"",
                destroyed:"",
                mediaType:"",
                contractNumber:"",
            } 
        }
    }

    fetchDocControlsAsync = async () => {
        try {
            let docControls = await fetchDocControls();
            for (let docControl of docControls){
                docControl.destroyed = docControl.destroyed?'Yes':'No';
                docControl.mediaType = docControl.mediaType?docControl.mediaType.displayName:docControl.mediaType;
            }
            console.log('Doc Controls are -', docControls)
            this.setState({
                docControls
            })
            console.log('docControls fetched');
        } catch (e) {
            console.log('Error found ', e);
        }
    }

    fetchDropDownOptions = async () => {
        try {
            let mediaTypeArray = await getMediaTypesForDocControl();
            const mediaTypes = CommonUtils.forDropDownWithEnum(mediaTypeArray)
            this.setState({
                mediaTypes
            })
        } catch (e) {
            console.log('Error found ', e);
        }
    }
    componentDidMount() {
        this.fetchDropDownOptions();
        this.fetchDocControlsAsync();
    }

    getCellLink = (row, cellData) => {
        return (
            <Link to={`/org/dash/docControl/detail/${row.id}`}>{cellData}</Link>
        );
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div className="table_title">
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        const {mediaTypes} = this.state;
        const trueFalseOptions = [
            {value: "Yes", label: "Yes"},
            {value: "No", label: "No"}
        ]

        return [{
            dataField: 'controlNumber',
            text: 'Control Number',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            //filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'description',
            text: 'Description',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            //filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'classificationLevel',
            text: 'Classification Level',
            formatter: (cell, row) => this.getCellLink(row, cell.displayName? cell.displayName : cell),
            sort: true,
           // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'workingPaperStr',
            text: 'Is Working Paper',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: selectFilter({
            //     options: trueFalseOptions
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: "docCreatedDate",
            text: "Created Date",
            //formatter: (cell, row) => this.getCellLink(row, DateUtils.getDateString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: "docReceivedDate",
            text: "Received Date",
            //formatter: (cell, row) => this.getCellLink(row, DateUtils.getDateString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'destroyed',
            text: 'Is Destroyed',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: selectFilter({
            //     options: trueFalseOptions
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'mediaType',
            text: 'Media Type',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: selectFilter({
            //     options: mediaTypes
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'contractNumber',
            text: 'Contract Number',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
           // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <ChangeStorageModal docControl={row} onFinish={this.fetchDocControlsAsync}/>
                    <DestroyDocControlModal docControl={row} onFinish={this.fetchDocControlsAsync}/>
                </ButtonToolbar>
            )
        }];
    }

    onDocControlUpload = (doc) => { 
        this.props.extractCSVDataForEntity(doc, DOC_CONTROL_DOC, CSV_UPLOAD_DOC_CONTROLS);;
        this.props.history.push("/org/dash/docControl/uploadToOrg");
    }
    onFilterClick = async (values) => { 
        this.setState({
            filter: values,
            page:1
        })
    }
    docListFilterby = () => {
        let { filter ,docControls} = this.state;
        let filteredData = docControls.filter(item =>
            (filter.classificationLevel === "" || String(item.classificationLevel.displayName).toLowerCase().includes(filter.classificationLevel.toLowerCase())) &&
            (filter.contractNumber === "" || (item.contractNumber && String(item.contractNumber).toLowerCase().includes(filter.contractNumber.toLowerCase()))) &&
            (filter.controlNumber === "" || String(item.controlNumber).toLowerCase().includes(filter.controlNumber.toLowerCase())) &&
            (filter.description === "" || String(item.description).toLowerCase().includes(filter.description.toLowerCase())) &&
            (filter.destroyed === "" ||  String(item.destroyed).toLowerCase() === filter.destroyed.toLowerCase()) &&
            (filter.mediaType === "" || String(item.mediaType).toLowerCase() === filter.mediaType.toLowerCase()) &&
            (filter.workingPaperStr === "" || String(item.workingPaperStr).toLowerCase() === filter.workingPaperStr.toLowerCase())
        )
        return filteredData;
    }
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Document Control
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        let {docControls, page, sizePerPage,filter} = this.state;

        if (!docControls) {
            return <div>Loading...</div>
        }

        const remoteSettings = {
            filter: true,
            pagination: false,
            sort: false,
            cellEdit: false
        };

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <UploadDialogForOtherEntities accept={['.csv']} onUpload={this.onDocControlUpload} entityBeingUploaded={'Document Control'}/>
                            <Link className="btn btn-primary" to="/org/dash/docControl/new">New Document Control</Link>
                        </ButtonToolbar>
                    </div>
                )}
                 <div className="d-flex">
                    <h3 className="page_title_left">Document Control</h3>
                      <DocList_Filter filters={filter} onSubmit={this.onFilterClick} />  
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.docListFilterby()}
                                pagination={paginationFactory({page, sizePerPage, totalSize: docControls.length})}
                                columns={this.getColumns()} filter={filterFactory()}/>
            </Fragment>
        )
    }
}


export const DocControlList =  connect(null, {extractCSVDataForEntity, showSuccessGrowl, showErrorGrowl})(DocControlListComponent);