/**
 * Created by pshivaraman on 2/14/19.
 */

import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    addContractToFacilities,
    fetchByContractIdAndActiveFor,
    searchFacilitiesNotActivelyPartOfContract,
    removeFacilityFromContract
} from "../../../../../actions/facility_actions";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";
import AddFacilityModal from "../../../../../components/contract/add_facility_modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

class ContractFacilityList extends Component {
    constructor(props) {
        super(props);

        this.onFacilitiesAdd = this.onFacilitiesAdd.bind(this);
        this.facilitiesSearch = this.facilitiesSearch.bind(this);
        this.loadFacilities = this.loadFacilities.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }

    componentDidMount() {
        this.loadFacilities();
    }

    loadFacilities() {
        this.props.fetchByContractIdAndActiveFor(this.props.contractId);
    }

    handleDelete(facilityId) {
        this.props.removeFacilityFromContract(this.props.contractId, facilityId, () => {
            this.loadFacilities();
        });
    }

    onFacilitiesAdd(facilities) {
        this.props.addContractToFacilities(this.props.contractId, facilities, () => {
            this.loadFacilities();
        });
    }

    facilitiesSearch(term, callback) {
        this.props.searchFacilitiesNotActivelyPartOfContract(this.props.contractId, term, callback);
    }
    // getFacilities() {
    //     const {contract} = this.props;
    //     if (contract && contract.facilities) {
    //         return contract.facilities.map((facilityId) => {
    //             let facilityUI = contract.avail_facilities.find(o => o.id === facilityId || (o.facility && (o.facility.id === facilityId)));
    //             if (facilityUI) {
    //                 const facility = facilityUI.facility ? facilityUI.facility : facilityUI;
    //                 return (
    //                     <ListGroupItem key={facilityId}>
    //                         <Link to={`/org/dash/facility/${facilityId}`}>
    //                             {facility.name} ({facility.city}, {facility.state})
    //                         </Link>
    //                     </ListGroupItem>
    //                 )
    //             }
    //             return <div/>;
    //         });
    //     }
    //     return [];
    // }


    render() {
        const {facilities} = this.props;

        if (!facilities) {
            return <div>Loading...</div>
        }

        const columns = [{
            dataField: 'name',
            text: 'Name',
            formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'city',
            text: 'City',
            formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'state',
            text: 'State',
            formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <div className="pull-right">
                        <ButtonToolbar>
                            <DeleteConfirmationModal deleteEntity="Facility" handleDeleteCallback={this.handleDelete} entityId={row.id} buttonText={'Remove'}/>
                        </ButtonToolbar>
                    </div>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                <div className="float-right mb-3">
                    <ButtonToolbar>
                        <AddFacilityModal onAdd={this.onFacilitiesAdd} searchFunction={this.facilitiesSearch}/>
                    </ButtonToolbar>
                </div>
                <div className="d-flex">
                <h3 className="page_title_left">Locations</h3>
                </div>
                
                
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={facilities}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }
}

ContractFacilityList.propTypes = {
    contractId: PropTypes.string.isRequired
};

function mapStateToProps({smos}) {
    return {
        facilities: smos
    }
}

export default connect(mapStateToProps, {
    addContractToFacilities,
    fetchByContractIdAndActiveFor,
    searchFacilitiesNotActivelyPartOfContract,
    removeFacilityFromContract
})(ContractFacilityList);