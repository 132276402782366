/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {getDistributions} from "../../../../../actions/fieldValue_actions";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form} from "react-bootstrap";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage10Comp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dists: []
        }
    }

    componentDidMount() {
        getDistributions(distResp => {
            this.setState({dists: distResp.data});
        });
    }
    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }


    render() {
        const {contract, previousPage} = this.props;

        const avail_distributions = this.state.dists.map((dist) => {
            return {
                value: dist.id,
                label: dist.name
            }
        }).sort((a, b) => a.label.localeCompare(b.label));

        return (
            <Formik
                initialValues={{
                    distribution: contract.distribution?contract.distribution:undefined,
                    otherDistrReqd: contract.otherDistrReqd?contract.otherDistrReqd:undefined,
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        otherDistrReqd: Yup.string().test(
                            'other-access-req-test',
                            'Please explain',
                            function (value) {
                                if (!this.parent.distribution || !this.parent.distribution.includes("others")){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        )
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 18</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'distribution'}>
                                        <Form.Label>Required Distribution By The Certifying Official</Form.Label>
                                        <Field
                                            id="distribution"
                                            name="distribution"
                                            component={FormikFieldDualListBox}
                                            options={avail_distributions}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.distribution}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {!!(values.distribution && values.distribution.includes("others")) &&
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Other As Necessary (specify)</Form.Label>
                                        <Form.Control required as="textarea" name={'otherDistrReqd'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Please specify..'}
                                                      isInvalid={touched.otherDistrReqd && !!errors.otherDistrReqd}
                                                      value={values.otherDistrReqd}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.otherDistrReqd}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                }
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Submit</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                            </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                      
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage10 = ContractNewPage10Comp;

ContractNewPage10.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};