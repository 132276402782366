import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {fetchAssets} from "../../../../actions/asset_actions";
import paginationFactory from "react-bootstrap-table2-paginator";
import {AssetCheckoutModal} from "./asset_checkout_modal";
import {AssetCheckinModal} from "./asset_checkin_modal";
import {UploadDialogForOtherEntities} from './assets_upload';
import {extractCSVDataForEntity} from "../../../../actions/person_csvupload_action"; 
import { Asset_Filter } from "./asset_filter";

class AssetListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            sizePerPage: 10,
            filter: { itemNumber: "", description: "", serialNumber: "", brand: "", checkedOutSubject: "", status: "" }
        }
    }

    fetchAssetAsync = async () => {
        try {
            let assets = await fetchAssets();
            this.setState({
                assets
            })
            console.log('Assets fetched');
        } catch (e) {
            console.log('Error found ', e);
        }
    }

    componentDidMount() {
        this.fetchAssetAsync();
    }

    getCellLink = (row, cellData) => {
        return (
            <Link to={`/org/dash/asset/detail/${row.id}`}>{cellData}</Link>
        );
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        const checkOutOptions = [
            {value: "Checked Out", label: "Checked Out"},
            {value: "Available", label: "Available"}
        ]

        return [{
            dataField: 'itemNumber',
            text: 'Item Number',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'description',
            text: 'Description',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'serialNumber',
            text: 'Serial Number',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'brand',
            text: 'Brand',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'checkedOutSubjectName',
            text: 'Checked Out By',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'assetManagementStatus',
            text: 'Asset Management',
            formatter: (cell, row) => cell ? this.getCellLink(row, cell.displayName): '',
            sort: true,
            headerFormatter: headerFormatter
        },{
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: selectFilter({
            //     options: checkOutOptions
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar className="p-0" style={{flexWrap: "nowrap"}}>
                    {row.status === 'Available' &&
                        <AssetCheckoutModal asset={row} onFinish={this.fetchAssetAsync}/>
                    }
                    {row.status === 'Checked Out' &&
                        <AssetCheckinModal asset={row} onFinish={this.fetchAssetAsync}/>
                    }
                </ButtonToolbar>
            )
        }];
    }

    onAssetUpload = (doc) => {

        this.props.extractCSVDataForAsset(doc);
        this.props.history.push("/org/dash/asset/uploadToOrg");
    }
    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    assetsFilterby = () => { 
        let { assets, filter } = this.state;  
        let filteredData = assets.filter(item =>
            (filter.itemNumber === "" || item.itemNumber.toLowerCase().includes(filter.itemNumber.toLowerCase())) &&
            (filter.brand === "" || item.brand.toLowerCase().includes(filter.brand.toLowerCase())) &&
            (filter.description === "" || item.description.toLowerCase().includes(filter.description.toLowerCase())) &&
            (filter.serialNumber === "" || item.serialNumber.toLowerCase().includes(filter.serialNumber.toLowerCase())) &&
            (filter.checkedOutSubject === "" ||  item.checkedOutSubjectName.toLowerCase().includes(filter.checkedOutSubject)) &&
            (filter.status === "" || item.status === filter.status)
        )
        return filteredData; 
    }
        
    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Assets
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        let {assets, page, sizePerPage,filter} = this.state;

        if (!assets) {
            return <div>Loading...</div>
        }

        const remoteSettings = {
            filter: true,
            pagination: false,
            sort: false,
            cellEdit: false
        }; 
        return (
            
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <UploadDialogForOtherEntities accept={['.csv']} onUpload={this.onAssetUpload} entityBeingUploaded={'Assets'}/>
                            <Link className="btn btn-primary" to="/org/dash/asset/new">New Asset</Link>
                        </ButtonToolbar>
                    </div>
                )}
                {/* <h3 className="page_title_left">Assets</h3> */}
                <div className="d-flex">
                    <h3 className="page_title_left">Assets</h3>
                    <Asset_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.assetsFilterby()}
                                pagination={paginationFactory({page, sizePerPage, totalSize: assets.length})}
                                columns={this.getColumns()} filter={filterFactory()}/>
            </Fragment>
        )
    }
}


export const AssetList =  connect(null, {extractCSVDataForAsset: extractCSVDataForEntity, showSuccessGrowl, showErrorGrowl})(AssetListComponent);