/**
 * Created by pshivaraman on 6/1/18.
 */

import React from "react";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchCurOrg} from "../../../../actions/org_actions";
import DocumentManager from "../../../../components/document/doc_manager";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Link} from "react-router-dom";
import {deleteDocument} from "../../../../actions/doc_actions"
import {
    deleteFacility,
    fetchOrgFacilities,
    fetchOrgFacility,
} from "../../../../actions/facility_actions";
import FacilitySubjectList from "./subject/facility_subject_list";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import EntityIssues from "../../../../components/entity_issue"
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import {ConditionalDisplay} from "../../../util/shouldDisplay";

class FacilityDetail extends React.Component {
    constructor(props) {
        super(props);

        this.goToEditPage = this.goToEditPage.bind(this);
        this.onDocumentCreate = this.onDocumentCreate.bind(this);
        this.onDocumentDelete = this.onDocumentDelete.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
        this.props.deleteFacility(this.props.match.params.id, () => {
            this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG))
            this.props.history.push("/org/dash/facility");
        });

    }

    componentDidMount() {
        this.props.fetchOrgFacility(this.props.match.params.id);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/facility"
    //                             onClick={() => this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG))}>
    //                 Locations
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Location {this.props.facility.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    currentTime() {
        const {facility} = this.props;
        if (facility && facility.timezoneUI) {
            const timeMs = new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000) + facility.timezoneUI.currentOffset;
            const options = {weekday: 'long', hour: '2-digit', minute: '2-digit'};
            return `${new Date(timeMs).toLocaleString('en-us', options)} ${facility.timezoneUI.abbr}`;
        }
        return "Unknown";
    }

    goToEditPage() {
        this.props.history.push(`/org/dash/facility/${this.props.match.params.id}/update`);
    }

    getContracts() {
        const {facility} = this.props;
        if (facility && facility.contracts) {
            return facility.contracts.map((contractId) => {
                const contract = facility.avail_contracts.find(o => o.contractId === contractId);
                if (contract) {
                    return (
                        <ListGroupItem key={contractId}>
                            <Link to={`/org/dash/contract/${contractId}`}>
                                {contract.name}
                            </Link>
                        </ListGroupItem>
                    )
                }
                return <div/>;
            });
        }
        return [];
    }

    onDocumentCreate(docId, callBack) {
        this.props.addFacilityDoc(docId, this.props.match.params.id, callBack);
    }

    onDocumentDelete(docId, callBack) {
        this.props.deleteDocument(docId, () => {
            this.props.removeFacilityDoc(docId, this.props.match.params.id, () => {
                callBack();
            });
        });
    }

    render() {
        const {facility} = this.props;

        if (!facility || Array.isArray(facility)) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {ConditionalDisplay(
                <div className="pull-right">
                    <ButtonToolbar>
                        <Button variant="primary" onClick={() => this.goToEditPage()}>
                            <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                        </Button>
                        <DeleteConfirmationModal deleteEntity="Facility" handleDeleteCallback={this.handleDelete}/>
                    </ButtonToolbar>
                </div>
                )}
                <h3>Location Details</h3>
                <br/>
                <EntityIssues issueEntity={facility}/>
                <CollapsibleCard header="General" defaultOpen={true}>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Name
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.name}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Cage Code
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.cageCode}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Location Level
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.facLevel ? facility.facLevel.displayName : "None"}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Safeguarding Level
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.safeLevel ? facility.safeLevel.displayName : "None"}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Address
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.street}, {facility.city}, {facility.state} {facility.zipcode}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <br/>
                <CollapsibleCard header="Point of Contact Information" defaultOpen={true}>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Name
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.pocName}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Current Time
                        </Col>
                        <Col sm={3} md={4}>
                            {this.currentTime()}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Phone Number
                        </Col>
                        <Col sm={3} md={4}>
                            {facility.pocPhone}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Email Address
                        </Col>
                        <Col sm={3} md={4}>
                            <a href={`mailto:${facility.pocEmail}`} target="_top">
                                {facility.pocEmail}
                            </a>
                        </Col>
                    </Row>
                </CollapsibleCard>
                <br/>
                <CollapsibleCard header="Subjects" defaultOpen={true}>
                    <FacilitySubjectList facilityId={this.props.match.params.id}/>
                </CollapsibleCard><br/>
                <CollapsibleCard header="Contracts" defaultOpen={true}>
                    <ListGroup>
                        {this.getContracts()}
                    </ListGroup>
                </CollapsibleCard><br/>
                <CollapsibleCard header="Documents" defaultOpen={true}>
                    <DocumentManager entityType="FACILITY"
                                     entityId={this.props.match.params.id}/>
                </CollapsibleCard>
            </div>
        )
    }
}

function mapStateToProps({org_facility}) {
    return {
        facility: org_facility
    }
}

export default connect(mapStateToProps, {
    fetchOrgFacility,
    deleteDocument,
    fetchOrgFacilities,
    deleteFacility
})(FacilityDetail);