
import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { CommonUtils } from "../../../../actions/common_utils";
import { getNotificationTypes } from "../../../../actions/fieldValue_actions";

export const NotificationFilter =({ filters, onSubmit })=>{
    const [ShowHover, setShowHover] = useState(false); 
    const [notificationType, setNotificationType] = useState(null); 
    const popupRef = useRef(null);

    useEffect(() => {
        NotificationTypeList();
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const NotificationTypeList = async () => {
        let arrayObject = [{id: "", name: "Select Notification Type" }];
        let _notificationType = await getNotificationTypes();
        _notificationType &&  _notificationType.forEach((element) => {
            arrayObject.push({ id: element.id, name: element.type });
        });
        setNotificationType(arrayObject);
    }

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => { 
        onSubmit({ 
            type: "", 
            date: "",
            status: "" 
        })
        setShowHover(false)
    }
  
    return (
        <>
            <div className="dropdown filter_menu" ref={popupRef}>
                <div className="btn" onClick={() => { setShowHover(true) }}>
                    <i className="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div className="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                    <label className="form-label">Type</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"type"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.type && !!errors.type
                                            }
                                            value={values.type}
                                        >
                                            {CommonUtils.getOptionsForSelect(notificationType)}
                                        </Form.Control>
                                    </div>
                                    <ButtonToolbar>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" className="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )

}