/**
 * Created by pshivaraman on 4/4/18.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {addOrgDoc, fetchCurOrg, fetchOrgDocuments, removeOrgDoc} from "../../../../actions/org_actions";
import DocManager from "../../../../components/document/doc_manager";
import {CUR_ORG} from "../../../../actions/session_constants";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {deleteDocument} from "../../../../actions/doc_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {EntityType} from "../../../../actions/action_constants";

class DocList extends Component {
    constructor(props) {
        super(props);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Documents
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <DocManager entityType={EntityType.ORG} entityId={sessionStorage.getItem(CUR_ORG)}/>
            </div>
        )
    }
}

export default connect(null, {})(DocList);