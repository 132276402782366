
import React, { useEffect, useState } from "react";
import { DateUtils } from "../../../../actions/common_utils";
import { CustomPagination } from "../../../../components/custom_pagination";
import { getIssuesForOrgWithFilter } from "../../../../actions/task_actions";
import { ProgressBar } from "react-bootstrap";



export const TaskListIndex = () => {
    const [taskList, setTaskList] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [pageData, setpageData] = useState(null);


    useEffect(() => {
        getTasklist();
    }, [currentPage,itemsPerPage])

    const getTasklist = async () => {
        const response = await getIssuesForOrgWithFilter("", 1, 0, "", 0, ['ORG', 'SUBJECT'], 0, ['ACTIVE', 'CANCELLED', 'COMPLETED'], 0, ['PENDING', 'SUBJECT_NOTIFIED', 'DOCS_SUBMITTED', 'REJECTED', 'APPROVED'], 0, false, 0, currentPage-1, itemsPerPage);
        // Calculate total number of pages
        console.log(response.data)
        if (response.data && response.data.length > 0) {
            const totalSize=response.totalSize;
            const totalPages = Math.ceil(totalSize / itemsPerPage); 
            setTaskList(response.data);
            setTotalPages(totalPages);
            setTotalItems(totalSize);
            setpageData(response.data); 
        }
    }
    // Function to handle page navigation
    const goToPage = (page, pagesize) => { 
        setCurrentPage(page);
        setItemsPerPage(pagesize);
    };
  
    return (

        <div className="bg-l-blue dash_cards">
            <div className="d-flex space-between">
                <h5 className="page_title_left">Tasks Overview</h5>
            </div>

            <div className="quadrant_scroll">
                <div className="notification_overview">
                    {pageData && pageData.length > 0 ?
                        <table className="bg-transparent table">
                            <tbody>
                                {
                                    pageData.map((row, index) => (
                                        <tr key={index}>
                                            <td className="task_detail_col">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-grid">
                                                        <a href="#">{row.issueName} </a>
                                                        <span><a href="#">{(row.dueDate ? DateUtils.getDateString(row.dueDate) : row.dueDate)}</a></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label className="text-muted fs-6 fw-semibold color-green">{`${row.percentComplete}%`}</label>
                                                <ProgressBar className="h-6px" striped variant="success" now={row.percentComplete} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table> : <div>No data found</div>
                    }
                </div>
                <CustomPagination totalPage={totalPages} totalItems={totalItems} onChange={goToPage} />
            </div>

        </div>
    )
}