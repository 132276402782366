/**
 * Created by pshivaraman on 6/22/18.
 */

import React, {Fragment} from "react";
import {getAgencies} from "../../../../../actions/fieldValue_actions";
import Form from "react-bootstrap/Form";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import {ButtonToolbar, Card, Col} from "react-bootstrap";
import {CommonUtils} from "../../../../../actions/common_utils";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage1Comp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            agencies: []
        };
    }

    componentDidMount() {
        getAgencies((response) => {
            response.data.push({
                name: "None",
                id: "none",
            });
            this.setState({agencies: response.data});
        });
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        let {contract} = this.props;
        return (
            <Fragment>
                <Formik
                    initialValues={{
                        name: contract.name,
                        status: contract.status,
                        agency: contract.agency,
                        description: contract.description
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string().required('Contract name is required')
                        })
                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header>General Information</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Contract Name</Form.Label>
                                            <Form.Control required type="text" name={'name'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter contract name'}
                                                          isInvalid={touched.name && !!errors.name}
                                                          value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control required type="text" name={'description'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.description && !!errors.description}
                                                          value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={"agency"}>
                                            <Form.Label>Agency</Form.Label>
                                            <Form.Control required as="select" name={'agency'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.agency && !!errors.agency}
                                                          value={values.agency}
                                            >
                                                {CommonUtils.getOptionsForSelect(this.state.agencies)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.agency}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={"status"}>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control required as="select" name={'status'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.status && !!errors.status}
                                                          value={values.status}
                                            >
                                                {CommonUtils.getOptionsForSelect([
                                                    {id: "Active", name: "Active"},
                                                    {id: "Closed", name: "Closed"},
                                                    {id: "Pending", name: "Pending"}])}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.status}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                   
                                   
                                </Card.Body>
                                <Card.Footer>
                                <ButtonToolbar>
                                        <Button variant={'primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>  
                                </Card.Footer>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </Fragment>
        );
    }
}

ContractNewPage1Comp.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired
};

export const ContractNewPage1 = ContractNewPage1Comp;

