/**
 * Created by pshivaraman on 2/14/19.
 */

import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {DateUtils} from "../../../../../actions/common_utils";
import CreateBriefingModal from "../../../../../components/subject/create_briefing_modal";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    addContractToSubjects,
    fetchSubjectsByContractIdAndActiveFor,
    searchSubjectsNotActivelyPartOfContract
} from "../../../../../actions/subject_actions";
import AddSubjectModal from "../../../../../components/subject/add_subject_modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

class ContractSubjectList extends Component {
    constructor(props) {
        super(props);

        this.onSubjectsAdd = this.onSubjectsAdd.bind(this);
        this.subjectSearch = this.subjectSearch.bind(this);
    }

    componentDidMount() {
        this.loadSubjects();
    }

    loadSubjects() {
        this.props.fetchSubjectsByContractIdAndActiveFor(this.props.contractId);
    }

    onSubjectsAdd(subjects) {
        this.props.addContractToSubjects(this.props.contractId, subjects, () => {
            this.loadSubjects();
        });
    }

    subjectSearch(term, callback) {
        this.props.searchSubjectsNotActivelyPartOfContract(this.props.contractId, term, callback);
    }

    render() {
        const {subjects} = this.props;

        if (!subjects) {
            return <div>Loading...</div>
        }

        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => {if (row.orgRelationshipIndicator !== 'S') return <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>; else return cell;},
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => {if (row.orgRelationshipIndicator !== 'S') return <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>; else return cell;},
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            formatter: (cell, row) => {if (row.orgRelationshipIndicator !== 'S') return <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>; else return cell;},
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'startDate',
            text: 'Start Date',
            formatter: (cell, row) => {
                const relObj = row.contracts ? row.contracts.find(contract => contract.contractId === this.props.contractId) : null;
                return relObj ? DateUtils.getDateString(relObj.startDate) : "Unknown";
            },
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <div className="pull-right">
                        <ButtonToolbar>
                            {/*<Button disabled>Add Task</Button>*/}
                            <CreateBriefingModal subject={row} contractId={this.props.contractId}
                                                 onFinish={() => this.loadSubjects()}/>
                        </ButtonToolbar>
                    </div>
                )
            }
        }];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                  <div className="float-right mb-3">
                    <ButtonToolbar>
                        <AddSubjectModal onSubjectsAdd={this.onSubjectsAdd} searchFunction={this.subjectSearch}/>
                    </ButtonToolbar>
                </div>

                <div className="d-flex">
                <h3 className="page_title_left">Subjects</h3>
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={subjects}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }
}

ContractSubjectList.propTypes = {
    contractId: PropTypes.string.isRequired
};

function mapStateToProps({subjects}) {
    return {
        subjects: subjects
    }
}

export default connect(mapStateToProps, {
    addContractToSubjects,
    fetchSubjectsByContractIdAndActiveFor,
    searchSubjectsNotActivelyPartOfContract,

})(ContractSubjectList);