// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Created by pshivaraman on 6/22/18.
 */
/**
 * Contract Steps
 */
.contract-steps .ui-steps-item {
  width: 20% !important;
}`, "",{"version":3,"sources":["webpack://./dev/containers/org/dash/contract/contract.css"],"names":[],"mappings":"AAAA;;EAAA;AAIA;;EAAA;AAIA;EACI,qBAAA;AADJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
