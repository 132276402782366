/**
 * Created by shiva on 5/28/20.
 */

import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchCurOrg, fetchEntitiesForCompliance} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import BootstrapTable from 'react-bootstrap-table-next';
import {getFacilityLevels} from "../../../../actions/fieldValue_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {ComplianceType, EntityType} from "../../../../actions/action_constants";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import DocAddModal from "../../../../components/document/add_wizard/doc_add_modal";
import {addDocToEntity} from "../../../../actions/doc_actions";

class FacilityDashList extends Component {
    constructor(props) {
        super(props);

        this.getOverlayPanels = this.getOverlayPanels.bind(this);

        this.op = {};

        this.state = {
            opanels: [],
            show: false
        }
    }

    componentDidMount() {
        const byDocType = this.props.match.path.includes("byDocType");
        const docType = byDocType ? this.props.match.params.docType : "";
        this.props.fetchEntitiesForCompliance("FACILITY", byDocType, docType);
    }

    getFacilities() {
        let entities = [];
        const complianceType = this.props.match.params.complianceType;

        switch (complianceType) {
            case ComplianceType.ALL:
                entities = this.props.entities;
                break;
            case ComplianceType.NON_COMPLIANT:
                entities = this.props.nonCompliantEntities;
                break;
            case ComplianceType.COMPLIANT:
                entities = this.props.compliantEntities;
                break;
        }

        return entities.map(facility => {
            if (facility.facility) {
                return {...facility, ...facility.facility};
            }
            return facility;
        });
    }

    getTitle() {
        const complianceType = this.props.match.params.complianceType;
        const byDocType = this.props.match.path.includes("byDocType");
        const title = byDocType ? this.props.match.params.docType : "Overall";

        switch (complianceType) {
            case ComplianceType.NON_COMPLIANT:
                return title + ': Non Compliant ';
            case ComplianceType.COMPLIANT:
                return title + ': Compliant ';
            default:
                return 'All ';
        }
    }

    onCreate = (docId, callback) => {
        this.props.addDocToEntity(docId, EntityType.FACILITY, this.state.entityId, () => {
            const byDocType = this.props.match.path.includes("byDocType");
            const docType = byDocType ? this.props.match.params.docType : "";
            this.props.fetchEntitiesForCompliance("FACILITY", byDocType, docType);
            // this.removeSubject();
            this.setState({
                show: false,
                entityId: null
            });
            callback();
        });
    };

    onUploadClick = (entityId) => {
        this.setState({
            show: true,
            entityId: entityId
        })
    };

    componentDidUpdate(prevProps) {
        // Compare to old compliantEntityStatus
        if (this.props.entities !== prevProps.entities || this.props.compliantEntities !== prevProps.compliantEntities) {
            this.getOverlayPanels();
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Locations
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getOverlayPanels() {
        const entities = this.getFacilities();
        if (entities) {
            this.setState({
                opanels: entities.map((facility) => {
                    return (
                        <OverlayPanel key={facility.id} ref={(el) => el && (this.op[facility.id] = el)}
                                      appendTo={document.querySelector("#container")}>
                            <h4>Point of Contact</h4>
                            <p>Name: {facility.pocName}</p>
                            <p>Phone Number: {facility.pocPhone}</p>
                            <p>Email Address: {facility.pocEmail}</p>
                        </OverlayPanel>
                    )
                })
            });
        }
    }

    getCurrentTime(facility) {
        if (facility.timezoneUI) {
            const timeMs = new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000) + facility.timezoneUI.currentOffset;
            const options = {weekday: 'long', hour: '2-digit', minute: '2-digit'};
            return `${new Date(timeMs).toLocaleString('en-us', options)} ${facility.timezoneUI.abbr}`;
        }
        return "Unknown";
    }

    getAddress(facility) {
        let address = `${facility.street}, ${facility.city},, ${facility.state} ${facility.zipcode}`
        // Replace only does first occurrence
        let retString = address.split('null,').join('').split('null').join('').trim()
        if (retString.endsWith(",")) {
            retString = retString.slice(0, retString.length - 1)
        }
        return retString
    }

    render() {
        const {opanels} = this.state;

        const columns = [
            {
                dataField: "cageCode",
                text: "Cage Code",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "address",
                text: "Address",
                formatter: (cell, row) => this.getAddress(row),
                sort: true
            },
            {
                dataField: "poc",
                text: "Point of Contact",
                formatter: (cell, row) => {
                    return <a onClick={(event) => this.op[row.id] && this.op[row.id].toggle(event)}>{row.pocName}</a>
                }
            },
            {
                dataField: "localTime",
                text: "Local Time",
                formatter: (cell, row) => this.getCurrentTime(row)
            },
            {
                dataField: "facLevel",
                text: "Location Level",
                formatter: (cell) => cell ? cell.displayName : "None",
                sort: true
            },
            {
                dataField: "safeLevel",
                text: "Safeguarding Level",
                formatter: (cell) => cell ? cell.displayName : "None",
                sort: true
            },
            {
                dataField: "subjectCount",
                text: "# of Subjects",
                formatter: (cell) => cell ? cell : 0,
                sort: true
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (cell, row) => {
                    return (
                        <Fragment>
                            <ButtonToolbar style={{flexWrap: "nowrap"}}>
                                <Button variant={'link'} className="close" onClick={() => this.onUploadClick(row.id)}>
                                <span aria-hidden="true" title={"Upload Document"} style={{padding: 10}} aria-label="Upload Document">
                                    <i className="fa fa-upload"/>
                                </span>
                                </Button>
                            </ButtonToolbar>
                        </Fragment>
                    )
                }
            }
        ];

        const byDocType = this.props.match.path.includes("byDocType");
        const docTypes = byDocType ? [this.props.match.params.docType] : [];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <h3 className="page_title_left">{this.getTitle()} Locations</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                data={this.getFacilities()} columns={columns}/>
                {opanels}
                {this.state.show &&
                <DocAddModal entityType={EntityType.FACILITY} entityId={this.state.entityId} viewList={() => {
                }}
                             onCreate={this.onCreate} docTypes={docTypes}/>
                }
            </div>
        )
    }
}

function mapStateToProps({compliantEntityStatus}) {
    return {
        entities: compliantEntityStatus.all,
        compliantEntities: compliantEntityStatus.compliant,
        nonCompliantEntities: compliantEntityStatus.nonCompliant
    }
}

export default connect(mapStateToProps, {fetchEntitiesForCompliance, addDocToEntity})(FacilityDashList);
