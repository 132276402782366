/**
 * Created by pshivaraman on 3/29/18.
 */

import React from "react";
import {fetchCurOrg, displaySelFacilitiesOrSubjectsFromDashboard} from "../../../actions/org_actions";
import {CUR_ORG} from "../../../actions/session_constants";
import {connect} from "react-redux";
import BreadcrumbLink from "../../../components/bootstrap_addons/breadcrumb_link";
import {fetchActivePersonsByOrg} from "../../../actions/subject_actions";
import {fetchOrgContracts} from "../../../actions/contract_actions";
import {fetchOrgFacilities} from "../../../actions/facility_actions";
import {Link,withRouter} from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {EntityDashboard} from './dashboard/entity_dashboard';
import {isContractOnlyRole, isHROnlyRole, isHRRole} from '../../util/shouldDisplay';
import ContractList from "./contract/contract_list";
import {CommonUtils, DateUtils} from "../../../actions/common_utils";
import TaskList from "./task/task_list";
import {Card} from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { fetchIssuesWithEntityForOrg, getIssuesForOrgWithFilter } from "../../../actions/task_actions";
import  Notification  from "./quadrant/notifications";
import { Todo } from "./quadrant/todo";
import { Subject } from "./quadrant/subject";
import { CustomPagination } from "../../../components/custom_pagination";
import { TaskListIndex } from "./quadrant/taskIndex";

class OrgDashIndex extends React.Component {
    constructor(props) {
        super(props); 
    }

    options = {
        isOpen: true,
        header:{title:"Tru-vetting"},
          footer:{text:""}, 
          chatHistory: {disabled:true},
          notification: {disabled:true},
          tooltip: {
            mode: "CLOSE",
            text: "Ask Query"
          },
        // ...other configurations
        theme: {
          primaryColor: '#003164',
          secondaryColor: '#003e7e',
        },
        audio: {
          disabled: true,
        },
        //   chatButton: {
        //     icon: userAvatar,
        //   },
        // ...other styles
      };

      

       flow={
		start: {
			message: "Enter your gemini api key and start asking away!",
			path: "api_key"
		},
		api_key: {
			message: (params) => {
				api_key = params.userInput.trim();
				return "Ask me anything!";
			},
			path: "loop",
		},
		loop: {
			message: async (params) => {
				await gemini_stream(params);
			},
			path: () => {
				if (has_error) {
					return "start"
				}
				return "loop"
			}
		}
	}

    componentDidMount() {
       
        if (!isHROnlyRole()) {
            this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
        } 
        }

     
    

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg(sessionStorage.getItem(CUR_ORG))}>
    //                 Organization {this.props.org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Dashboard
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getOrgIssues(){
        const {cur_org} = this.props;

        if (cur_org){
            return cur_org.issues && cur_org.issues.map(issue => {
                    return <li>{issue.name} ({issue.percentComplete ? issue.percentComplete : 0}% Complete)</li>;
                });
        }
    }

    render() { 
        if (!isContractOnlyRole() && !isHROnlyRole()) {
            return (
                <div>
                    {/* {this.getBreadCrumb()} */}
                    {/* <h3>Dashboard</h3>
                <h4>Issues</h4>
                <h6>Organization Issues</h6> */}
                    <ul>
                        {this.getOrgIssues()}
                    </ul>
                    <div className="container-fluid dashboard_cover">
                        <div className="bg-red-cover">
                            <div className="col-lg-12 row">
                                <div className="col-lg-6">
                                    <EntityDashboard entityType={"FACILITY"}/>
                                </div>

                                <div className="col-lg-6">
                                    <EntityDashboard entityType={"SUBJECT"}/>
                                </div>
                            </div>
                        </div>

                        <div className="dash_statistics">
                            <div className="col-lg-12 row">

                                <div className="col-lg-6">
                                    <Todo />
                                </div>

                                <div className="col-lg-6">
                                    <Subject />
                                </div>
                                <div className="col-lg-6">
                                    <Notification />
                                </div>

                                <div className="col-lg-6">
                                  <TaskListIndex />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                 
            );
        } else if (isHRRole()) {
            return (
                <TaskList/>
            )
        } else {
            return (
                <ContractList/>
            )
        }
    }
}


function mapStateToProps({cur_org, org_contracts,tasks}) {
    return {
        org: cur_org,
        contracts: org_contracts,
        tasklist:tasks
    }
}

export default withRouter(connect(mapStateToProps, {fetchOrgFacilities, fetchOrgContracts, fetchActivePersonsByOrg,fetchIssuesWithEntityForOrg})(OrgDashIndex));