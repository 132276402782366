import React from "react";
import { useEffect, useState } from "react"
import { fetchNotifications } from "../../../../actions/notification_actions";
import { DateUtils } from "../../../../actions/common_utils";
import ProgressBar from 'react-bootstrap/ProgressBar';

export const Todo = () => {

    const [notification, setNotification] = useState(null);
    // const [totalPages, setTotalPages] = useState(1);
    // const [totalItems, setTotalItems] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(4);
    const [pageData, setpageData] = useState(null);
    const [filter, setFilter] = useState({type: "", status: "", data: ""});
    const now = 60;

    useEffect(() => {
       getNotification(filter);
    },[filter]);

    // useEffect(() => {
    //     if (notification && notification.length > 0) { 
    //         const totalPages = Math.ceil(notification.length / itemsPerPage);
    //         setTotalPages(totalPages);
    //         setTotalItems(notification.length);
    //         goToPage(currentPage);
    //     }
    //     else{
    //         setTotalPages(0);
    //         setTotalItems(0);
    //         setpageData(null);
    //     }
    // }, [notification])
   
    const getNotification = async(_filter) => { 
        const response =await fetchNotifications();
        if (response && response.length > 0) {
            let filteredData = response.filter(item =>
                (_filter.type === "" || (item.type && item.type.id === _filter.type))
            ) 
            setNotification(filteredData);
        } 
    };
    const goToPage = (page) => {
        if (notification) {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = Math.min(startIndex + itemsPerPage, notification.length); 
            // Slice the data array to display only the items for the current page
            const currentData = notification.slice(startIndex, endIndex); 
            setpageData(currentData);
            setCurrentPage(page);
        }
    };
    return (
        <div className="bg-l-blue dash_cards"> 
            <div className="d-flex space-between">
                <h5 className="page_title_left">Todo</h5>
            </div>
            <div className="quadrant_scroll">
                <div className="notification_overview">
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px bg-success"></span>
                        {/* <div className="form-check form-check-custom form-check-solid mx-5">
                            <input className="form-check-input" type="checkbox" value="" />
                        </div> */}
                        <div className="flex-grow-1 mx-3">
                            <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-7">Task that are due for action will appear here.</a>
                            <span className="text-muted fw-semibold d-block">Due in no. days</span>
                        </div>

                        {/* <div className="flex-grow-2 todo_progress_bar">
                        <ProgressBar now={now} label={`${now}%`} />
                        </div> */}
                        <span className="mx-5 badge badge-light-success fs-6 fw-bold">New</span>
                    </div>
                </div>

                <div className="custom_pagination pagination mt-3 align-items-center">
                    <div className="align-left">
                        <span className="custom_pagination">Total * Items</span>
                    </div>
                    <div className="align-right">
                        <button className="btn btn-light">
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        <span className="custom_pagination">* of *</span>
                        <button className="btn btn-light" >
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}