/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {getFacilityLevels} from "../../../../../actions/fieldValue_actions";
import Form from "react-bootstrap/Form";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import {Card, Col} from "react-bootstrap";
import {CommonUtils} from "../../../../../actions/common_utils";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage2Comp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            levels: [],
            reqLevels: []
        };
    }

    componentDidMount() {
        getFacilityLevels((response) => {
            // remove none option to the FCL
            let reqLevels = [];
            for (let level of response.data){
                if ('None'.toLowerCase() !== level.id.toLowerCase()){
                    reqLevels.push(level);
                }
            }
            this.setState({
                levels: response.data,
                reqLevels
            });
        });
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {previousPage, contract} = this.props;
        if (!contract.clearanceLevel && this.state.reqLevels && this.state.reqLevels.length > 0){
            contract.clearanceLevel = this.state.reqLevels[0].id;
        }
        return (
            <Formik
                initialValues={{
                    clearanceLevel: contract.clearanceLevel,
                    safeguardLevel: contract.safeguardLevel,
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Card>
                            <Card.Header>Section 1</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId={"clearanceLevel"}>
                                        <Form.Label>Clearance Level</Form.Label>
                                        <Form.Control required as="select" name={'clearanceLevel'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.clearanceLevel && !!errors.clearanceLevel}
                                                      value={values.clearanceLevel}
                                        >
                                            {CommonUtils.getOptionsForSelect(this.state.reqLevels)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.clearanceLevel}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={"safeguardLevel"}>
                                        <Form.Label>Safeguarding Level</Form.Label>
                                        <Form.Control required as="select" name={'safeguardLevel'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.safeguardLevel && !!errors.safeguardLevel}
                                                      value={values.safeguardLevel}
                                        >
                                            {CommonUtils.getOptionsForSelect(this.state.levels)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.safeguardLevel}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                
                            </Card.Body>

                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                                </ButtonToolbar> 
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage2 = ContractNewPage2Comp;

ContractNewPage2.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};

