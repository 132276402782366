import React from "react";
import { useEffect, useState } from "react"
import { fetchNotifications } from "../../../../actions/notification_actions";
import { DateUtils } from "../../../../actions/common_utils";
import { NotificationFilter } from "./notification_filter"; 
import { CustomPagination } from "../../../../components/custom_pagination";
import {withRouter } from "react-router-dom";

 const Notification = (props) => {

    const [notification, setNotification] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [pageData, setpageData] = useState(null);
    const [debouncehit, setDebouncehit] = useState(false);
    const [filter, setFilter] = useState({type: "", status: "", data: ""});

    useEffect(() => {
       getNotification(filter);
        setTimeout(()=>{
            setDebouncehit(!debouncehit);
        },5000)
    },[debouncehit,filter]);

  

    useEffect(() => {
        const handleLinkClick = (event) => {
            const target = event.target;
            if (target.tagName === 'A' && target.href) {
                event.preventDefault();
                const url = new URL(target.href);
                navigateTo(url.pathname); // Navigate to the specified path
            }
        };

        const container = document.getElementById('html-container');
        if (container) {
            container.addEventListener('click', handleLinkClick);
        }

        return () => {
            if (container) {
                container.removeEventListener('click', handleLinkClick);
            }
        };
    }, []);

    const navigateTo = (path) => {
        props.history.push(path); // Navigate using history.push from props
    };
      

    useEffect(() => { 
        if (notification && notification.length > 0) { 
            const totalPages = Math.ceil(notification.length / itemsPerPage);
            setTotalPages(totalPages);
            setTotalItems(notification.length);
            goToPage(currentPage,itemsPerPage);
        }
        else{
            setTotalPages(0);
            setTotalItems(0);
            setpageData(null);
        }
    }, [notification])
    const notificationFilterby = (filter) => {  
        setFilter(filter);
    }
    const getNotification = async(_filter) => { 
        const response =await fetchNotifications();
       
        if (response && response.length > 0) {
            let filteredData = response.filter(item =>
                (_filter.type === "" || (item.type && item.type.id === _filter.type))
            )  
            setNotification(filteredData);
        } 
    };
    const goToPage = (page, pageSize) => {
        if (notification) {
            const startIndex = (page - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, notification.length); 
            // Slice the data array to display only the items for the current page
            const currentData = notification.slice(startIndex, endIndex); 
            setpageData(currentData);
            setCurrentPage(page);  
            setItemsPerPage(pageSize);
        }
    };
    return (
        <div className="bg-l-blue dash_cards"> 
            <div className="d-flex space-between">
                    <h5 className="page_title_left">Notification Overview</h5>
                    <NotificationFilter filters={filter} onSubmit={notificationFilterby} />
                </div>
           
                <div className="quadrant_scroll">
                    <div className="notification_overview" id="html-container">
                    {pageData && pageData.length > 0 ?
                        <div className="timeline-label">
                            {
                                pageData.map((row, index) => (
                                    <div className="timeline-item" key={index}>
                                        <div className="timeline-label fw-bold text-gray-800 fs-6">{(row.when ? DateUtils.getDateTimeString(row.when) : row.when)}</div>
                                        <div className="timeline-badge"><i className="fa fa-genderless text-warning fs-1"></i></div>
                                        <div className="fw-mormal timeline-content text-muted ps-3">
                                            {/* <div className=""> {row.whoForList.join(",")} </div> */}
                                            <span dangerouslySetInnerHTML={{ __html: row.message }} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div> : <div>No data found</div>
                    }
                    </div> 
                    <CustomPagination totalPage={totalPages} totalItems={totalItems} onChange={goToPage} />
                </div>
           
        </div>
    )
}
export default withRouter(Notification)