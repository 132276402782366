/**
 * Created by pshivaraman on 1/15/19.
 */

import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {FieldRBSelectWithKeys} from "../../../../../components/redux_form/field_components_rb";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class FacilitySubjectEditRelModal extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        this.setState({showModal: true});
    }

    handleClose() {
        this.setState({showModal: false});
        setTimeout(this.props.onClose, 500);
    }

    onSubmit(values) {
        this.props.onFinish(values.relType, () => {
            this.handleClose();
        });
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Modal show={this.state.showModal} onHide={this.handleClose}>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Relationship Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Field name="relType" component={FieldRBSelectWithKeys} options={[
                            {id: "", name: "Select Type..."},
                            {id: "OWNED", name: "Owned"},
                            {id: "SERVICED", name: "Serviced"}
                        ]} label="Type" required/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.relType) {
        errors.relType = "Type is required!";
    }

    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'FacilitySubjectEditRelForm',
    enableReinitialize: true,
})(
    connect(null, {})(FacilitySubjectEditRelModal)
);