/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {Formik} from "formik";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import * as Yup from "yup";
import {Button, Card, Col, Form} from "react-bootstrap";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage5Comp extends React.Component {
    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {previousPage, contract} = this.props;
        return (
            <Formik
                initialValues={{
                    contractor: contract.contractor,
                    subContractors: contract.subContractors,
                    actualPerformance: contract.actualPerformance,
                    generalIdent: contract.generalIdent
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 6</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Contractor(s) Address, Cage Code, and Security Office</Form.Label>
                                        <Form.Control required as="textarea" name={'contractor'}
                                            onChange={handleChange}
                                            onBlur={handleBlur} placeholder={'Enter one or more contract name, address..'}
                                            isInvalid={touched.contractor && !!errors.contractor}
                                            value={values.contractor}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contractor}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 7</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Sub-Contractor(s) Address, Cage Code, and Security Office</Form.Label>
                                        <Form.Control required as="textarea" name={'subContractors'}
                                            onChange={handleChange}
                                            onBlur={handleBlur} placeholder={'Enter one or more sub-contractor name, address..'}
                                            isInvalid={touched.subContractors && !!errors.subContractors}
                                            value={values.subContractors}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.subContractors}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 8</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Actual Performance Location, Cage Code, and Security Office</Form.Label>
                                        <Form.Control required as="textarea" name={'actualPerformance'}
                                            onChange={handleChange}
                                            onBlur={handleBlur} placeholder={'Enter actual performance  location, cage code..'}
                                            isInvalid={touched.actualPerformance && !!errors.actualPerformance}
                                            value={values.actualPerformance}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.actualPerformance}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 9</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>General Identification of this Procurement</Form.Label>
                                        <Form.Control required as="textarea" name={'generalIdent'}
                                            onChange={handleChange}
                                            onBlur={handleBlur} placeholder={'Enter one or more contract name, address..'}
                                            isInvalid={touched.generalIdent && !!errors.generalIdent}
                                            value={values.generalIdent}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.generalIdent}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>

                            <Card.Footer>
                                <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                        handleDeleteCallback={this.handleCancel} />
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                        
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage5 = ContractNewPage5Comp;

ContractNewPage5.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};
