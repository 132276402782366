/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import Form from "react-bootstrap/Form";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {validate_contract_new} from "./contract_new_validate";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import {Card, Col} from "react-bootstrap";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage7Comp extends React.Component {
    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage} = this.props;
        return (
            <Formik
                initialValues={{
                    releaseType: contract.releaseType?contract.releaseType:undefined,
                    throughReleaseDesc: contract.throughReleaseDesc?contract.throughReleaseDesc:undefined,
                    securityGuidance: contract.securityGuidance?contract.securityGuidance:undefined,
                    addSecurityDesc: contract.addSecurityDesc?contract.addSecurityDesc:undefined,
                    addSecurity: contract.addSecurity,
                    inspectionsDesc: contract.inspectionsDesc?contract.inspectionsDesc:undefined,
                    inspections: contract.inspections
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        releaseType: Yup.string().test(
                            'rel-type-test',
                            'Please select a release type',
                            function (value) {
                                if (this.parent.throughReleaseDesc){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        throughReleaseDesc: Yup.string().test(
                            'through-rel-test',
                            'Please enter comments',
                            function (value) {
                                if (this.parent.releaseType === 'public'){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        addSecurityDesc: Yup.string().test(
                            'addl-sec-req-test',
                            'Please enter the description for additional security',
                            function (value) {
                                if (this.parent.addSecurity !== true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        inspectionsDesc: Yup.string().test(
                            'inspections-req-test',
                            'Please enter the description for inspections',
                            function (value) {
                                if (this.parent.inspections !== true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 12</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'releaseType'}>
                                        <Form.Label>Any information (classified or unclassified) pertaining to this contract shall not be
                                            released
                                            for public dissemination except as provided by the Industrial Security Manual or unless it
                                            has
                                            been approved for public release by appropriate U.S. Government authority. Proposed public
                                            releases shall be submitted for approval prior to release to the Directorate for Freedom of
                                            Information and Security Review, Office of the Assistant Secretary of Defense (Public
                                            Affairs)*
                                            for review. *In the case of non-DoD User Agencies, requests for disclosure shall be
                                            submitted to
                                            that agency.</Form.Label>
                                        <Form.Check
                                            name={'releaseType'}
                                            type={'radio'}
                                            label={'Direct Public Release'}
                                            value={'public'}
                                            onChange={handleChange}
                                            checked={values.releaseType === 'public'}
                                            isInvalid={touched.releaseType && !!errors.releaseType}
                                            id={'releaseType'}
                                        />
                                        <Form.Check
                                            name={'releaseType'}
                                            type={'radio'}
                                            label={'Through Release'}
                                            value={'through'}
                                            checked={values.releaseType === 'through'}
                                            onChange={handleChange}
                                            isInvalid={touched.releaseType && !!errors.releaseType}
                                            id={'releaseType'}
                                        />
                                        {touched.releaseType && !!errors.releaseType &&
                                            <div className={"invalid-feedback"} style={{ display: "block" }}>{errors.releaseType}</div>}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Comments</Form.Label>
                                        <Form.Control required as="textarea" name={'throughReleaseDesc'}
                                            onChange={handleChange}
                                            onBlur={handleBlur} placeholder={'Please specify..'}
                                            isInvalid={touched.throughReleaseDesc && !!errors.throughReleaseDesc}
                                            value={values.throughReleaseDesc}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.throughReleaseDesc}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 13</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'securityGuidanceLabel'}>
                                        <Form.Label>The security classification guidance needed for this classified effort is identified below.
                                            If
                                            any difficulty is encountered in applying this guidance or if any other contributing factor
                                            indicates a need for changes in this guidance, the contractor is authorized and encouraged
                                            to
                                            provide recommended changes; to challenge the guidance or the classification assigned to any
                                            information or material furnished or generated under this contract; and to submit any
                                            questions
                                            for interpretation of this guidance to the official identified below. Pending final
                                            decision,
                                            the information involved shall be handled and protected at the highest level of
                                            classification
                                            assigned or recommended. (Fill in as appropriate for the classified effort. Attach, or
                                            forward
                                            under separate correspondence, any documents/guides/extracts referenced herein. Add
                                            additional
                                            pages in the documents section as needed to provide complete guidance.)</Form.Label>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Security Guidance</Form.Label>
                                        <Form.Control required as="textarea" name={'securityGuidance'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.securityGuidance && !!errors.securityGuidance}
                                            value={values.securityGuidance}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.securityGuidance}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 14</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'section14Label'}>
                                        <Form.Label>Requirements, in addition to NISPOM requirements, are established for this contract. (If Yes,
                                            identify the pertinent contractual clauses in the contract document itself, or provide an
                                            appropriate statement which identifies additional requirements. Provide a copy of the
                                            requirements to the cognizant security office.)</Form.Label>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'addSecurity'}>
                                        <Form.Check
                                            name={'addSecurity'}
                                            label={'Additional Security'}
                                            checked={values.addSecurity}
                                            onChange={(event) => {
                                                setFieldValue('addSecurity', event.target.checked);
                                                handleChange(event);
                                            }}
                                            isInvalid={touched.addSecurity && !!errors.addSecurity}
                                            id={'addSecurity'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.addSecurity}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {(values.addSecurity === true) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={"addSecurityDesc"}>
                                            <Form.Label>Additional Security Description</Form.Label>
                                            <Form.Control required as="textarea" name={'addSecurityDesc'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.addSecurityDesc && !!errors.addSecurityDesc}
                                                value={values.addSecurityDesc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.addSecurityDesc}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                }
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 15</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'section15Label'}>
                                        <Form.Label>Elements of this contract are outside the inspection responsibility of the cognizant security
                                            office. (If yes, explain and identify specific areas or elements carved out and the activity
                                            responsible for inspections. Use Item 13 if more space is needed.)</Form.Label>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'inspections'}>
                                        <Form.Check
                                            name={'inspections'}
                                            label={'Inspections'}
                                            checked={values.inspections}
                                            onChange={(event) => {
                                                setFieldValue('inspections', event.target.checked);
                                                handleChange(event);
                                            }}
                                            isInvalid={touched.inspections && !!errors.inspections}
                                            id={'inspections'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.inspections}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {(values.inspections === true) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={"inspectionsDesc"}>
                                            <Form.Label>Inspections Description</Form.Label>
                                            <Form.Control required as="textarea" name={'inspectionsDesc'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.inspectionsDesc && !!errors.inspectionsDesc}
                                                value={values.inspectionsDesc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.inspectionsDesc}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                }
                            </Card.Body>

                            <Card.Footer>
                                <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                        handleDeleteCallback={this.handleCancel} />
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                        
                        
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage7 = ContractNewPage7Comp;

ContractNewPage7.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};
