import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {DateUtils} from "../../../../actions/common_utils";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Col, Row, ButtonToolbar} from "react-bootstrap";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {fetchDocControl} from "../../../../actions/doc_control_actions";
import {ConditionalDisplay} from "../../../util/shouldDisplay";

class DocControlDetailComp extends React.Component {
    constructor(props) {
        super(props);

        this.goToEditPage = this.goToEditPage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            page: 1,
            sizePerPage: 10
        }

    }

    componentDidMount() {
        this.fetchDocControlAsync();
    }

    fetchDocControlAsync = async () => {
        let docControl = await fetchDocControl(this.props.match.params.id);
        this.setState({
            docControl
        })
    }

    goToEditPage() {
        this.props.history.push(`/org/dash/docControl/${this.props.match.params.id}/update`);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/docControl">
    //                 Document Control
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Document Control {this.state.docControl?this.state.docControl.controlNumber:''}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    handleDelete() {
        // this.props.deleteContract(this.props.match.params.id, () => {
        //     fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
        //     this.props.history.push("/org/dash/contract");
        // });

    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'location',
            text: 'Location',
            formatter: (cell) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'safeNumber',
            text: 'Safe Number',
            formatter: (cell) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'drawerNumber',
            text: 'Drawer Number',
            formatter: (cell) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'beginDate',
            text: 'Begin Date',
            formatter: (cell) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'endDate',
            text: 'End Date',
            formatter: (cell) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    render() {
        let {docControl, page, sizePerPage} = this.state;

        if (!docControl) {
            return (
                <div>Loading...</div>
            );
        }

        console.log('docControl is ', docControl);
        const paramId = this.props.match.params.id;

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {ConditionalDisplay(
                <div className="title_flex">
                      <h3 className="page_title_left mb-4">Document Control Details</h3>
                    <ButtonToolbar>
                        <Link to={`/org/dash/docControl/${paramId}/update`} className="btn btn-primary">
                            <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                        </Link>
                    </ButtonToolbar>
                </div>
                )}
              
                <CollapsibleCard header="General" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Control Number
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.controlNumber}
                        </Col>
                        <Col sm={3} md={2}>
                            Is Working Paper?
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.workingPaper?'Yes':'No'}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Media Type
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.mediaType? docControl.mediaType.displayName:''}
                        </Col>
                        <Col sm={3} md={2}>
                            Description
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.description}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Classfication Level
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.classificationLevel}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2}>
                            Date Created
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.docCreatedDate}
                        </Col>
                        <Col sm={3} md={2}>
                            Date Received
                        </Col>
                        <Col sm={3} md={4}>
                            {docControl.docReceivedDate}
                        </Col>
                    </Row>
                    {docControl.destructionInfo &&
                        <Fragment>
                            <Row className={'mb-3'}>
                                <Col sm={3} md={2}>
                                    Date Destroyed
                                </Col>
                                <Col sm={3} md={4}>
                                    {docControl.destructionInfo.dateDestroyed}
                                </Col>
                                <Col sm={3} md={2}>
                                    Destruction Type
                                </Col>
                                <Col sm={3} md={4}>
                                    {docControl.destructionInfo.destructionType?docControl.destructionInfo.destructionType.displayName:''}
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </CollapsibleCard>
                {docControl.storageDetailsList &&
                <CollapsibleCard header="Storage Details" defaultOpen>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="subjectId"
                                    data={docControl.storageDetailsList}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize: docControl.storageDetailsList.length
                                    })}
                                    columns={this.getColumns()} filter={filterFactory()}/>
                </CollapsibleCard>
                }
            </div>
        );
    }
}

export const DocControlDetail = connect(null, null)(DocControlDetailComp);
