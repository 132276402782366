/**
 * Created by pshivaraman on 4/7/18.
 */

import React from "react";
import {CON_IN_THE_WORKS, CUR_ORG, FAC_FOR_ORG_CON_CREATE} from "../../../../actions/session_constants";
import {Link} from "react-router-dom";
import {
    fetchAgencies,
    getContractStatusTypes,
    getDestructionTypesForDocControl
} from "../../../../actions/fieldValue_actions";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {showErrorGrowl} from "../../../../actions/msg_actions";
import {TableExport} from '../../../../components/table_export';
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {DateUtils} from "../../../../actions/common_utils";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from 'react-bootstrap-table2-filter';
import {fetchOrgContracts, uploadDD254Doc} from "../../../../actions/contract_actions";
import {connect} from "react-redux";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ContractUpload} from "../../../../components/contract/contract_upload";
import {DD254Mapping} from "../../../../mappingFiles/dd254FormMapping";
import { Contract_Filter } from "./contract_filter";

class ContractList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            contractStatusTypes: [],
            filter: {contractType: "", status: "", agencyData: ""}
        }
    }
    componentDidMount() {
        this.props.fetchAgencies();
        this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Contracts
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    titleFunction = (cell, row) => {
        console.log('Inside title function');
        if (row.documentCount && row.documentCount > 0){
            return "The documents associated with the contract will be deleted when contract has been archived for 2 years";
        }
    }

    uploadContract = async (docs) => {
        console.log('Doc uploaded is ', docs);
        const response = await uploadDD254Doc(docs);
        if (response.contract){
            let dd254Mapping = new DD254Mapping();
            dd254Mapping.populateContract(response.contract, response.dd254FormMap);
            sessionStorage.setItem(CON_IN_THE_WORKS, JSON.stringify(response.contract));
            sessionStorage.setItem(FAC_FOR_ORG_CON_CREATE, JSON.stringify(response.facilityCageCodes));
            this.props.history.push(`/org/dash/contract/${CON_IN_THE_WORKS}/update`);
        } else {
            this.props.showErrorGrowl('Invalid PDF', 'Cannot load contract from the PDF');
        }
    }

    getDataReadyForDisplay = (agencyOptions) => {
        const {contracts} = this.props;
        for (let eachContract of contracts){
            eachContract.daysLeft = DateUtils.getDaysLeftPlainText(eachContract.endDate).text;
            eachContract.endDateStr = DateUtils.getDateString(eachContract.endDate);
            eachContract.agencyData = agencyOptions[eachContract.agency];
            eachContract.isPrime = eachContract.primeContractNo?'No':'Yes';
        }
    }

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    contractFilterby = () => { 
        let { filter } = this.state;  
        let {contracts} = this.props;
        console.log("filter",filter);
        let filteredData = contracts.filter(item =>
            (filter.contractType === "" || item.contractType.toLowerCase().includes(filter.contractType.toLowerCase())) &&
            (filter.status === "" || item.status.toLowerCase().includes(filter.status.toLowerCase())) &&
            (filter.agencyData === "" || item.agencyData.toLowerCase().includes(filter.agencyData.toLowerCase())) 
            
        )
        return filteredData; 
    }

   

    render() {
        let {page, sizePerPage,filter} = this.state;

        const {contracts, agencies} = this.props;
        console.log('Contracts are ', contracts)
        let contractOptions = [{value:'Active', label:'Active'},{value:'Pending',label:'Pending'},{value:'Archived',label:'Archived'},{value:'Closed',label:'Closed'}];
        const agencyOptions = {};
        agencyOptions.none = "None";
        if (agencies) {
            agencies.map((agency) => {
                agencyOptions[agency.id] = agency.name;
            });
        }
        this.getDataReadyForDisplay(agencyOptions);

        const columns = [
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "contractNo",
                text: "Contract Number",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "contractType",
                text: "Contract Type",
                formatter: (cell, row) => {
                    let returnStr = '';
                    if (cell){
                        if ('PRIME' === cell){
                            returnStr = "Prime";
                        } else {
                            returnStr = "Sub";
                        }
                        if (row.taskOrderParentContractId){
                            returnStr = returnStr + ', IDIQ';
                        }
                    } else {
                        if (row.taskOrderParentContractId){
                            returnStr = 'IDIQ';
                        }
                    }
                    return returnStr;
                },
                sort: true,
                // filter: textFilter(),
                title: this.titleFunction
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                title: this.titleFunction,
                formatter: (cell, row) => cell,
                // filter: selectFilter({
                //     options: contractOptions,
                //     defaultValue: 'Active'
                // }),
            },
            {
                dataField: "daysLeft",
                text: "Time Remaining",
                formatter: (cell, row) => DateUtils.getDaysLeft(row.endDate, 30),
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "endDateStr",
                text: "End Date",
                formatter: (cell) => cell,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "agencyData",
                text: "Agency",
                formatter: (cell) => <Link to={`/smo/agencies/${cell}`}>{cell}</Link>,
                // filter: selectFilter({
                //     options: agencyOptions
                // }),
                sort: true,
                title: this.titleFunction
            },
        ];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <ContractUpload onUpload={this.uploadContract} />
                            <Link className="btn btn-primary" to="/org/dash/contract/new">New Contract</Link>
                            <TableExport columns={columns} data={contracts} columnNameField={'text'} dataFieldName={'dataField'} reportName={'Contracts'}/>
                        </ButtonToolbar>
                    </div>
                )}
                {/* <h3 className="page_title_left">Contracts</h3> */}
                <div className="d-flex">
                    <h3 className="page_title_left">Contracts</h3>
                    <Contract_Filter filters={filter} onSubmit={this.onFilterClick} agencies={agencies} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.contractFilterby()} columns={columns}
                                filter={filterFactory()} />
            </div>
        )
    }
}

function mapStateToProps({org_contracts, agencies, cur_org}) {
    return {
        cur_org: cur_org,
        contracts: org_contracts,
        agencies: agencies,
    }
}

export default connect(mapStateToProps, {fetchAgencies, showErrorGrowl, fetchOrgContracts})(ContractList);