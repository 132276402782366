/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {Card, Form, Button, Col} from "react-bootstrap";
import {CommonUtils} from "../../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";


class ContractNewPage4Comp extends React.Component {
    onSubmit = (values, actions) => {
        console.log('Submitting step 4')
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }
    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage} = this.props;
        return (
            <Formik
                initialValues={{
                    specType: contract.specType,
                    specRevNo: contract.specRevNo?contract.specRevNo:undefined,
                    specDate: contract.specDate,
                    followsOn: contract.followsOn,
                    followsOnContractNo: contract.followsOnContractNo?contract.followsOnContractNo:undefined,
                    finalDD254:contract.finalDD254,
                    finalReqPeriod:contract.finalReqPeriod?contract.finalReqPeriod:undefined,
                    finalReqDate: contract.finalReqDate?contract.finalReqDate:undefined
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        specDate: Yup.date().required('Enter specification date'),
                        specRevNo: Yup.string().test(
                            'rev-no-req-test',
                            'Please enter a revision number',
                            function (value) {
                                console.log('spec type is ', this.parent.specType)
                                if (this.parent.specType !== 'rev'){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        followsOnContractNo: Yup.string().test(
                            'prec-cont-no-req-test',
                            'Please enter the preceding contract number',
                            function (value) {
                                if (this.parent.followsOn !== true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        finalReqPeriod: Yup.string().test(
                            'final-DD-254-req-test',
                            'Please enter the final DD-254 authorization Period',
                            function (value) {
                                if (this.parent.finalDD254 !== true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        finalReqDate: Yup.date().test(
                            'final-req_date-test',
                            'Please enter the contractor request date',
                            function (value) {
                                if (this.parent.finalDD254 !== true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        )

                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 3</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId={"specType"}>
                                        <Form.Label>Specification Type</Form.Label>
                                        <Form.Control required as="select" name={'specType'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.specType && !!errors.specType}
                                                      value={values.specType}
                                        >
                                            {CommonUtils.getOptionsForSelect([
                                                {id: "orig", name: "Original"},
                                                {id: "rev", name: "Revised"},
                                                {id: "final", name: "Final"}])}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.specType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'specDate'}>
                                        <Form.Label>Specification Date</Form.Label>
                                        <Field
                                            id="specDate"
                                            name="specDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={"Due Date (MM/DD/YYYY)"}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.specDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {(values.specType === 'rev') &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={"specRevNo"}>
                                            <Form.Label>Revision Number</Form.Label>
                                            <Form.Control required type="text" name={'specRevNo'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter revision number'}
                                                          isInvalid={touched.specRevNo && !!errors.specRevNo}
                                                          value={values.specRevNo}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.specRevNo}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                }
                            </Card.Body>

                            <hr></hr>
                        
                            <Card.Header>Section 4</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'followsOn'}>
                                        <Form.Check
                                            name={'followsOn'}
                                            label={'This is a follow on contract'}
                                            checked={values.followsOn}
                                            onChange={(event) => {
                                                setFieldValue('followsOn', event.target.checked);
                                                console.log('Value are ', values);
                                                handleChange(event);
                                            }}
                                            isInvalid={touched.followsOn && !!errors.followsOn}
                                            id={'followsOn'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.followsOn}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {(values.followsOn === true) &&
                                    <Form.Group as={Col} md="4" controlId={"followsOnContractNo"}>
                                        <Form.Label>Preceding Contract Number</Form.Label>
                                        <Form.Control required type="text" name={'followsOnContractNo'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.followsOnContractNo && !!errors.followsOnContractNo}
                                                      value={values.followsOnContractNo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.followsOnContractNo}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }
                                </Form.Row>
                            </Card.Body>

                            <hr></hr>
                       
                            <Card.Header>Section 5</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId={'finalDD254'}>
                                        <Form.Check
                                            name={'finalDD254'}
                                            label={'This is the final DD-254'}
                                            checked={values.finalDD254}
                                            onChange={(event) => {
                                                setFieldValue('finalDD254', event.target.checked);
                                                console.log('Value are ', values);
                                                handleChange(event);
                                            }}
                                            isInvalid={touched.finalDD254 && !!errors.finalDD254}
                                            id={'finalDD254'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.finalDD254}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {(values.finalDD254 === true) &&
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId={"finalReqPeriod"}>
                                        <Form.Label>Authorization Period</Form.Label>
                                        <Form.Control required type="text" name={'finalReqPeriod'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.finalReqPeriod && !!errors.finalReqPeriod}
                                                      value={values.finalReqPeriod}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.finalReqPeriod}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId={'finalReqDate'}>
                                        <Form.Label>Contractor Request Date</Form.Label>
                                        <Field
                                            id="finalReqDate"
                                            name="finalReqDate"
                                            component={FormikDatePickerComponent}
                                            placeholder={"Due Date (MM/DD/YYYY)"}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.finalReqDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                }
                              
                            </Card.Body>

                            <Card.Footer>
                            <ButtonToolbar>
                                <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                        handleDeleteCallback={this.handleCancel}/>
                            </ButtonToolbar>  
                            </Card.Footer>
                        </Card>

                       
                        
                    </Form>
                )}
            </Formik>);

    }
}

export const ContractNewPage4 = ContractNewPage4Comp;

ContractNewPage4.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};
