import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import {checkoutAsset} from "../../../../actions/asset_actions";
import {CommonUtils} from "../../../../actions/common_utils";
import {getActiveOrgPersons} from "../../../../actions/subject_actions";

class AssetCheckoutModalComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        this.getSubjects();
    }

    getSubjects() {
        getActiveOrgPersons((response) => {
            let newEntityIds = [
                {
                    id: "",
                    name: "Select Subject"
                }
            ];
            response.data.forEach((subject) => {
                newEntityIds.push({
                    id: subject.id,
                    name: `${subject.personFirstName?subject.personFirstName:''} ${subject.personLastName}`
                });
            });

            this.setState({
                availEntities: newEntityIds,
            });
        });
    }

    open = () => {
        this.setState({
            showModal: true,
        });
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    onSubmit = async (values) => {
        values.id = this.props.asset.id;
        try{
            await checkoutAsset(values);
            this.props.showSuccessGrowl("Asset Checkout", "The asset has been checked out successfully!");
            this.props.onFinish();
            this.close();
        } catch (error) {
            this.props.showErrorGrowl(`Asset Checkout", "Unexpected error checking out Asset. Error is ${error.response.data}`);
        }
    }

    render() {
        const {showModal, availEntities} = this.state;
        return (
            <Fragment>
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" style={{padding: 10}}>
                            <i className="fa fa-arrow-circle-right" title={"Checkout"}/>
                        </span>
                </Button>

                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Checkout Asset</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "checkedOutSubjectId": "",
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                checkedOutSubjectId: Yup.string().required('Subject is required for checkout'),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Checked Out By:</Form.Label>
                                            <Form.Control required as="select" name={'checkedOutSubjectId'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Select who is checking out the asset'}
                                                          isInvalid={touched.checkedOutSubjectId && !!errors.checkedOutSubjectId}
                                                          value={values.checkedOutSubjectId}
                                            >
                                                {CommonUtils.getOptionsForSelect(availEntities)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.checkedOutSubjectId}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Check Out</Button>
                                        <Button variant="danger" onClick={this.close}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const AssetCheckoutModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl
})(AssetCheckoutModalComponent);

AssetCheckoutModal.propTypes = {
    asset: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired
}