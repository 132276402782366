/**
 * Created by pshivaraman on 1/14/19.
 */

import React, {Component, Fragment} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Link} from "react-router-dom";
import {
    addFacilityToSubjects,
    fetchSubjectsByFacilityId,
    removeFacilityFromSubjects,
    searchSubjectsNotPartOfFacility,
    updateFacilityRelForSubjects
} from "../../../../../actions/subject_actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AddSubjectModal from "../../../../../components/subject/add_subject_modal";
import FacilitySubjectEditRelModal from "./facility_subject_edit_rel_modal";
import {showErrorGrowl, showSuccessGrowl} from "../../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay, isReadOnlyRole} from "../../../../util/shouldDisplay";

class FacilitySubjectList extends Component {
    constructor(props) {
        super(props);

        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
        this.onSubjectsAdd = this.onSubjectsAdd.bind(this);
        this.subjectSearch = this.subjectSearch.bind(this);
        this.removeSubject = this.removeSubject.bind(this);
        this.removeSelectedSubjects = this.removeSelectedSubjects.bind(this);
        this.editRelationshipSelectedSubjects = this.editRelationshipSelectedSubjects.bind(this);
        this.onEditModalClose = this.onEditModalClose.bind(this);
        this.onEditRelFinished = this.onEditRelFinished.bind(this);

        this.state = {
            selected: [],
            showEditModal: false
        };
    }

    componentDidMount() {
        this.props.fetchSubjectsByFacilityId(this.props.facilityId);
    }

    handleOnSelect(row, isSelect) {
        if (isSelect) {
            this.setState({
                selected: [...this.state.selected, row.id]
            });
        } else {
            this.setState({
                selected: this.state.selected.filter(x => x !== row.id)
            });
        }
    }

    handleOnSelectAll(isSelect, rows) {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            this.setState({
                selected: ids
            });
        } else {
            this.setState({
                selected: []
            });
        }
    }

    onSubjectsAdd(subjects) {
        this.props.addFacilityToSubjects(this.props.facilityId, subjects, () => {
            this.props.fetchSubjectsByFacilityId(this.props.facilityId);
            this.setState({selected: []});
        });
    }

    subjectSearch(term, callback) {
        this.props.searchSubjectsNotPartOfFacility(this.props.facilityId, term, callback);
    }

    removeSubject(subjectId) {
        this.props.removeFacilityFromSubjects(this.props.facilityId, Array.of(subjectId), () => {
            this.props.fetchSubjectsByFacilityId(this.props.facilityId);
            this.setState({selected: []});
        });
    }

    removeSelectedSubjects() {
        if (this.state.selected && this.state.selected.length !== 0) {
            this.props.removeFacilityFromSubjects(this.props.facilityId, this.state.selected, () => {
                this.props.fetchSubjectsByFacilityId(this.props.facilityId);
                this.setState({selected: []});
                this.props.showSuccessGrowl("Subjects Removed", "Subjects were removed from the facility successfully");
            });
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least 1 subject");
        }
    }

    editRelationshipSelectedSubjects() {
        if (this.state.selected && this.state.selected.length !== 0) {
            this.setState({showEditModal: true});
        } else {
            this.props.showErrorGrowl("No Subjects Selected", "Select at least 1 subject");
        }
    }

    onEditModalClose() {
        this.setState({showEditModal: false, selected: []});
        this.props.fetchSubjectsByFacilityId(this.props.facilityId);
    }

    onEditRelFinished(relType, callback) {
        this.props.updateFacilityRelForSubjects(this.props.facilityId, this.state.selected, relType, () => {
            callback();
            this.props.showSuccessGrowl("Subjects' Relationships Edited");
        });
    }

    render() {
        const {subjects} = this.props;
        const {showEditModal} = this.state;

        if (!subjects) {
            return <div>Loading...</div>
        }

        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'rel',
            text: 'Relationship',
            formatter: (cell, row) => {
                const relObj = row.facilities.find(facility => facility.facilityId === this.props.facilityId);
                // TODO: Update to show readable name
                return relObj ? relObj.relType : 'Unknown';
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    ConditionalDisplay(
                <Fragment>
                    <Button variant={'link'} className="close" onClick={() => this.removeSubject(row.id)}>
                            <span aria-hidden="true" style={{padding: 10}} title={"Remove Subject From Location"} aria-label="Remove Subject From Facility">
                            <i class="fa fa-times-circle"></i>
                            </span>
                    </Button>
                </Fragment>
                        )
                )
            }
        }];

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                {/* <h3>Subjects</h3> */}
                {ConditionalDisplay(
                <ButtonToolbar className="mb-3">
                    <Button onClick={this.editRelationshipSelectedSubjects}>
                        <span className="glyphicon glyphicon-edit" aria-hidden="true"/> Edit Relationships
                    </Button>
                    <Button onClick={this.removeSelectedSubjects}>
                        <span className="glyphicon glyphicon-trash" aria-hidden="true"/> Remove Subjects
                    </Button>
                    <AddSubjectModal onSubjectsAdd={this.onSubjectsAdd} searchFunction={this.subjectSearch}/>
                </ButtonToolbar>
                )}
                {isReadOnlyRole() &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={subjects}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
                }
                {!isReadOnlyRole() &&
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={subjects}
                                columns={columns} selectRow={selectRow} pagination={pagination}
                                filter={filterFactory()}/>
                }
                {showEditModal && !isReadOnlyRole() &&
                <FacilitySubjectEditRelModal onClose={this.onEditModalClose} onFinish={this.onEditRelFinished}/>}
            </Fragment>
        )
    }
}

FacilitySubjectList.propTypes = {
    facilityId: PropTypes.string.isRequired
};

function mapStateToProps({subjects}) {
    return {
        subjects: subjects
    }
}

export default connect(mapStateToProps, {
    fetchSubjectsByFacilityId,
    searchSubjectsNotPartOfFacility,
    addFacilityToSubjects,
    removeFacilityFromSubjects,
    showErrorGrowl,
    updateFacilityRelForSubjects,
    showSuccessGrowl
})(FacilitySubjectList);