import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { CommonUtils } from "../../../../actions/common_utils";
import { getMediaTypesForDocControl } from "../../../../actions/fieldValue_actions";
import { forEach } from "lodash";

export const DocList_Filter = ({ filters, onSubmit }) => {
    const [ShowHover, setShowHover] = useState(false);
    const [mediaType, setMediaType] = useState(null)
    const popupRef = useRef(null);
 
    useEffect(() => {
        fetchDropDownOptions(); 
        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => {
        onSubmit({
            controlNumber:"",
            description:"",
            classificationLevel:"",
            workingPaperStr:"",
            destroyed:"",
            mediaType:"",
            contractNumber:""
        })
        setShowHover(false)
    }
    const trueFalseOptions = [
        {id: "Yes", name: "Yes"},
        {id: "No", name: "No"}
    ]

   const fetchDropDownOptions = async() => {
        try {
            let _MediaType = [{id:"",name :"Selec Media Type"}];
            let mediaTypeArray = await getMediaTypesForDocControl();
            mediaTypeArray.forEach(element=>{
                _MediaType.push({id:element.id,name:element.displayName});
            })
            setMediaType(_MediaType);
        } catch (e) {
            console.log('Error found ', e);
        }
    }

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Control Number</label>
                                        <Form.Control
                                            type="text"
                                            name={"controlNumber"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Control Number"}
                                            value={values.controlNumber}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Description</label>
                                        <Form.Control
                                            type="text"
                                            name={"description"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Description"}
                                            value={values.description}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Classification Level</label>
                                        <Form.Control
                                            type="text"
                                            name={"classificationLevel"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Classification Level"}
                                            value={values.classificationLevel}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Is Working Paper</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"workingPaperStr"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.workingPaperStr && !!errors.workingPaperStr
                                            }
                                            value={values.workingPaperStr}
                                        >
                                        {CommonUtils.getOptionsForSelect([{ id: "", name: "Select Is Working Paper" }, ...trueFalseOptions])}
                                        </Form.Control>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Is Destroyed</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"destroyed"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.destroyed && !!errors.destroyed
                                            }
                                            value={values.destroyed}
                                        >
                                            {CommonUtils.getOptionsForSelect([{id: "", name: "Select Is Destroyed"}, ...trueFalseOptions])}
                                        </Form.Control>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Media Type</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"mediaType"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.mediaType && !!errors.mediaType
                                            }
                                            value={values.mediaType}
                                        >
                                            {CommonUtils.getOptionsForSelect(mediaType)}
                                        </Form.Control>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Contract Number</label>
                                        <Form.Control
                                            type="text"
                                            name={"contractNumber"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Contract Number"}
                                            value={values.contractNumber}
                                        />
                                    </div> 
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}