/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import Form from "react-bootstrap/Form";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import {Formik} from "formik";
import * as Yup from "yup";
import {Card} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import MaskedInput from "react-input-mask";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage9Comp extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage} = this.props;

        return (
            <Formik
                initialValues={{
                    certOfficialName: contract.certOfficialName,
                    certOfficialTitle: contract.certOfficialTitle,
                    certOfficialPhone: contract.certOfficialPhone,
                    certOfficialAddress: contract.certOfficialAddress,
                    certCageCode: contract.certCageCode,
                    certAAC: contract.certAAC,
                    certEmailAddress: contract.certEmailAddress,
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        certOfficialName: Yup.string().required('Certifying Official Name is required')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 17</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Certifying Official Name</Form.Label>
                                        <Form.Control required type="text" name={'certOfficialName'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter Certifying Official Name'}
                                                      isInvalid={touched.certOfficialName && !!errors.certOfficialName}
                                                      value={values.certOfficialName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certOfficialName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Certifying Official Title</Form.Label>
                                        <Form.Control required type="text" name={'certOfficialTitle'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter Certifying Official Title'}
                                                      isInvalid={touched.certOfficialTitle && !!errors.certOfficialTitle}
                                                      value={values.certOfficialTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certOfficialTitle}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>AAC of the contracting office</Form.Label>
                                        <Form.Control required type="text" name={'certAAC'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter Activity Address Code'}
                                                      isInvalid={touched.certAAC && !!errors.certAAC}
                                                      value={values.certAAC}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certAAC}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Cage Code Of Prime Contractor</Form.Label>
                                        <Form.Control required type="text" name={'certCageCode'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Enter Cage Code Of Prime Contractor'}
                                                      isInvalid={touched.certCageCode && !!errors.certCageCode}
                                                      value={values.certCageCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certCageCode}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="8">
                                        <Form.Label>Certifying Office Address</Form.Label>
                                        <Form.Control required type="text" name={'certOfficialAddress'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Enter Certifying Official Address'}
                                                      isInvalid={touched.certOfficialAddress && !!errors.certOfficialAddress}
                                                      value={values.certOfficialAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certOfficialAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    
                                    <Form.Group as={Col} md={"4"} controlId="certOfficialPhone">
                                        <Form.Label>Certifying Official Phone Number</Form.Label>
                                        <Form.Control
                                            required
                                            as={MaskedInput}
                                            name="certOfficialPhone"
                                            mask="999-999-9999"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Certifying Official Phone Number"
                                            isInvalid={touched.certOfficialPhone && !!errors.certOfficialPhone}
                                            value={values.certOfficialPhone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certOfficialPhone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={"4"} controlId="certEmailAddress">
                                        <Form.Label>Certifying Official Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="certEmailAddress"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Certifying Official Email Address"
                                            isInvalid={touched.certEmailAddress && !!errors.certEmailAddress}
                                            value={values.certEmailAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.certEmailAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                       <Button variant={'outline-primary'} className="pager-button"
                                                           onClick={previousPage}>Previous</Button>
                                       <Button variant={'outline-primary'} className="pager-button"
                                                           disabled={isSubmitting}
                                                           type="submit">Next</Button>
                                                             <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        );

    }
}
export const ContractNewPage9 = ContractNewPage9Comp;
ContractNewPage9.propTypes = {
onStepComplete: PropTypes.func.isRequired,
contract: PropTypes.object.isRequired,
previousPage: PropTypes.func.isRequired
};

