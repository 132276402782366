import React, {Component, Fragment} from "react";
import {Button, ButtonToolbar, Card, Col, Form, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Field, Formik} from "formik";
import {CommonUtils} from "../../../../actions/common_utils";
import {createDocControl} from "../../../../actions/doc_control_actions";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {getMediaTypesForDocControl} from "../../../../actions/fieldValue_actions";
import {getActiveContractsForDocControl} from "../../../../actions/contract_actions";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class DocControlNewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availSecurityClearanceLevel: ['NONE', 'CONFIDENTIAL', 'SECRET', 'TOP_SECRET'],
            showModal: false,
        }
    }

    componentDidMount() {
        this.fetchDropDownOptions();
        this.getContracts();
    }

    fetchDropDownOptions = async () => {
        try {
            let mediaTypes = await getMediaTypesForDocControl();
            this.setState({
                mediaTypes
            })
        } catch (e) {
            console.log('Error found ', e);
        }
    }

    getContracts = async () => {
        try {
            const contracts = await getActiveContractsForDocControl();
            console.log('Contracts got are ', contracts)
            let newEntityIds = [
                {
                    id: "",
                    name: "Select Contract"
                }
            ];
            contracts.forEach((contract) => {
                newEntityIds.push({
                    id: contract.id,
                    name: contract.contractNo
                });
            });

            console.log('Contract Number is ', newEntityIds)
            this.setState({
                availEntities: newEntityIds,
            });
        } catch (e) {
            console.log('Error fetching contracts', e);
            this.props.showErrorGrowl("Unexpected error occurred", "Error fetching contracts");
        }
    }

    onSubmit = async (values) => {
        try {
            await createDocControl(values);
            this.props.history.push("/org/dash/docControl");
        } catch (e) {
            console.log('Error creating doc control', e);
            this.props.showErrorGrowl("Unexpected error occurred", "Error creating doc control.");
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/docControl">
    //                 Document Control
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Document Control
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    close = () => {
        this.setState({showModal: false, warningMessage: null});
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {warningMessage, showModal, mediaTypes, availEntities, availSecurityClearanceLevel} = this.state;

        return (
            <Formik
                initialValues={{
                    "controlNumber": "",
                    "description": "",
                    "workingPaper": false,
                    "mediaType": "ELECTRONIC",
                    classificationLevel: 'NONE',
                    "docCreatedDate": "",
                    "docReceivedDate": "",
                    "storageLocation": "",
                    "storageSafeNumber": "",
                    "storageDrawerNumber": "",
                    "storageBeginDate": "",
                    "contractId":""
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        controlNumber: Yup.string().required('Enter the control number'),
                        description: Yup.string().required('Please enter a description'),
                        mediaType: Yup.string().required('Enter the media type for document'),
                        docCreatedDate: Yup.date().required('Enter the document creation date'),
                        classificationLevel: Yup.string().required('Select a classification level'),
                        docReceivedDate: Yup.date().required('Enter the document received date'),
                        storageLocation: Yup.string().required('Enter storage location'),
                        storageBeginDate: Yup.date().required('Enter storage begin date'),
                        contractId: Yup.string().test(
                            'contract-no-req-test',
                            'Please select a contract',
                            function (value) {
                                if (this.parent.workingPaper){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* <h3>New Document Control</h3> */}
                            <Card>
                                <Card.Header>New Document Control Creation Form</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={'controlNumber'}>
                                            <Form.Label>Control Number</Form.Label>
                                            <Form.Control required type="text" name={'controlNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the Control Number'}
                                                          isInvalid={touched.controlNumber && !!errors.controlNumber}
                                                          value={values.controlNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.controlNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={'4'} controlId={'description'}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control required type="text" name={'description'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter Description'}
                                                          isInvalid={touched.description && !!errors.description}
                                                          value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Storage Media Type</Form.Label>
                                            <Form.Control required as="select" name={'mediaType'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.mediaType && !!errors.mediaType}
                                                          value={values.mediaType}
                                            >
                                                {CommonUtils.getOptionsForSelect(mediaTypes)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mediaType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={"classificationLevel"}>
                                            <Form.Label>Classification Level</Form.Label>
                                            <Form.Control required as="select" name={'classificationLevel'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.classificationLevel && !!errors.classificationLevel}
                                                          value={values.classificationLevel}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availSecurityClearanceLevel))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.classificationLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" className="d_flex_center">
                                            <Form.Check
                                                name={`workingPaper`}
                                                label={'Is Working Paper?'}
                                                onChange={handleChange}
                                                isInvalid={touched.workingPaper && !!errors.workingPaper}
                                                feedback={errors.workingPaper && errors.workingPaper}
                                                id={`workingPaper`}
                                            />
                                        </Form.Group>
                                        {!values.workingPaper &&
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Select Contract</Form.Label>
                                                <Form.Control as="select" name={'contractId'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Select a contract to associate with'}
                                                              isInvalid={touched.contractId && !!errors.contractId}
                                                              value={values.contractId}
                                                >
                                                    {CommonUtils.getOptionsForSelect(availEntities)}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contractId}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        }

<Form.Group as={Col} md="4" controlId={'docCreatedDate'}>
                                            <Form.Label>Document Creation Date</Form.Label>
                                            <Field
                                                id="docCreatedDate"
                                                name="docCreatedDate"
                                                maxDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"When document was created"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'docReceivedDate'}>
                                            <Form.Label>Document Received Date</Form.Label>
                                            <Field
                                                id="docReceivedDate"
                                                name="docReceivedDate"
                                                maxDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Document received date"}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'storageLocation'}>
                                            <Form.Label>Storage Location</Form.Label>
                                            <Form.Control type="text" name={'storageLocation'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage location'}
                                                          isInvalid={touched.storageLocation && !!errors.storageLocation}
                                                          value={values.storageLocation}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageLocation}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'storageSafeNumber'}>
                                            <Form.Label>Storage Safe Number</Form.Label>
                                            <Form.Control type="text" name={'storageSafeNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage safe number'}
                                                          isInvalid={touched.storageSafeNumber && !!errors.storageSafeNumber}
                                                          value={values.storageSafeNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageSafeNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'storageDrawerNumber'}>
                                            <Form.Label>Storage Drawer Number</Form.Label>
                                            <Form.Control type="text" name={'storageDrawerNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage drawer number'}
                                                          isInvalid={touched.storageDrawerNumber && !!errors.storageDrawerNumber}
                                                          value={values.storageDrawerNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageDrawerNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'storageBeginDate'}>
                                            <Form.Label>Storage Begin Date</Form.Label>
                                            <Field
                                                id="storageBeginDate"
                                                name="storageBeginDate"
                                                maxDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Storage Begin Date"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                   
                                    <Fragment>
                                        <Modal show={showModal} onHide={this.close}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>{"Document Control creation confirmation"}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>{warningMessage}</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button onClick={()=>{
                                                    resetForm();
                                                    this.close();
                                                }} variant={"danger"}>No</Button>
                                                <Button onClick={this.onConfirm} variant={"primary"}>Yes</Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            {/* <Link to="/org/dash/docControl" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Document Control Creation Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                        </ButtonToolbar>
                                    </Fragment>
                                </Card.Body>
                            </Card>
                    </Form>
                )}
            </Formik>
        )
    }
}

export const DocControlNew = connect(null, {showSuccessGrowl, showErrorGrowl})(DocControlNewComponent);

