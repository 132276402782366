import React, {Component, Fragment} from "react";
import {Button, ButtonToolbar, Card, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {fetchAsset, updateAsset} from "../../../../actions/asset_actions";
import {Formik} from "formik";
import PropTypes from 'prop-types';
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {getAssetManagementStatus} from "../../../../actions/fieldValue_actions";
import {CommonUtils} from "../../../../actions/common_utils";

class AssetUpdateComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.fetchAssetAsync()

        getAssetManagementStatus((response) => {
            let assetStatusList = [
                {
                    id: "",
                    name: "Select a Status"
                }
            ];
            response.data.forEach((assetStatus) => {
                assetStatusList.push({
                    id: assetStatus.id,
                    name: assetStatus.displayName
                });
            });
            this.setState({
                assetManagementStatusOptions: assetStatusList
            })
        })
    }

    fetchAssetAsync = async () => {
        const asset = await fetchAsset(this.props.match.params.id);
        this.setState({asset});
    }

    handleCancel = () => {
        window.history.back();
    }

    onSubmit = async (values, actions) => {
        let response = undefined;
        try {
            if (values.assetManagementStatus === ''){
                values.assetManagementStatus = null;
            }
            actions.setSubmitting(false);
            if (values.status){
                values.status = values.status.name;
            }
            response = await updateAsset(values);
            if (response.status === 200){
                this.props.showSuccessGrowl("Asset update success", "Asset updated successfully!");
            }
            this.props.history.push("/org/dash/asset");
        } catch (e) {
            console.log('Error updating asset', e);
            this.props.showErrorGrowl("Unexpected error occurred", "Error updating asset.");
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/asset">
    //                 Assets
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Update Asset
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    close = () => {
        this.setState({showModal: false, warningMessage: null});
    }

    render() {
        const {asset, assetManagementStatusOptions} = this.state;
        if (!asset){
            return <div>Loading...</div>;
        }
        if (!asset.serialNumber){
            asset.serialNumber = '';
        }
        return (
            <Formik
                initialValues={{...asset}}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        itemNumber: Yup.string().required('Enter the item number'),
                        description: Yup.string().required('Please enter a description'),
                        serialNumber: Yup.string().test(
                            'serialNumber-asset-test',
                            'Please enter a Serial Number',
                            function (value) {
                                console.log('parent badge is ', this.parent.badge)
                                if (this.parent.badge === true){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        )
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* <h3 className="page_title_left mb-3">Update Asset</h3> */}
                            <Card>
                                <Card.Header>Asset Update Form</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={'itemNumber'}>
                                            <Form.Label>Item Number</Form.Label>
                                            <Form.Control required type="text" name={'itemNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the Item Number'}
                                                          isInvalid={touched.itemNumber && !!errors.itemNumber}
                                                          value={values.itemNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.itemNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'serialNumber'}>
                                            <Form.Label>Serial Number</Form.Label>
                                            <Form.Control required type="text" name={'serialNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the Serial Number'}
                                                          isInvalid={touched.serialNumber && !!errors.serialNumber}
                                                          value={values.serialNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.serialNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'brand'}>
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control type="text" name={'brand'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the brand name: Ex: iPhone/Samsung etc'}
                                                          isInvalid={touched.brand && !!errors.brand}
                                                          value={values.brand}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.brand}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      
                                        <Form.Group as={Col} md={'8'} controlId={'description'}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control required type="text" name={'description'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter Description'}
                                                          isInvalid={touched.description && !!errors.description}
                                                          value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'assetManagementStatus'}>
                                            <Form.Label>Asset Management (If lost/stolen etc)</Form.Label>
                                            <Form.Control as="select" name={'assetManagementStatus'}
                                                          onChange={handleChange}
                                                          placeholder={'Select if your device was stolen/lost/destroyed/other'}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.assetManagementStatus && !!errors.assetManagementStatus}
                                                          value={values.assetManagementStatus?values.assetManagementStatus.id:''}
                                            >
                                    {CommonUtils.getOptionsForSelect(assetManagementStatusOptions)}
                                            </Form.Control>        
                                            <Form.Control.Feedback type="invalid">
                                                {errors.assetManagementStatus}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Form.Row>
                                    <Fragment>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Asset Update Form"
                                                  handleDeleteCallback={this.handleCancel}/>
                                            {/* <Link to="/org/dash/asset" className="btn btn-danger">Cancel</Link> */}
                                        </ButtonToolbar>
                                    </Fragment>
                                </Card.Body>
                            </Card>
                    </Form>
                )}
            </Formik>
        )
    }
}

export const AssetUpdate = connect(null, {showSuccessGrowl, showErrorGrowl})(AssetUpdateComponent);
