import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {DateUtils} from "../../../../actions/common_utils";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {getContractTaskOrders} from "../../../../actions/contract_actions";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {Button} from "react-bootstrap";
import {PARENT_TASK_ORDER_CONTRACT} from "../../../../actions/action_constants";
import {withRouter} from 'react-router-dom';

class ContractTaskOrderListComp extends Component {
    constructor(props) {
        super(props);
        this.state={
            taskOrders: []
        }
    }

    componentDidMount() {
        this.loadTaskOrders(this.props.contract);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.contract !== nextProps.contract) {
            this.loadTaskOrders(nextProps.contract);
        }
    }

    loadTaskOrders = async (contract) => {
        if (contract && contract.id) {
            const response = await getContractTaskOrders(contract.id);
            console.log('Response for task order list is ', response)
            this.setState({
                taskOrders: response
            })
        }
    }

    onNewTO = () => {
        const {contract} = this.props;
        sessionStorage.setItem(PARENT_TASK_ORDER_CONTRACT, JSON.stringify(contract));
        console.log('Contract is ', this.props);
        this.props.history.push(`/org/dash/contract/new/${contract.id}`);
    }

    render() {
        const {taskOrders} = this.state;
        if (!taskOrders) {
            return <div>Loading...</div>
        }

        const columns = [
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "contractNo",
                text: "Contract Number",
                formatter: (cell, row) => <Link to={`/org/dash/contract/${row.id}`}>{cell}</Link>,
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                title: this.titleFunction,
                formatter: (cell, row) => cell,
            },
            {
                dataField: "daysLeft",
                text: "Time Remaining",
                formatter: (cell, row) => DateUtils.getDaysLeft(row.endDate, 30),
                sort: true,
                title: this.titleFunction
            },
            {
                dataField: "endDateStr",
                text: "End Date",
                formatter: (cell) => cell,
                sort: true,
                title: this.titleFunction
            }
        ];

        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <Fragment>
                 {ConditionalDisplay(
                    <div className="float-right mb-3">
                        <ButtonToolbar>
                            <Button variant="primary" type="submit" onClick={this.onNewTO}>New Task Order</Button>
                        </ButtonToolbar>
                    </div>
                )}

                <div className="d-flex">
                <h3 className="page_title_left">Task Orders</h3>
                </div>
               
               
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={taskOrders}
                                columns={columns} pagination={pagination}
                                filter={filterFactory()}/>
            </Fragment>
        )
    }
}

export const ContractTaskOrderList = withRouter(connect(null, null)(ContractTaskOrderListComp));

ContractTaskOrderList.propTypes = {
    contract: PropTypes.object.isRequired
};

