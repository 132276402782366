/**
 * Created by pshivaraman on 6/8/18.
 */

import React from 'react';
import {CON_IN_THE_WORKS, CUR_ORG, FAC_FOR_ORG_CON_CREATE} from "../../../../actions/session_constants";
import {connect} from "react-redux";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {
    getAccessReqsByAccessor,
    getAgencies,
    getDistributions,
    getFacilityLevels
} from "../../../../actions/fieldValue_actions";
import {
    createOrgContract,
    fetchOrgContract,
    fetchOrgContracts,
    updateOrgContract
} from "../../../../actions/contract_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import MaskedInput from "react-input-mask";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import {FieldRBCheckbox} from "../../../../components/redux_form/field_components_rb";
import { FETCH_ORG_CONTRACTS } from '../../../../actions/action_constants';

class ContractUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.cancelUpdate = this.cancelUpdate.bind(this);

        this.state = {
            agencies: [],
            levels: [],
            reqLevels: [],
            access_reqs: [],
            contractor_access_reqs: [],
            dists: [],
            contract: null
        };
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        if (values.expirationNotification && values.expirationNotification.id){
            values.expirationNotification = values.expirationNotification.id
        }
        if (this.checkIfItisForConFromDD254()) {
            this.props.createOrgContract(values, () => {
                sessionStorage.setItem(CON_IN_THE_WORKS, null);
                sessionStorage.setItem(FAC_FOR_ORG_CON_CREATE, null);
                this.props.history.push("/org/dash/contract");
            });
        } else {
            this.props.updateOrgContract(values, this.props.match.params.id, () => {
                this.cancelUpdate();
            });
        }
    }

    checkIfItisForConFromDD254() {
        return this.props.match.params.id === CON_IN_THE_WORKS && sessionStorage.getItem(CON_IN_THE_WORKS);
    }

    cancelUpdate() {
        if (this.checkIfItisForConFromDD254()) {
            this.props.history.push("/org/dash/contract");
        } else {
            this.props.history.push(`/org/dash/contract/${this.props.match.params.id}`);
        }
    }

    componentDidMount() {
        this.agencies = [];
        getAgencies((response) => {
            response.data.push({
                name: "None",
                id: "none",
            });
            this.setState({agencies: response.data});
        });
        if (!this.checkIfItisForConFromDD254()){
            this.props.fetchOrgContract(this.props.match.params.id);
        }
        getFacilityLevels((response) => {
            // remove none option to the FCL
            let reqLevels = [];
            for (let level of response.data){
                if ('None'.toLowerCase() !== level.displayName.toLowerCase()){
                    reqLevels.push(level);
                }
            }
            this.setState({
                levels: response.data,
                reqLevels
            });
        });
        getAccessReqsByAccessor("contract", accessReqsResp => {
            this.setState({access_reqs: accessReqsResp.data});
        });

        getAccessReqsByAccessor("contractor", contractorAccessReqsResp => {
            this.setState({contractor_access_reqs: contractorAccessReqsResp.data});
        });
        getDistributions(distResp => {
            this.setState({dists: distResp.data});
        });
        this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
    }

    componentDidUpdate() {
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/contract"
    //                             onClick={() => fetchOrgContracts(sessionStorage.getItem(CUR_ORG))}>
    //                 Contracts
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Contract {this.props.contract.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    initializeNullValues(contract) {
        console.log('Contract is ', contract);
        if (!contract.name){
            contract.name = '';
        }
        if (!contract.status){
            contract.status = 'Active';
        }
        if (!contract.agency){
            if (this.state.agencies && this.state.agencies.length > 0) {
                console.log('Agencies are ', this.state.agencies)
                contract.agency = this.state.agencies[0].id;
            }
        }
        if (contract.clearanceLevel && contract.clearanceLevel.id){
            contract.clearanceLevel = contract.clearanceLevel.id;
        }
        if (contract.safeguardLevel && contract.safeguardLevel.id){
            contract.safeguardLevel = contract.safeguardLevel.id;
        }
        if (!contract.specType){
            contract.specType = 'orig';
        }
        if (!contract.addSecurityDesc) {
            contract.addSecurityDesc = '';
        }
        if (!contract.followsOnContractNo) {
            contract.followsOnContractNo = '';
        }
        if (!contract.specRevNo){
            contract.specRevNo = '';
        }
        if (!contract.throughReleaseDesc){
            contract.throughReleaseDesc = '';
        }
        if (!contract.inspectionsDesc){
            contract.inspectionsDesc = '';
        }
        if (!contract.otherDistrReqd){
            contract.otherDistrReqd = '';
        }
        if (!contract.finalReqPeriod){
            contract.finalReqPeriod = '';
        }
        if (!contract.finalReqDate){
            contract.finalReqDate = '';
        }
        if (!contract.primeContractNo){
            contract.primeContractNo = '';
        }
        if (!contract.certOfficialName){
            contract.certOfficialName = '';
        }
        if (!contract.gcaName){
            contract.gcaName = '';
        }
        if (!contract.specDate){
            contract.specDate = '';
        }
        if (!contract.releaseType){
            contract.releaseType = '';
        }
    }

    render() {

        let contract = null;
        let cageCodes = [];
        let {org_contracts} = this.props;
        let contractNumberStr = '';
        if (org_contracts) {
            for (let contract of org_contracts) {
                contractNumberStr = contractNumberStr + ',' + contract.contractNo;
            }
        }
        if (this.checkIfItisForConFromDD254() ){
            contract = JSON.parse(sessionStorage.getItem(CON_IN_THE_WORKS));
            cageCodes = JSON.parse(sessionStorage.getItem(FAC_FOR_ORG_CON_CREATE));
        } else {
            contract = this.props.contract;
        }
        if (!contract || (!contract.id && !this.checkIfItisForConFromDD254())) {
            return (
                <div>Loading...</div>
            );
        }

        this.initializeNullValues(contract);
        const avail_distributions = this.state.dists.map((dist) => {
            return {
                value: dist.id,
                label: dist.name
            }
        });
        const avail_access_reqs = this.state.access_reqs.map((accessReq) => {
            return {
                value: accessReq.id,
                label: accessReq.name
            }
        });

        const avail_contractor_access_reqs = this.state.contractor_access_reqs.map((accessReq) => {
            return {
                value: accessReq.id,
                label: accessReq.name
            }
        });


        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {!this.checkIfItisForConFromDD254() &&
                <h3>Edit Contract</h3>
                }
                {this.checkIfItisForConFromDD254() &&
                <h3>Create Contract</h3>
                }
                <Formik
                    initialValues={{...contract
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string().required('Contract name is required'),
                            contractNo: Yup.string().required('Contract Number is required'),
                            primeContractNo: Yup.string().test(
                                'prime-contract-no-req-test',
                                'Please enter a prime contract number',
                                function (value) {
                                    if (this.parent.contractType !== 'SUB'){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            specDate: Yup.date().required('Enter specification date'),
                            //     'spec-date-req-test',
                            //     'Enter specification date',
                            //     function (value) {
                            //         return value !== null && (value !== "");
                            //     }
                            // ),
                            specRevNo: Yup.string().test(
                                'rev-no-req-test',
                                'Please enter a revision number',
                                function (value) {
                                    if (this.parent.specType !== 'rev'){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            followsOnContractNo: Yup.string().test(
                                'prec-cont-no-req-test',
                                'Please enter the preceding contract number',
                                function (value) {
                                    if (this.parent.followsOn !== true){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            contractor: Yup.string().test(
                                'contractor-cage-code_map-test',
                                'Please enter a valid facility cage code',
                                function (value) {
                                    if (!value && !cageCodes){
                                        return true;
                                    }
                                    if (this.parent.contractType === 'SUB') {
                                        // Check to see if the prime contract number exists in the existing contracts and if so then get cage code from here
                                        if (!contractNumberStr.includes(this.parent.primeContractNo)){
                                            return true;
                                        }
                                    }
                                    if (!value){
                                        return false;
                                    }
                                    if (cageCodes === null || cageCodes.length === 0){
                                        return true;
                                    }
                                    for (let eachCageCode of cageCodes){
                                        if (value.includes(eachCageCode)){
                                            return true;
                                        }
                                    }
                                    return false;
                                }
                            ),
                            subContractors: Yup.string().test(
                                'sub-contractor-cage-code_map-test',
                                'Please enter a valid facility cage code',
                                function (value) {
                                    if (!value && !cageCodes || (this.parent.contractType !== 'SUB')){
                                        return true;
                                    }
                                    if (contractNumberStr.includes(this.parent.primeContractNo)){
                                        return true;
                                    }
                                    if (!value){
                                        return false;
                                    }
                                    if (cageCodes === null || cageCodes.length === 0){
                                        return true;
                                    }
                                    for (let eachCageCode of cageCodes){
                                        if (value.includes(eachCageCode)){
                                            return true;
                                        }
                                    }
                                    return false;
                                }
                            ),
                            finalReqPeriod: Yup.string().test(
                                'final-DD-254-req-test',
                                'Please enter the final DD-254 authorization Period',
                                function (value) {
                                    if (this.parent.finalDD254 !== true){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            finalReqDate: Yup.date().test(
                                'final-req_date-test',
                                'Please enter the contractor request date',
                                function (value) {
                                    if (this.parent.finalDD254 !== true){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            releaseType: Yup.string().test(
                                'rel-type-test',
                                'Please select a release type',
                                function (value) {
                                    if (this.parent.throughReleaseDesc){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            throughReleaseDesc: Yup.string().test(
                                'through-rel-test',
                                'Please enter comments',
                                function (value) {
                                    if (this.parent.releaseType === 'public'){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            addSecurityDesc: Yup.string().test(
                                'addl-sec-req-test',
                                'Please enter the description for additional security',
                                function (value) {
                                    if (this.parent.addSecurity !== true){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            inspectionsDesc: Yup.string().test(
                                'inspections-req-test',
                                'Please enter the description for inspections',
                                function (value) {
                                    if (this.parent.inspections !== true){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),
                            gcaName: Yup.string().required('GCA Name is required'),
                            certOfficialName: Yup.string().required('Certifying Official Name is required'),
                            otherDistrReqd: Yup.string().test(
                                'other-access-req-test',
                                'Please explain',
                                function (value) {
                                    if (!this.parent.distribution || !this.parent.distribution.includes("others")){
                                        return true;
                                    }
                                    return value && (value !== "");
                                }
                            ),

                        })

                    }
                >
                    {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <CommonUtils.ScrollToFieldError/>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>General Information</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"name"}>
                                                <Form.Label>Contract Name</Form.Label>
                                                <Form.Control required type="text" name={'name'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} placeholder={'Enter contract name'}
                                                    isInvalid={touched.name && !!errors.name}
                                                    value={values.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="8">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control required type="text" name={'description'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.description && !!errors.description}
                                                    value={values.description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"agency"}>
                                                <Form.Label>Agency</Form.Label>
                                                <Form.Control required as="select" name={'agency'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.agency && !!errors.agency}
                                                    value={values.agency}
                                                >
                                                    {CommonUtils.getOptionsForSelect(this.state.agencies)}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.agency}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={"status"}>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control required as="select" name={'status'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.status && !!errors.status}
                                                    value={values.status}
                                                >
                                                    {CommonUtils.getOptionsForSelect([
                                                        { id: "Active", name: "Active" },
                                                        { id: "Closed", name: "Closed" },
                                                        { id: "Pending", name: "Pending" }])}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.status}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 1</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"clearanceLevel"}>
                                                <Form.Label>Clearance Level</Form.Label>
                                                <Form.Control required as="select" name={'clearanceLevel'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.clearanceLevel && !!errors.clearanceLevel}
                                                              value={values.clearanceLevel}
                                                >
                                                    {CommonUtils.getOptionsForSelect(this.state.reqLevels)}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.clearanceLevel}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={"safeguardLevel"}>
                                                <Form.Label>Safeguarding Level</Form.Label>
                                                <Form.Control required as="select" name={'safeguardLevel'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.safeguardLevel && !!errors.safeguardLevel}
                                                              value={values.safeguardLevel}
                                                >
                                                    {CommonUtils.getOptionsForSelect(this.state.levels)}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.safeguardLevel}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>

                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 2</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Contract Number</Form.Label>
                                                <Form.Control required type="text" name={'contractNo'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter contract number'}
                                                              isInvalid={touched.contractNo && !!errors.contractNo}
                                                              value={values.contractNo}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contractNo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {values.contractType !== 'SUB' &&
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Sub Contract Number</Form.Label>
                                                <Form.Control required type="text" name={'subContractNo'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter contract number'}
                                                              isInvalid={touched.subContractNo && !!errors.subContractNo}
                                                              value={values.subContractNo}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.subContractNo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            }
                                            {values.contractType === 'SUB' &&
                                            <Form.Group as={Col} md="4" controlId={"primeContractNo"}>
                                                <Form.Label>Prime Contract Number</Form.Label>
                                                <Form.Control required type="text" name={'primeContractNo'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter prime contract number'}
                                                              isInvalid={touched.primeContractNo && !!errors.primeContractNo}
                                                              value={values.primeContractNo}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.primeContractNo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            }
                                             <Form.Group as={Col} md="4" controlId={"contractType"}>
                                                <Form.Label>Contract Type</Form.Label>
                                                <Form.Control required as="select" name={'contractType'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.contractType && !!errors.contractType}
                                                              value={values.contractType}
                                                >
                                                    {CommonUtils.getOptionsForSelect([
                                                        {id: "PRIME", name: "Prime Contract"},
                                                        {id: "SUB", name: "Sub-Contract"},
                                                        {id: "OTHER", name: "Solicitation or Other"}])}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contractType}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={'endDate'}>
                                                <Form.Label>End Date</Form.Label>
                                                <Field
                                                    id="endDate"
                                                    name="endDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Due Date (MM/DD/YYYY)"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.endDate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                     </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 3</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"specType"}>
                                                <Form.Label>Specification Type</Form.Label>
                                                <Form.Control required as="select" name={'specType'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.specType && !!errors.specType}
                                                              value={values.specType}
                                                >
                                                    {CommonUtils.getOptionsForSelect([
                                                        {id: "orig", name: "Original"},
                                                        {id: "rev", name: "Revised"},
                                                        {id: "final", name: "Final"}])}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.specType}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={'specDate'}>
                                                <Form.Label>Specification Date</Form.Label>
                                                <Field
                                                    id="specDate"
                                                    name="specDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Due Date (MM/DD/YYYY)"}
                                                />
                                                {/*<Form.Control.Feedback type="invalid">*/}
                                                {/*    {errors.specDate}*/}
                                                {/*</Form.Control.Feedback>*/}
                                            </Form.Group>
                                        </Form.Row>
                                        {(values.specType === 'rev') &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"specRevNo"}>
                                                <Form.Label>Revision Number</Form.Label>
                                                <Form.Control required type="text" name={'specRevNo'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter revision number'}
                                                              isInvalid={touched.specRevNo && !!errors.specRevNo}
                                                              value={values.specRevNo}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.specRevNo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 4</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'followsOn'}>
                                                <Form.Check
                                                    name={'followsOn'}
                                                    checked={values.followsOn}
                                                    label={'This is a follow on contract'}
                                                    onChange={(event) => {
                                                        setFieldValue('followsOn', event.target.checked);
                                                        console.log('Value are ', values);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.followsOn && !!errors.followsOn}
                                                    id={'followsOn'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.followsOn}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {(values.followsOn === true) &&
                                            <Form.Group as={Col} md="4" controlId={"followsOnContractNo"}>
                                                <Form.Label>Preceding Contract Number</Form.Label>
                                                <Form.Control required type="text" name={'followsOnContractNo'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.followsOnContractNo && !!errors.followsOnContractNo}
                                                              value={values.followsOnContractNo}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.followsOnContractNo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            }
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 5</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'finalDD254'}>
                                                <Form.Check
                                                    name={'finalDD254'}
                                                    checked={values.finalDD254}
                                                    label={'This is the final DD-254'}
                                                    onChange={(event) => {
                                                        setFieldValue('finalDD254', event.target.checked);
                                                        console.log('Value are ', values);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.finalDD254 && !!errors.finalDD254}
                                                    id={'finalDD254'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.finalDD254}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        {(values.finalDD254 === true) &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" controlId={"finalReqPeriod"}>
                                                <Form.Label>Authorization Period</Form.Label>
                                                <Form.Control required type="text" name={'finalReqPeriod'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.finalReqPeriod && !!errors.finalReqPeriod}
                                                              value={values.finalReqPeriod}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.finalReqPeriod}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId={'finalReqDate'}>
                                                <Form.Label>Contractor Request Date</Form.Label>
                                                <Field
                                                    id="finalReqDate"
                                                    name="finalReqDate"
                                                    component={FormikDatePickerComponent}
                                                    placeholder={"Due Date (MM/DD/YYYY)"}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.finalReqDate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        }
                                     </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 6</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Contractor(s) Address, Cage Code, and Security Office</Form.Label>
                                                <Form.Control required as="textarea" name={'contractor'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter one or more contract name, address..'}
                                                              isInvalid={touched.contractor && !!errors.contractor}
                                                              value={values.contractor}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contractor}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 7</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Sub-Contractor(s) Address, Cage Code, and Security Office</Form.Label>
                                                <Form.Control required as="textarea" name={'subContractors'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter one or more sub-contractor name, address..'}
                                                              isInvalid={touched.subContractors && !!errors.subContractors}
                                                              value={values.subContractors}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.subContractors}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 8</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Actual Performance Location, Cage Code, and Security Office</Form.Label>
                                                <Form.Control required as="textarea" name={'actualPerformance'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter actual performance  location, cage code..'}
                                                              isInvalid={touched.actualPerformance && !!errors.actualPerformance}
                                                              value={values.actualPerformance}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.actualPerformance}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 9</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>General Identification of this Procurement</Form.Label>
                                                <Form.Control required as="textarea" name={'generalIdent'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter one or more contract name, address..'}
                                                              isInvalid={touched.generalIdent && !!errors.generalIdent}
                                                              value={values.generalIdent}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.generalIdent}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                <Card.Header>Section 10</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={'accessReqd'}>
                                            <Form.Label>Contract Requires Access To:</Form.Label>
                                            <Field
                                                id="accessReqd"
                                                name="accessReqd"
                                                component={FormikFieldDualListBox}
                                                options={avail_access_reqs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.accessReqd}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {!!(values.accessReqd && values.accessReqd.includes("other")) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Other Access Required (specify)</Form.Label>
                                            <Form.Control required as="textarea" name={'otherAccessReqd'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Please specify..'}
                                                          isInvalid={touched.otherAccessReqd && !!errors.otherAccessReqd}
                                                          value={values.otherAccessReqd}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.otherAccessReqd}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 11</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'contractorAccessReqd'}>
                                                <Form.Label>Performing this contract, the contractor will:</Form.Label>
                                                <Field
                                                    id="contractorAccessReqd"
                                                    name="contractorAccessReqd"
                                                    component={FormikFieldDualListBox}
                                                    options={avail_contractor_access_reqs}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.contractorAccessReqd}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        {!!(values.contractorAccessReqd && values.contractorAccessReqd.includes("other")) &&
                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label>Other Access Required (specify)</Form.Label>
                                                    <Form.Control required as="textarea" name={'contractorOtherAccessReqd'}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} placeholder={'Please specify..'}
                                                        isInvalid={touched.contractorOtherAccessReqd && !!errors.contractorOtherAccessReqd}
                                                        value={values.contractorOtherAccessReqd}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contractorOtherAccessReqd}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 12</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'releaseType'}>
                                                <Form.Label>Any information (classified or unclassified) pertaining to this contract shall not be
                                                    released
                                                    for public dissemination except as provided by the Industrial Security Manual or unless it
                                                    has
                                                    been approved for public release by appropriate U.S. Government authority. Proposed public
                                                    releases shall be submitted for approval prior to release to the Directorate for Freedom of
                                                    Information and Security Review, Office of the Assistant Secretary of Defense (Public
                                                    Affairs)*
                                                    for review. *In the case of non-DoD User Agencies, requests for disclosure shall be
                                                    submitted to
                                                    that agency.</Form.Label>
                                                <Form.Check
                                                    name={'releaseType'}
                                                    type={'radio'}
                                                    checked={values.releaseType === 'public'}
                                                    label={'Direct Public Release'}
                                                    value={'public'}
                                                    onChange={handleChange}
                                                    isInvalid={touched.releaseType && !!errors.releaseType}
                                                    id={'releaseType'}
                                                />
                                                <Form.Check
                                                    name={'releaseType'}
                                                    type={'radio'}
                                                    checked={values.releaseType === 'through'}
                                                    label={'Through Release'}
                                                    value={'through'}
                                                    onChange={handleChange}
                                                    isInvalid={touched.releaseType && !!errors.releaseType}
                                                    id={'releaseType'}
                                                />
                                                {touched.releaseType && !!errors.releaseType &&
                                                <div className={"invalid-feedback"} style={{display: "block"}}>{errors.releaseType}</div>}
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Comments</Form.Label>
                                                <Form.Control required as="textarea" name={'throughReleaseDesc'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Please specify..'}
                                                              isInvalid={touched.throughReleaseDesc && !!errors.throughReleaseDesc}
                                                              value={values.throughReleaseDesc}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.throughReleaseDesc}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 13</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'securityGuidanceLabel'}>
                                                <Form.Label>The security classification guidance needed for this classified effort is identified below.
                                                    If
                                                    any difficulty is encountered in applying this guidance or if any other contributing factor
                                                    indicates a need for changes in this guidance, the contractor is authorized and encouraged
                                                    to
                                                    provide recommended changes; to challenge the guidance or the classification assigned to any
                                                    information or material furnished or generated under this contract; and to submit any
                                                    questions
                                                    for interpretation of this guidance to the official identified below. Pending final
                                                    decision,
                                                    the information involved shall be handled and protected at the highest level of
                                                    classification
                                                    assigned or recommended. (Fill in as appropriate for the classified effort. Attach, or
                                                    forward
                                                    under separate correspondence, any documents/guides/extracts referenced herein. Add
                                                    additional
                                                    pages in the documents section as needed to provide complete guidance.)</Form.Label>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Security Guidance</Form.Label>
                                                <Form.Control required as="textarea" name={'securityGuidance'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.securityGuidance && !!errors.securityGuidance}
                                                              value={values.securityGuidance}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.securityGuidance}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 14</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'section14Label'}>
                                                <Form.Label>Requirements, in addition to NISPOM requirements, are established for this contract. (If Yes,
                                                    identify the pertinent contractual clauses in the contract document itself, or provide an
                                                    appropriate statement which identifies additional requirements. Provide a copy of the
                                                    requirements to the cognizant security office.)</Form.Label>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'addSecurity'}>
                                                <Form.Check
                                                    name={'addSecurity'}
                                                    checked={values.addSecurity}
                                                    label={'Additional Security'}
                                                    onChange={(event) => {
                                                        setFieldValue('addSecurity', event.target.checked);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.addSecurity && !!errors.addSecurity}
                                                    id={'addSecurity'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.addSecurity}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        {(values.addSecurity === true) &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={"addSecurityDesc"}>
                                                <Form.Label>Additional Security Description</Form.Label>
                                                <Form.Control required as="textarea" name={'addSecurityDesc'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.addSecurityDesc && !!errors.addSecurityDesc}
                                                              value={values.addSecurityDesc}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.addSecurityDesc}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 15</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'section15Label'}>
                                                <Form.Label>Elements of this contract are outside the inspection responsibility of the cognizant security
                                                    office. (If yes, explain and identify specific areas or elements carved out and the activity
                                                    responsible for inspections. Use Item 13 if more space is needed.)</Form.Label>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'inspections'}>
                                                <Form.Check
                                                    name={'inspections'}
                                                    checked={values.inspections}
                                                    label={'Inspections'}
                                                    onChange={(event) => {
                                                        setFieldValue('inspections', event.target.checked);
                                                        handleChange(event);
                                                    }}
                                                    isInvalid={touched.inspections && !!errors.inspections}
                                                    id={'inspections'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.inspections}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        {(values.inspections === true) &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={"inspectionsDesc"}>
                                                <Form.Label>Inspections Description</Form.Label>
                                                <Form.Control required as="textarea" name={'inspectionsDesc'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.inspectionsDesc && !!errors.inspectionsDesc}
                                                              value={values.inspectionsDesc}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.inspectionsDesc}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 16</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="3" controlId={'gcaName'}>
                                                <Form.Label>GCA Name</Form.Label>
                                                <Form.Control required type="text" name={'gcaName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter GCA name'}
                                                              isInvalid={touched.gcaName && !!errors.gcaName}
                                                              value={values.gcaName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>AAC of the contracting office</Form.Label>
                                                <Form.Control required type="text" name={'gcaAAC'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter Activity address code'}
                                                              isInvalid={touched.gcaAAC && !!errors.gcaAAC}
                                                              value={values.gcaAAC}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaAAC}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>GCA Address</Form.Label>
                                                <Form.Control required type="text" name={'gcaAddress'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter GCA address'}
                                                              isInvalid={touched.gcaAddress && !!errors.gcaAddress}
                                                              value={values.gcaAddress}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaAddress}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        
                                        <Form.Row>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>POC Name</Form.Label>
                                                <Form.Control required type="text" name={'gcaPocName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter POC name'}
                                                              isInvalid={touched.gcaPocName && !!errors.gcaPocName}
                                                              value={values.gcaPocName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaPocName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md={"4"} controlId="gcaPocPhone">
                                                <Form.Label>POC Phone Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    as={MaskedInput}
                                                    name="gcaPocPhone"
                                                    mask="999-999-9999"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.gcaPocPhone && !!errors.gcaPocPhone}
                                                    value={values.gcaPocPhone}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaPocPhone}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md={"4"} controlId="gcaEmailAddress">
                                                <Form.Label>POC Email Address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="gcaEmailAddress"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="POC Email Address"
                                                    isInvalid={touched.gcaEmailAddress && !!errors.gcaEmailAddress}
                                                    value={values.gcaEmailAddress}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gcaEmailAddress}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                       </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 17</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Certifying Official Name</Form.Label>
                                                <Form.Control required type="text" name={'certOfficialName'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter Certifying Official Name'}
                                                              isInvalid={touched.certOfficialName && !!errors.certOfficialName}
                                                              value={values.certOfficialName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certOfficialName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Certifying Official Title</Form.Label>
                                                <Form.Control required type="text" name={'certOfficialTitle'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter Certifying Official Title'}
                                                              isInvalid={touched.certOfficialTitle && !!errors.certOfficialTitle}
                                                              value={values.certOfficialTitle}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certOfficialTitle}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>AAC of the contracting office</Form.Label>
                                                <Form.Control required type="text" name={'certAAC'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Enter Activity Address Code'}
                                                              isInvalid={touched.certAAC && !!errors.certAAC}
                                                              value={values.certAAC}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certAAC}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Cage Code Of Prime Contractor</Form.Label>
                                                <Form.Control required type="text" name={'certCageCode'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Enter Cage Code Of Prime Contractor'}
                                                              isInvalid={touched.certCageCode && !!errors.certCageCode}
                                                              value={values.certCageCode}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certCageCode}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="8">
                                                <Form.Label>Certifying Office Address</Form.Label>
                                                <Form.Control required type="text" name={'certOfficialAddress'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={'Enter Certifying Official Address'}
                                                              isInvalid={touched.certOfficialAddress && !!errors.certOfficialAddress}
                                                              value={values.certOfficialAddress}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certOfficialAddress}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md={"4"} controlId="certOfficialPhone">
                                                <Form.Label>Certifying Official Phone Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    as={MaskedInput}
                                                    name="certOfficialPhone"
                                                    mask="999-999-9999"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Certifying Official Phone Number"
                                                    isInvalid={touched.certOfficialPhone && !!errors.certOfficialPhone}
                                                    value={values.certOfficialPhone}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certOfficialPhone}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md={"4"} controlId="certEmailAddress">
                                                <Form.Label>Certifying Official Email Address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="certEmailAddress"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Certifying Official Email Address"
                                                    isInvalid={touched.certEmailAddress && !!errors.certEmailAddress}
                                                    value={values.certEmailAddress}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.certEmailAddress}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                     </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <CollapsibleCard defaultOpen={true}>
                                <Card>
                                    <Card.Header>Section 18</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'distribution'}>
                                                <Form.Label>Required Distribution By The Certifying Official</Form.Label>
                                                <Field
                                                    id="distribution"
                                                    name="distribution"
                                                    component={FormikFieldDualListBox}
                                                    options={avail_distributions}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.distribution}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        {!!(values.distribution && values.distribution.includes("others")) &&
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Other As Necessary (specify)</Form.Label>
                                                <Form.Control required as="textarea" name={'otherDistrReqd'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur} placeholder={'Please specify..'}
                                                              isInvalid={touched.otherDistrReqd && !!errors.otherDistrReqd}
                                                              value={values.otherDistrReqd}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.otherDistrReqd}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </CollapsibleCard>
                            <div className={"text-right"}>
                                <ButtonToolbar>
                                    <Button variant="primary" type="submit">Submit</Button>
                                    <Button variant="danger" type="button" onClick={this.cancelUpdate}>Cancel</Button>
                                </ButtonToolbar>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

}


function mapStateToProps({org_contract,org_contracts}) {
    const retProps = {
        contract: org_contract,
        org_contracts: org_contracts,
    };
    return retProps;
}

export default connect(mapStateToProps, {updateOrgContract, createOrgContract, fetchOrgContract, fetchOrgContracts})(ContractUpdate);