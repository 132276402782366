import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import {changeStorage} from "../../../../actions/doc_control_actions";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";

class ChangeStorageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
    }

    open = () => {
        this.setState({
            showModal: true,
        });
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    onSubmit = async (values) => {
        values.id = this.props.docControl.id;
        try{
            await changeStorage(values);
            this.props.showSuccessGrowl("Storage changed", "The new storage has been applied successfully!");
            this.props.onFinish();
            this.close();
        } catch (error) {
            this.props.showErrorGrowl(`Storage Changed", "Unexpected error changing storage. Error is ${error.response.data}`);
        }
    }

    render() {
        const {showModal} = this.state;
        return (
            <Fragment>
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" style={{padding: 10}}>
                            <i className="fa fa-window-restore" title={"Change Storage"}/>
                        </span>
                </Button>

                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Storage</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "storageLocation": "",
                            "storageSafeNumber": "",
                            "storageDrawerNumber": "",
                            "storageBeginDate": ""
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                storageLocation: Yup.string().required('Enter storage location'),
                                storageBeginDate: Yup.date().required('Enter storage begin date')
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'storageLocation'}>
                                            <Form.Label>Storage Location</Form.Label>
                                            <Form.Control type="text" name={'storageLocation'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage location'}
                                                          isInvalid={touched.storageLocation && !!errors.storageLocation}
                                                          value={values.storageLocation}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageLocation}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={'storageSafeNumber'}>
                                            <Form.Label>Storage Safe Number</Form.Label>
                                            <Form.Control type="text" name={'storageSafeNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage safe number'}
                                                          isInvalid={touched.storageSafeNumber && !!errors.storageSafeNumber}
                                                          value={values.storageSafeNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageSafeNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'storageDrawerNumber'}>
                                            <Form.Label>Storage Drawer Number</Form.Label>
                                            <Form.Control type="text" name={'storageDrawerNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the storage drawer number'}
                                                          isInvalid={touched.storageDrawerNumber && !!errors.storageDrawerNumber}
                                                          value={values.storageDrawerNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.storageDrawerNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={'storageBeginDate'}>
                                            <Form.Label>Storage Begin Date</Form.Label>
                                            <Field
                                                id="storageBeginDate"
                                                name="storageBeginDate"
                                                maxDate={new Date()}
                                                component={FormikDatePickerComponent}
                                                placeholder={"Storage Begin Date"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                                        <Button variant="danger" onClick={this.close}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const ChangeStorageModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl
})(ChangeStorageComponent);

ChangeStorageModal.propTypes = {
    docControl: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired
}