import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { fetchNotifications } from "../../../../actions/notification_actions";
import { DateUtils } from "../../../../actions/common_utils";
import ProgressBar from 'react-bootstrap/ProgressBar';  
import { fetchActivePersonsByOrg } from "../../../../actions/subject_actions";
import { connect } from "react-redux";
import { processEntitiesForCompliance } from "../../../../actions/org_actions";
import { CUR_ORG } from "../../../../actions/session_constants";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ComplianceType } from "../../../../actions/action_constants";
import { CustomPagination } from "../../../../components/custom_pagination";

const NATO_TEXT = 'NATO';
const CNWDI_TEXT = 'CNWDI';

class SubjectComp extends React.Component {

    constructor(props) {
        super(props); 
        this.state = { 
            entityType: "SUBJECT",
            currentPage:1, 
            itemsPerPage:5
        }; 
    }

    componentDidMount() {
        this.props.fetchActivePersonsByOrg(sessionStorage.getItem(CUR_ORG));
    }

    getEntities() {
        const { subjects } = this.props; 
        return processEntitiesForCompliance(subjects);
    }

    getEntityInfo() {
        return {
            entitySingular: "Subject",
            entityPlural: "Subjects",
            linkToEntity: "/org/dash/dashSubjects"
        };
    }

    isSpecialAccessInContracts = (entity, accessStr) => {
        if (entity.contracts) {
            for (let contractRel of entity.contracts) {
                if (contractRel && contractRel.orgId === sessionStorage.getItem(CUR_ORG)) {
                    if (contractRel.reqDocTypes) {
                        for (let eachType of contractRel.reqDocTypes) {
                            if (eachType.id.includes(accessStr)) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            return false;
        }
    }
    arrangeCompliantEntities = () => {
        const {all} = this.getEntities();
        const entities = all;
        let compliantEntityCount = 0;
        let totalEntities = 0;
        let issue2EntityCountMap = new Map();
        let totalNATOSubs = 0;
        let totalCNWDISubs = 0;

        if (entities) {
            totalEntities = entities.length;
            let reqDocTypes = [];
            if (entities.length > 0) {
                reqDocTypes = entities[0].docTypesRequired;
            }
            for (let entity of entities) {
                if ((entity.specialAccesses && entity.specialAccesses.includes(NATO_TEXT)) ||
                    this.isSpecialAccessInContracts(entity, NATO_TEXT)) {
                    totalNATOSubs++;
                }
                if ((entity.specialAccesses && entity.specialAccesses.includes(CNWDI_TEXT)) ||
                    this.isSpecialAccessInContracts(entity, CNWDI_TEXT)) {
                    totalCNWDISubs++;
                }
                if ((entity.docTypesPending && entity.docTypesPending.length > 0)) {
                    for (let docTypeStr of entity.docTypesPending) {
                        if (issue2EntityCountMap.has(docTypeStr)) {
                            issue2EntityCountMap.set(
                                docTypeStr,
                                issue2EntityCountMap.get(docTypeStr) + 1);
                        } else {
                            issue2EntityCountMap.set(docTypeStr, 1);
                        }
                    }
                } else {
                    compliantEntityCount++;
                }
            }
            if (reqDocTypes) {
                // Now see if any of the req doc type is not in the map. This means this is completely satisfied. Show it as complete
                for (let reqDocType of reqDocTypes) {
                    if (!issue2EntityCountMap.has(reqDocType)) {
                        issue2EntityCountMap.set(reqDocType, 0);
                    }
                }
            }
        }
        return {compliantEntityCount, totalEntities, issue2EntityCountMap, totalNATOSubs, totalCNWDISubs};
    };

    onPaginationChange = (curPage, itemsperpage) => {
        this.setState({ currentPage: curPage, itemsPerPage: itemsperpage })
    }

    goToPage = (jsxArray,page) => {
        let {itemsPerPage } = this.state; 
        if (jsxArray) {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = Math.min(startIndex + itemsPerPage, jsxArray.length); 
            const currentData = jsxArray.slice(startIndex, endIndex);
            return currentData;
        }
        return null;
    };
    render() {
        const {compliantEntityCount, totalEntities, issue2EntityCountMap, totalNATOSubs, totalCNWDISubs} = this.arrangeCompliantEntities();
        const { entitySingular } = this.getEntityInfo();
        let { currentPage, itemsPerPage } = this.state; 
        let { jsxArray, compliantItems  } = this.renderIssuesProgress(issue2EntityCountMap, totalEntities, totalNATOSubs, totalCNWDISubs);
        let totalCompliantItems = totalCNWDISubs + totalNATOSubs + totalEntities;
        let totalPages = jsxArray.length;
        let totalItems = jsxArray.length;
        if (jsxArray && jsxArray.length > 0) { 
              totalPages = Math.ceil(jsxArray.length / itemsPerPage); 
        } 
        let pageData = this.goToPage(jsxArray,currentPage);
        return (
            <Fragment>
                <div className="bg-l-blue dash_cards">
                    <div className="d-flex space-between">
                        <h5 className="page_title_left">Subject Compliance</h5>
                        {/* <span className="mx-5 badge badge-light-success fs-6 fw-bold">Total Subjects : {totalEntities}</span> */}
                    </div>
                    <div className="quadrant_scroll">
                        <div className="subject_overview">
                            <div className="sub_title">
                                {pageData && pageData.length > 0 ?
                                    <Row>
                                        <Col xs={5}>
                                            <h6 className="">Compliance Requirement</h6>
                                        </Col>
                                        <Col xs={3}>
                                            <h6 className=""># Compliant</h6>
                                        </Col>
                                        <Col xs={4}>
                                            <h6 className="">Progress</h6>
                                        </Col>
                                    </Row> : <div>No data found</div>
                                }
                            </div>
                            <div className="sub_body">
                                {pageData}
                            </div>
                        </div>
                        <CustomPagination totalPage={totalPages} totalItems={totalItems} onChange={this.onPaginationChange} />
                    </div>
                </div>
            </Fragment>
        )
    }

    renderIssuesProgress(issue2EntityCountMap, totalEntities, totalNATOSubs, totalCnwdiSubs) {
        const {linkToEntity} = this.getEntityInfo();
        let jsxArray = [];
        let compliantItems = 0;
        let totalItems = 0;
        issue2EntityCountMap.forEach((count, name) => {
            if (name.includes(NATO_TEXT)){
                compliantItems = compliantItems + (totalNATOSubs - count);
                totalItems += totalNATOSubs;
            }
            if (name.includes(CNWDI_TEXT)){
                compliantItems = compliantItems + (totalCnwdiSubs - count);
                totalItems += totalCnwdiSubs;
            }
            if (!name.includes(NATO_TEXT) && !name.includes(CNWDI_TEXT)){
                compliantItems = compliantItems + (totalEntities - count);
                totalItems += totalEntities;
            }
            jsxArray.push(
                <Row key={name}>
                    <Col xs={5}>
                        <Link className=""
                              to={`${linkToEntity}/byDocType/${name}/${ComplianceType.NON_COMPLIANT}`}>{name}</Link>
                    </Col>
                    {name.includes(NATO_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="">
                            {totalNATOSubs - count + "/" + totalNATOSubs}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar className="dashboard_subject h-6px" now={(totalNATOSubs - count) * 100 / totalNATOSubs}/>
                        </Col>
                    </Fragment>
                    }
                    {name.includes(CNWDI_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="">
                            {totalCnwdiSubs - count + "/" + totalCnwdiSubs}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar className="dashboard_subject h-6px" now={(totalCnwdiSubs - count) * 100 / totalCnwdiSubs}/>
                        </Col>
                    </Fragment>
                    }
                    {!name.includes(NATO_TEXT) && !name.includes(CNWDI_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="">
                            {totalEntities - count + "/" + totalEntities}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar className="dashboard_subject h-6px" now={(totalEntities - count) * 100 / totalEntities}/>
                        </Col>
                    </Fragment>
                    }
                </Row>
            )
        })
        return {jsxArray, compliantItems, totalItems};
    }

    RenderCB(props) {
        return (

            <div className="prog_div">{props.children}</div>
        );
    }
}

function mapStateToProps({subjects}) {
    return { 
        subjects: subjects
    }
}

export const Subject = connect(mapStateToProps, {
    fetchActivePersonsByOrg 
})(SubjectComp);

 
 