/**
 * Created by pshivaraman on 3/30/18.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCurOrg } from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import { OverlayPanel } from "primereact/components/overlaypanel/OverlayPanel";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { getFacilityLevels } from "../../../../actions/fieldValue_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ConditionalDisplay } from "../../../util/shouldDisplay";
import { ButtonToolbar } from "react-bootstrap";
import { Facility_Filter } from "./facility_filter";

class FacilityList extends Component {
    constructor(props) {
        super(props);

        this.getOverlayPanels = this.getOverlayPanels.bind(this);

        this.op = {};

        this.state = {
            availLevels: {},
            opanels: [],
            filter: {
                cageCode: "",
                name: "",
                address: "",
                poc: "",
                facLevel: "",
                safeLevel: ""
            }
        }
    }

    componentDidMount() {
        getFacilityLevels((response) => {
            this.setState({
                availLevels: response.data
            });
        });
    }

    componentDidUpdate(prevProps) {
        // Compare to old facilities
        if (this.props.facilities !== prevProps.facilities) {
            this.getOverlayPanels();
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Locations
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getOverlayPanels() {
        if (this.props.facilities) {
            this.setState({
                opanels: this.props.facilities.map((facility) => {
                    return (
                        <OverlayPanel key={facility.id} ref={(el) => el && (this.op[facility.id] = el)}
                            appendTo={document.querySelector("#container")}>
                            <h4>Point of Contact</h4>
                            <p>Name: {facility.pocName}</p>
                            <p>Phone Number: {facility.pocPhone}</p>
                            <p>Email Address: {facility.pocEmail}</p>
                        </OverlayPanel>
                    )
                })
            });
        }
    }

    getCurrentTime(facility) {
        if (facility.timezoneUI) {
            const timeMs = new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000) + facility.timezoneUI.currentOffset;
            const options = { weekday: 'long', hour: '2-digit', minute: '2-digit' };
            return `${new Date(timeMs).toLocaleString('en-us', options)} ${facility.timezoneUI.abbr}`;
        }
        return "Unknown";
    }

    getAddress(facility) {
        let address = `${facility.street}, ${facility.city},, ${facility.state} ${facility.zipcode}`
        // Replace only does first occurance
        let retString = address.split('null,').join('').split('null').join('').trim()
        if (retString.endsWith(",")) {
            retString = retString.slice(0, retString.length - 1)
        }
        return retString
    }

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    facilityFilterby = () => {
        let { availLevels, filter } = this.state;
        const { facilities } = this.props;
        
        let filteredData = facilities.filter(item =>
            (filter.cageCode === "" || String(item.cageCode).toLowerCase().includes(filter.cageCode.toLowerCase())) &&
            (filter.name === "" || String(item.name).toLowerCase().includes(filter.name.toLowerCase())) &&
            (filter.address === "" || String(this.getAddress(item)).toLowerCase().includes(filter.address.toLowerCase())) &&
            (filter.poc === "" || String(item.pocName).toLowerCase().includes(filter.poc.toLowerCase())) &&
            (filter.facLevel === "" || (item.facLevel && String(item.facLevel.id) === filter.facLevel)) &&
            (filter.safeLevel === "" || (item.safeLevel && String(item.safeLevel.id) === filter.safeLevel))
        )
        return filteredData;
    }
    render() {
        const { facilities } = this.props;
        const { availLevels, opanels, filter } = this.state;

        // if (!facilities){
        //     return <div>Loading...</div>;
        // }

        const columns = [
            {
                dataField: "cageCode",
                text: "Cage Code",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "name",
                text: "Name",
                formatter: (cell, row) => <Link to={`/org/dash/facility/${row.id}`}>{cell}</Link>,
                sort: true
            },
            {
                dataField: "address",
                text: "Address",
                formatter: (cell, row) => this.getAddress(row),
                sort: true
            },
            {
                dataField: "poc",
                text: "Point of Contact",
                formatter: (cell, row) => {
                    return <a onClick={(event) => this.op[row.id] && this.op[row.id].toggle(event)}>{row.pocName}</a>
                }
            },
            {
                dataField: "localTime",
                text: "Local Time",
                formatter: (cell, row) => this.getCurrentTime(row)
            },
            {
                dataField: "facLevel",
                text: "Location Level",
                formatter: (cell) => cell ? cell.displayName : "None",
                sort: true
            },
            {
                dataField: "safeLevel",
                text: "Safeguarding Level",
                formatter: (cell) => cell ? cell.displayName : "None",
                sort: true
            },
            {
                dataField: "subjectCount",
                text: "# of Subjects",
                formatter: (cell) => cell ? cell : 0,
                sort: true
            },
        ];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <Link className="btn btn-primary" to="/org/dash/facility/new">New Location</Link>
                        </ButtonToolbar>
                    </div>
                )}
                <div className="d-flex">
                    <h3 className="page_title_left">Locations</h3>
                    <Facility_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.facilityFilterby()} columns={columns}
                    filter={filterFactory()} defaultSorted={[{ dataField: 'name', order: 'asc' }]} />
                {opanels}

            </div>
        )
    }
}

function mapStateToProps({ smos }) {
    return {
        facilities: smos
    };
}

export default connect(mapStateToProps, {})(FacilityList);
