import React from "react";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import MaskedInput from "react-input-mask";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage8Comp extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage} = this.props;

        return (
            <Formik
                initialValues={{
                    gcaName: contract.gcaName,
                    gcaAAC: contract.gcaAAC,
                    gcaAddress: contract.gcaAddress,
                    gcaPocName: contract.gcaPocName,
                    gcaPocPhone: contract.gcaPocPhone,
                    gcaEmailAddress: contract.gcaEmailAddress,
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        gcaName: Yup.string().required('GCA Name is required')
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 16</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>GCA Name</Form.Label>
                                        <Form.Control required type="text" name={'gcaName'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter GCA name'}
                                                      isInvalid={touched.gcaName && !!errors.gcaName}
                                                      value={values.gcaName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>AAC of the contracting office</Form.Label>
                                        <Form.Control required type="text" name={'gcaAAC'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter Activity address code'}
                                                      isInvalid={touched.gcaAAC && !!errors.gcaAAC}
                                                      value={values.gcaAAC}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaAAC}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4">
                                        <Form.Label>GCA Address</Form.Label>
                                        <Form.Control required type="text" name={'gcaAddress'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter GCA address'}
                                                      isInvalid={touched.gcaAddress && !!errors.gcaAddress}
                                                      value={values.gcaAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                               
                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>POC Name</Form.Label>
                                        <Form.Control required type="text" name={'gcaPocName'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter POC name'}
                                                      isInvalid={touched.gcaPocName && !!errors.gcaPocName}
                                                      value={values.gcaPocName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaPocName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={"4"} controlId="gcaPocPhone">
                                        <Form.Label>POC Phone Number</Form.Label>
                                        <Form.Control
                                            required
                                            as={MaskedInput}
                                            name="gcaPocPhone"
                                            mask="999-999-9999"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.gcaPocPhone && !!errors.gcaPocPhone}
                                            value={values.gcaPocPhone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaPocPhone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={"4"} controlId="gcaEmailAddress">
                                        <Form.Label>POC Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="gcaEmailAddress"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="POC Email Address"
                                            isInvalid={touched.gcaEmailAddress && !!errors.gcaEmailAddress}
                                            value={values.gcaEmailAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gcaEmailAddress}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                               
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                        <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                        <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage8 = ContractNewPage8Comp;

ContractNewPage8.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};

