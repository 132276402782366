import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { getFacilityLevels } from "../../../../actions/fieldValue_actions";
import { CommonUtils } from "../../../../actions/common_utils";

export const Facility_Filter = ({ filters, onSubmit }) => {

    const [ShowHover, setShowHover] = useState(false);
    const [availLevels, setAvailLevels] = useState(null);
    const popupRef = useRef(null);
 
    useEffect(() => {
        getFacilityLevels((response) => {
            setAvailLevels(response.data)
        })

        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => {
        onSubmit({
            cageCode: "",
            name: "",
            address: "",
            poc: "",
            facLevel: "",
            safeLevel: ""
        })
        setShowHover(false)
    }


    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Cage Code</label>
                                        <Form.Control
                                            type="text"
                                            name={"cageCode"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Cage Code"}
                                            value={values.cageCode}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"name"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Name"}
                                            value={values.name}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Address</label>
                                        <Form.Control
                                            type="text"
                                            name={"address"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Address"}
                                            value={values.address}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Point of Contact</label>
                                        <Form.Control
                                            type="text"
                                            name={"poc"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Point of Contact"}
                                            value={values.poc}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Location Level</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"facLevel"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.facLevel && !!errors.facLevel
                                            }
                                            value={values.facLevel}
                                        >
                                            {CommonUtils.getOptionsForSelect([{ id: "", name: "Select Location Level" }, ...availLevels])}
                                        </Form.Control>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Safeguarding Level</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"safeLevel"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.safeLevel && !!errors.safeLevel
                                            }
                                            value={values.safeLevel}
                                        >
                                            {CommonUtils.getOptionsForSelect([{ id: "", name: "Select Safeguarding Level" }, ...availLevels])}
                                        </Form.Control>
                                    </div>
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}