/**
 * Created by pshivaraman on 3/29/18.
 */

import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {CUR_ORG} from "../../../actions/session_constants";
import {fetchCurOrg, fetchManagedOrgs} from "../../../actions/org_actions";
import {connect} from "react-redux";
import {fetchOrgFacilities} from "../../../actions/facility_actions";
import {fetchOrgContracts} from "../../../actions/contract_actions";
import {fetchUsersByOrg} from "../../../actions/user_actions";
import {ORG_NAME, ROLES} from "../../../actions/auth_actions";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import {
    ConditionalDisplay,
    isContractOnlyRole,
    shouldDisplayDocOrgControls,
    shouldDisplayOrgAssets,
    shouldDisplayOrgContracts,
    shouldDisplayOrgDash,
    shouldDisplayOrgDocs,
    shouldDisplayOrgFacilities, shouldDisplayOrgReport,
    shouldDisplayOrgScreening, shouldDisplayOrgSettings,
    shouldDisplayOrgSubjects,
    shouldDisplayOrgTasks, shouldDisplayOrgUsers,
    shouldDisplayOrgVisits
} from "../../util/shouldDisplay";
const Config = require('Config')

class OrgSideNav extends React.Component {
    constructor(props) {
        super(props);

        this.getBrand = this.getBrand.bind(this);
    }

    onSideBarSelect(key, event) {
        switch (key) {
            case "1":
                this.props.fetchCurOrg();
                break;
            case "2":
                this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG));
                break;
            case "4":
                this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
                break;
            case "5":
                break;
            case "6":
                this.props.fetchManagedOrgs();
                break;
            case "7":
                this.props.fetchUsersByOrg();
                break;
        }
    }

    getBrand() {
        if (this.props.org) {
            sessionStorage.setItem(ORG_NAME, this.props.org.name)
            if (this.props.org.logoUrl) {
                return (
                    <Nav.Item className="sidebar-brand">
                        <Image className="navbar-brand" src={this.props.org.logoUrl} alt={this.props.org.name} height={'50'} width={'90%'}/>
                    </Nav.Item>
                )
            }
            return (
                <Nav.Item className="sidebar-brand">
                    <h4><i className="fa fa-project-diagram" aria-hidden="true"></i> {this.props.org.name}</h4>
                </Nav.Item>
            )
        }
    }

    isOrgAdmin() {
        const roles = sessionStorage.getItem(ROLES);
        if (roles && roles !== "undefined") {
            const parsedRoles = JSON.parse(roles);

            // Loop through roles
            const roleObj = parsedRoles.find((role) => {
                // Check if role is ORG_ADMIN for current Org
                return role.role === "ORG_ADMIN" && role.entityIds.includes(sessionStorage.getItem(CUR_ORG));
            });

            if (roleObj) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <div id="sidebar-wrapper">
                <Nav bsPrefix="sidebar-nav" onSelect={this.onSideBarSelect.bind(this)}>
                     {this.getBrand()}
                    {shouldDisplayOrgDash() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash" exact>
                            <Nav.Link eventKey={1}><span className="fa fa-home"
                                                         aria-hidden="true"/> Dashboard</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgFacilities() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/facility">
                            <Nav.Link eventKey={2}><span className="fa fa-building"
                                                         aria-hidden="true"/> Locations</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgDocs() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/doc">
                            <Nav.Link eventKey={3}><span className="fa fa-file-text" aria-hidden="true"/> Documents</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgContracts() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/contract">
                            <Nav.Link eventKey={4}><span className="fa fa-book"
                                                         aria-hidden="true"/> Contracts</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgTasks() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/task">
                            <Nav.Link eventKey={8}><span className="fa fa-tasks" aria-hidden="true"/> Tasks</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {Config.clientUrl !== "https://www.tru-vetting.com" && shouldDisplayOrgScreening() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/screening">
                            <Nav.Link eventKey={8}><span className="fa fa-check-circle-o"
                                                         aria-hidden="true"/> Screenings</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgAssets() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/asset">
                            <Nav.Link eventKey={22}><span className="fa fa-laptop"
                                                          aria-hidden="true"/> Assets</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayDocOrgControls() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/docControl">
                            <Nav.Link eventKey={823}><span className="fa fa-file-shield" aria-hidden="true"/> Document
                                Control</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgVisits() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/visitRequests">
                            <Nav.Link eventKey={10}><span className="fa fa-file-circle-check" aria-hidden="true"/> Visit
                                Requests</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgVisits() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/visits">
                            <Nav.Link eventKey={11}><span className="fa fa-car-side"
                                                          aria-hidden="true"/> Visits</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgSubjects() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/subject">
                            <Nav.Link eventKey={5}><span className="fa fa-users"
                                                         aria-hidden="true"/> Subjects</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgReport() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/report">
                            <Nav.Link eventKey={9}><span className="fa fa-file-excel-o"
                                                         aria-hidden="true"/> Reports</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgUsers() && <Nav.Item>
                        <LinkContainer to="/org/dash/user">
                            <Nav.Link eventKey={7}><span className="fa fa-users" aria-hidden="true"/> Users</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>}
                    {this.isOrgAdmin() &&
                    <Nav.Item>
                        <LinkContainer to="/org/dash/serviceCenter">
                            <Nav.Link eventKey={6}><span className="fa fa-users" aria-hidden="true"/> Service Center</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    }
                    {shouldDisplayOrgSettings() && (<Nav.Item>
                        <LinkContainer to="/org/dash/settings">
                            <Nav.Link eventKey={20}><span className="fa fa-wrench" aria-hidden="true"/> Settings</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>)}
                </Nav>
            </div>
        )
    }

}


function mapStateToProps({cur_org}) {
    return {
        org: cur_org
    }
}


export default connect(mapStateToProps, {
    fetchCurOrg,
    fetchOrgFacilities,
    fetchOrgContracts,
    fetchManagedOrgs,
    fetchUsersByOrg
})(OrgSideNav);