/**
 * Created by pshivaraman on 4/10/18.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {fetchCurOrg, fetchEntitiesForCompliance} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {fetchActivePersonsByOrg} from "../../../../actions/subject_actions";
import {Link} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {ComplianceType, EntityType} from "../../../../actions/action_constants";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {addDocToEntity} from "../../../../actions/doc_actions";
import DocAddModal from "../../../../components/document/add_wizard/doc_add_modal";
import {CUR_ORG} from "../../../../actions/session_constants";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import { Dashsubject_Filter } from "./dash_subject_filter";

class SubjectDashList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            filter: {personFirstName: "", personLastName: "", socialSecurityNumber: "", stateOfBirth: "", countryOfBirth: ""}
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Subjects
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    // getSubjects() {
    //     let entities = [];
    //     const complianceType = this.props.match.params.complianceType;

    //     switch (complianceType){
    //         case ComplianceType.ALL:
    //             entities = this.props.entities;
    //             break;
    //         case ComplianceType.NON_COMPLIANT:
    //             entities = this.props.nonCompliantEntities;
    //             break;
    //         case ComplianceType.COMPLIANT:
    //             entities = this.props.compliantEntities;
    //             break;
    //     }

    //     return entities.map(subject => {
    //         if (subject.personObject) {
    //             return {...subject, ...subject.personObject};
    //         }
    //         return subject;
    //     });
    // }

    componentDidMount() {
        this.docUploadSuccessCallBack();
    }

    docUploadSuccessCallBack = () => {
        const byDocType = this.props.match.path.includes("byDocType");
        const docType = byDocType ? this.props.match.params.docType : "";
        this.props.fetchEntitiesForCompliance("SUBJECT", byDocType, docType);
    }

    getTitle(){
        const complianceType = this.props.match.params.complianceType;
        const byDocType = this.props.match.path.includes("byDocType");
        const title = byDocType ? this.props.match.params.docType : "Overall";

        switch (complianceType) {
            case ComplianceType.NON_COMPLIANT:
                return title + ': Non Compliant';
            case ComplianceType.COMPLIANT:
                return title + ': Compliant ';
            default:
                return 'All ';
        }
    }

    onCreate = (docId, callback) => {
        this.props.addDocToEntity(docId, EntityType.SUBJECT, this.state.entityId, () => {
            const byDocType = this.props.match.path.includes("byDocType");
            const docType = byDocType ? this.props.match.params.docType : "";
            this.props.fetchEntitiesForCompliance("SUBJECT", byDocType, docType);
            // this.removeSubject();
            this.setState({
                show: false,
                entityId: null
            });
            callback();
        });
    };

    onUploadClick = (entityId) => {
        this.setState({
            show: true,
            entityId: entityId
        })
    };

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    dashsubjectFilterby = () => { 
        let { filter } = this.state;   
        let entities = [];
        const complianceType = this.props.match.params.complianceType;

        switch (complianceType){
            case ComplianceType.ALL:
                entities = this.props.entities;
                break;
            case ComplianceType.NON_COMPLIANT:
                entities = this.props.nonCompliantEntities;
                break;
            case ComplianceType.COMPLIANT:
                entities = this.props.compliantEntities;
                break;
        }

        entities = entities.map(subject => {
            if (subject.personObject) {
                return {...subject, ...subject.personObject};
            }
            return subject;
        }); 
        console.log("filter", filter);
        let filteredData = entities.filter(item =>
            (filter.personFirstName === "" || item.personFirstName.toLowerCase().includes(filter.personFirstName.toLowerCase())) &&
            (filter.personLastName === "" || item.personLastName.toLowerCase().includes(filter.personLastName.toLowerCase())) &&
            (filter.socialSecurityNumber === "" || String(item.socialSecurityNumber).toLowerCase().includes(filter.socialSecurityNumber.toLowerCase())) &&
            (filter.stateOfBirth === "" || String(item.stateOfBirth).toLowerCase().includes(filter.stateOfBirth.toLowerCase()))  &&
            (filter.countryOfBirth === "" || String(item.countryOfBirth).toLowerCase().includes(filter.countryOfBirth.toLowerCase())) 
            
        )
        return filteredData; 
    }

    render() {
        const activeOptions = [
            {value: true, label: "Active"},
            {value: false, label: "Separated"}
        ];

        let {page, sizePerPage,filter} = this.state;

        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
           // filter: textFilter()
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
           // filter: textFilter()
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
           // filter: textFilter()
        }, {
            dataField: 'stateOfBirth',
            text: 'State of Birth',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
           // filter: textFilter()
        }, {
            dataField: 'countryOfBirth',
            text: 'Country of Birth',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
           // filter: textFilter()
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    ConditionalDisplay(
                    <Fragment>
                        <ButtonToolbar style={{flexWrap: "nowrap"}}>
                            <Button variant={'link'} className="close" onClick={() => this.onUploadClick(row.id)}>
                                <span aria-hidden="true" title={"Upload Document"} style={{padding: 10}} aria-label="Upload Document">
                                    <i className="fa fa-upload"/>
                                </span>
                            </Button>
                        </ButtonToolbar>
                        {/*<Button className="close" onClick={() => this.removeSubject(row.id)}>*/}
                        {/*<span aria-hidden="true" style={{padding: 10}} aria-label="Remove Subject From Facility">*/}
                        {/*&times;*/}
                        {/*</span>*/}
                        {/*</Button>*/}
                    </Fragment>
                    )
                )
            }
        }];

        const byDocType = this.props.match.path.includes("byDocType");
        const docTypes = byDocType ? [this.props.match.params.docType] : [];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                {/* <h3 className="page_title_left">{this.getTitle()} Subjects</h3> */}
                <div className="d-flex">
                    <h3 className="page_title_left">{this.getTitle()} Subjects</h3>
                    <Dashsubject_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={this.dashsubjectFilterby()}
                                columns={columns} filter={filterFactory()}/>
                {this.state.show &&
                <DocAddModal docUploadSuccessCallBack={this.docUploadSuccessCallBack} entityType={EntityType.SUBJECT} entityId={this.state.entityId} viewList={()=>this.setState({show:false})}
                             onCreate={this.onCreate} docTypes={docTypes}/>
                }
            </div>
        )
    }
}

function mapStateToProps({compliantEntityStatus}) {
    return {
        entities: compliantEntityStatus.all,
        compliantEntities: compliantEntityStatus.compliant,
        nonCompliantEntities: compliantEntityStatus.nonCompliant
    }
}

export default connect(mapStateToProps, {fetchEntitiesForCompliance, addDocToEntity})(SubjectDashList);