/**
 * Created by pshivaraman on 4/7/18.
 */

import React, {Fragment} from "react";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {getAgencies} from "../../../../actions/fieldValue_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {deleteDocument} from "../../../../actions/doc_actions";
import {addContractToFacilities} from "../../../../actions/facility_actions";
import {
    deleteContract,
    fetchOrgContract,
    fetchOrgContracts
} from "../../../../actions/contract_actions";
import DocumentManager from "../../../../components/document/doc_manager";
import {Link} from "react-router-dom";
import {DateUtils} from "../../../../actions/common_utils";
import {
    addContractToSubjects,
    fetchSubjectsByContractIdAndActiveFor,
    searchSubjectsNotActivelyPartOfContract
} from "../../../../actions/subject_actions";
import ContractSubjectList from "./subject/contract_subject_list";
import ContractFacilityList from "./facility/contract_facility_list";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import EntityIssues from "../../../../components/entity_issue";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {ContractTaskOrderList} from "./contract_task_order_list";

class ContractDetail extends React.Component {
    constructor(props) {
        super(props);

        this.goToEditPage = this.goToEditPage.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.state = {
            agencies: []
        }
    }

    componentDidMount() {
        this.setState({agencies: []});
        getAgencies((response) => {
            this.setState({agencies: response.data});
        });
        this.props.fetchOrgContract(this.props.match.params.id);
        this.props.fetchSubjectsByContractIdAndActiveFor(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.id !== this.props.match.params.id){
            this.props.fetchOrgContract(nextProps.match.params.id);
            this.props.fetchSubjectsByContractIdAndActiveFor(nextProps.match.params.id);
        }
    }

    goToEditPage() {
        this.props.history.push(`/org/dash/contract/${this.props.match.params.id}/update`);
    }

    getAccessReqd() {
        const {contract} = this.props;
        if (contract && contract.accessReqd) {
            return contract.accessReqd.map((accessItem) => {
                const accessVal = accessItem === "other" ? contract.otherAccessReqd : accessItem;
                return (
                    <ListGroupItem key={accessItem}>
                        {accessVal}
                    </ListGroupItem>
                )
            });
        }
        return [];
    }

    getContractorAccessReqd() {
        const {contract} = this.props;
        if (contract && contract.contractorAccessReqd) {
            return contract.contractorAccessReqd.map((accessItem) => {
                const accessVal = accessItem === "other" ? contract.contractorOtherAccessReqd : accessItem;
                return (
                    <ListGroupItem key={accessItem}>
                        {accessVal}
                    </ListGroupItem>
                )
            });
        }
        return [];
    }

    getDistributions() {
        const {contract} = this.props;
        if (contract && contract.distribution) {
            return contract.distribution.map((distItem) => {
                return (
                    <ListGroupItem key={distItem}>
                        {distItem}
                    </ListGroupItem>
                )
            });
        }
        return [];
    }

    getAgency() {
        const {contract} = this.props;
        if (contract && contract.agency) {
            const agencyObj = this.state.agencies.find(o => o.id === contract.agency);
            if (agencyObj) {
                return agencyObj.name;
            }
            return "None";
        }
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/contract"
    //                             onClick={() => fetchOrgContracts(sessionStorage.getItem(CUR_ORG))}>
    //                 Contracts
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Contract {this.props.contract.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getDocuments() {
        this.props.fetchContractDocs(this.props.match.params.id);
    }

    handleDelete() {
        this.props.deleteContract(this.props.match.params.id, () => {
            fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
            this.props.history.push("/org/dash/contract");
        });

    }

    render() {
        const {contract} = this.props;

        if (!contract) {
            return (
                <div>Loading...</div>
            );
        }

        const paramId = this.props.match.params.id;
        console.log('Contract detail is ', contract)

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {ConditionalDisplay(
                <div className="pull-right">
                    <ButtonToolbar>
                        <Link to={`/org/dash/contract/${paramId}/generateChild`} className="btn btn-success">
                            <span className="fa fa-magic" aria-hidden="true"/> Generate Sub-Contract
                        </Link>
                        <Link to={`/org/dash/contract/${paramId}/update`} className="btn btn-primary">
                            <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                        </Link>
                        <DeleteConfirmationModal deleteEntity="Contract" handleDeleteCallback={this.handleDelete}/>
                    </ButtonToolbar>
                </div>
                )}
                <h3 className="page_title_left">Contract Details</h3>
                {!Array.isArray(contract) && <EntityIssues issueEntity={contract}/>}
                
                <CollapsibleCard header="General" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Name
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.name}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Description
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.description}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Agency
                        </Col>
                        <Col sm={3} md={4}>
                            {this.getAgency()}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Status
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.status}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 1" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Clearance Level
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.clearanceLevel ? contract.clearanceLevel.displayName : "None"}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Safeguarding Level
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.safeguardLevel ? contract.safeguardLevel.displayName : "None"}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 2" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Contract Type
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.contractType}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Contract Number
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.contractNo}
                        </Col>
                        {(contract.contractType === 'SUB') &&
                        <Fragment>
                            <Col sm={3} md={2}>
                            </Col>
                            <Col sm={3} md={4}>
                            </Col>
                            <Col sm={3} md={2} className="form-label">
                                Prime Contract #
                            </Col>
                            <Col sm={3} md={4}>
                                {contract.primeContractNo}
                            </Col>
                        </Fragment>
                        }
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            End Date
                        </Col>
                        <Col sm={3} md={4}>
                            {DateUtils.getDateString(contract.endDate)}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Days Remaining
                        </Col>
                        <Col sm={3} md={4}>
                            <div className="form-control-static">
                                {DateUtils.getDaysLeft(contract.endDate, 30)}
                            </div>
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 3" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Specification
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.specType === "rev" ? `Revision ${contract.specRevNo}` : contract.specType}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Specification Date
                        </Col>
                        <Col sm={3} md={4}>
                            {DateUtils.getDateString(contract.specDate)}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 4" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Follows On
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.followsOn ? contract.followsOnContractNo : "None"}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 5" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Contractor Request Date
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.finalDD254 ? DateUtils.getDateString(contract.finalReqDate) : "Not a Final DD-254"}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Authorization Period
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.finalDD254 ? contract.finalReqPeriod : "Not a Final DD-254"}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 6" defaultOpen>
                    <Form.Group>
                        <Form.Label>Contractors</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.contractor}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 7" defaultOpen>
                    <Form.Group>
                        <Form.Label>Sub-Contractors</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.subContractors}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 8" defaultOpen>
                    <Form.Group>
                        <Form.Label>Actual Performance Location</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.actualPerformance}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 9" defaultOpen>
                    <Form.Group>
                        <Form.Label>General Identification of this Procurement</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.generalIdent}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 10" defaultOpen>
                    <h3 className="page_title_left">Contract Requires Access To:</h3>
                    <ListGroup>
                        {this.getAccessReqd()}
                    </ListGroup>
                </CollapsibleCard>
                <CollapsibleCard header="Section 11" defaultOpen>
                    <h3 className="page_title_left">In performing this contract, the contractor will:</h3>
                    <ListGroup>
                        {this.getContractorAccessReqd()}
                    </ListGroup>
                </CollapsibleCard>
                <CollapsibleCard header="Section 12" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Release Type
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.releaseType}
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Comments</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.throughReleaseDesc}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 13" defaultOpen>
                    <Form.Group>
                        <Form.Label>Security Guidance</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={contract.securityGuidance}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 14" defaultOpen>
                    <Form.Group>
                        <Form.Label>Additional Security</Form.Label>
                        <Form.Control plaintext readOnly
                                      defaultValue={contract.addSecurity ? contract.addSecurityDesc : "None"}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 15" defaultOpen>
                    <Form.Group>
                        <Form.Label>Inspections</Form.Label>
                        <Form.Control plaintext readOnly
                                      defaultValue={contract.inspections ? contract.inspectionsDesc : "None"}/>
                    </Form.Group>
                </CollapsibleCard>
                <CollapsibleCard header="Section 16" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            GCA Name
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaName}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            GCA AAC
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaAAC}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            GCA Address
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaAddress}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            GCA POC Name
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaPocName}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            GCA POC Phone
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaPocPhone}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            GCA Email Address
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.gcaEmailAddress}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 17" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Certifying Official Name
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certOfficialName}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Certifying Official Title
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certOfficialTitle}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Certifying Official Phone Number
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certOfficialPhone}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Certifying Official Address
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certOfficialAddress}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Cage Code of the Prime Contractor
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certCageCode}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            AAC of the contracting office
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certAAC}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Email Address
                        </Col>
                        <Col sm={3} md={4}>
                            {contract.certEmailAddress}
                        </Col>
                    </Row>
                </CollapsibleCard>
                <CollapsibleCard header="Section 18" defaultOpen>
                    <h3 className="page_title_left">Distributions</h3>
                    <ListGroup>
                        {this.getDistributions()}
                    </ListGroup>
                </CollapsibleCard>
                <CollapsibleCard header="Task Orders" defaultOpen>
                    <ContractTaskOrderList contract={contract}/>
                </CollapsibleCard>
                <CollapsibleCard header="Subjects" defaultOpen>
                    <ContractSubjectList contractId={this.props.match.params.id}/>
                </CollapsibleCard>
                <CollapsibleCard header="Associated Locations" defaultOpen>
                    <ContractFacilityList contractId={this.props.match.params.id}/>
                </CollapsibleCard>
                <CollapsibleCard header="Documents" defaultOpen>
                    <DocumentManager entityType="CONTRACT"
                                     entityId={this.props.match.params.id}/>
                </CollapsibleCard>
            </div>
        );
    }
}

function mapStateToProps({org_contract, subjects}) {
    return {
        contract: org_contract,
        subjects
    }
}

export default connect(mapStateToProps, {
    fetchOrgContract,
    deleteDocument,
    searchSubjectsNotActivelyPartOfContract,
    addContractToSubjects,
    addContractToFacilities,
    deleteContract,
    fetchSubjectsByContractIdAndActiveFor
})(ContractDetail);