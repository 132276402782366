import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {connect} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import {checkinAsset} from "../../../../actions/asset_actions";

class AssetCheckinModalComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
    }

    open = () => {
        this.setState({
            showModal: true,
        });
    }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    onSubmit = async (values) => {
        values.id = this.props.asset.id;
        try{
            await checkinAsset(values);
            this.props.showSuccessGrowl("Asset Checkin", "The asset has been checked in successfully!");
            this.props.onFinish();
            this.close();
        } catch (error) {
            this.props.showErrorGrowl(`Asset Checkin", "Unexpected error checking in Asset. Error is ${error.response.data}`);
        }
    }

    render() {
        const {showModal} = this.state;
        return (
            <Fragment>
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" style={{padding: 10}}>
                            <i className="fa fa-arrow-circle-left" title={"Checkin"}/>
                        </span>
                </Button>

                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Checkin Asset</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "checkInComments": "",
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'checkInComments'}>
                                            <Form.Label>Item Number</Form.Label>
                                            <Form.Control type="text" name={'checkInComments'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'(Optional) Comments for check-in'}
                                                          isInvalid={touched.checkInComments && !!errors.checkInComments}
                                                          value={values.checkInComments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.checkInComments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Check In</Button>
                                        <Button variant="danger" onClick={this.close}>Cancel</Button>
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const AssetCheckinModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl
})(AssetCheckinModalComponent);

AssetCheckinModal.propTypes = {
    asset: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired
}