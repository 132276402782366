import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { FieldMultiSelectDropdown } from "../../../../components/redux_form/field_components_rb";
import { Picky } from 'react-picky';
import { FormikFieldMultiSelectDropDown } from "../../../../components/formik/formik_multiselectdropdown";
import { CommonUtils } from "../../../../actions/common_utils";




export const Asset_Filter = ({ filters, onSubmit }) => {

    const [ShowHover, setShowHover] = useState(false); 
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = () => { 
        onSubmit({ 
            itemNumber: "", 
            description: "", 
            serialNumber: "", 
            brand: "", 
            checkedOutSubject: "", 
            status: "" 
        })
        setShowHover(false)
    }

    const checkOutOptions = [
        {id: "", name: "Select Status"},
        {id: "Checked Out", name: "Checked Out"},
        {id: "Available", name: "Available"}
    ] 

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label">Item Number</label>
                                        <Form.Control
                                            type="text"
                                            name={"itemNumber"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Item Number"}
                                            value={values.itemNumber}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Description</label>
                                        <Form.Control
                                            type="text"
                                            name={"description"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Description"}
                                            value={values.description}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Serial Number</label>
                                        <Form.Control
                                            type="text"
                                            name={"serialNumber"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Serial Number"}
                                            value={values.serialNumber}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Brand</label>
                                        <Form.Control
                                            type="text"
                                            name={"brand"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Brand"}
                                            value={values.brand}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Check Out By</label>
                                        <Form.Control
                                            type="text"
                                            name={"checkedOutSubject"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Checked Out By"}
                                            value={values.checkedOutSubject}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Status</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"status"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.status && !!errors.status
                                            }
                                            value={values.status}
                                        >
                                            {CommonUtils.getOptionsForSelect(checkOutOptions)}
                                        </Form.Control>
                                    </div>

                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}