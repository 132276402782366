import React, {Fragment} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {PieChart} from 'react-minimal-pie-chart';

import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";
import {ComplianceType} from "../../../../actions/action_constants";
import Link from "react-router-dom/es/Link";
import {CUR_ORG} from "../../../../actions/session_constants";
import {connect} from "react-redux";
import {fetchOrgFacilities} from "../../../../actions/facility_actions";
import {fetchActivePersonsByOrg} from "../../../../actions/subject_actions";
import {fetchOrgContracts} from "../../../../actions/contract_actions";
import {
    fetchEntitiesForCompliance,
    fetchOrgWithSummaryForAccessAndEligibility,
    processEntitiesForCompliance
} from "../../../../actions/org_actions";

const NATO_TEXT = 'NATO';
const CNWDI_TEXT = 'CNWDI';

class EntityDashboardComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { OrgWithSummary: null }
    }
    componentDidMount() {
        const {entityType} = this.props;

        switch (entityType) {
            case "SUBJECT":
                this.getOrgWithSummary(); 
                this.props.fetchActivePersonsByOrg(sessionStorage.getItem(CUR_ORG));
                break;
            case "FACILITY":
                this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG));
                break;
            case "CONTRACT":
                this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
                break;
        } 
    } 
    getOrgWithSummary = async () => {
        const resp = await fetchOrgWithSummaryForAccessAndEligibility(sessionStorage.getItem(CUR_ORG));
            if (resp.data && resp.data.org) {
            this.setState({
                OrgWithSummary: resp.data.org
            })
        }
        console.log('org summary is ', resp)
    }

    getEntities() {
        const {entityType, subjects, facilities, contracts} = this.props;

        switch (entityType) {
            case "SUBJECT":
                return processEntitiesForCompliance(subjects);
            case "FACILITY":
                return processEntitiesForCompliance(facilities);
            case "CONTRACT":
                return processEntitiesForCompliance(contracts);
        }
        return [];
    }

    getEntityInfo() {
        const {entityType} = this.props;

        switch (entityType) {
            case "SUBJECT":
                return {
                    entitySingular: "Subject",
                    entityPlural: "Subjects",
                    linkToEntity: "/org/dash/dashSubjects"
                };
            case "FACILITY":
                return {
                    entitySingular: "Location",
                    entityPlural: "Locations",
                    linkToEntity: "/org/dash/dashFacilities"
                };
            case "CONTRACT":
                return {
                    entitySingular: "Contract",
                    entityPlural: "Contracts",
                    linkToEntity: "/org/dash/dashContracts"
                };
        }
        return {
            entitySingular: "Unknown",
            entityPlural: "Unknown"
        };
    }

    isSpecialAccessInContracts = (entity, accessStr) => {
        if (entity.contracts) {
            for (let contractRel of entity.contracts) {
                if (contractRel && contractRel.orgId === sessionStorage.getItem(CUR_ORG)) {
                    if (contractRel.reqDocTypes) {
                        for (let eachType of contractRel.reqDocTypes) {
                            if (eachType.id.includes(accessStr)) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            return false;
        }
    }
    arrangeCompliantEntities = () => {
        const {all} = this.getEntities();
        const entities = all;
        let compliantEntityCount = 0;
        let totalEntities = 0;
        let issue2EntityCountMap = new Map();
        let totalNATOSubs = 0;
        let totalCNWDISubs = 0;

        if (entities) {
            totalEntities = entities.length;
            let reqDocTypes = [];
            if (entities.length > 0) {
                reqDocTypes = entities[0].docTypesRequired;
            }
            for (let entity of entities) {
                if ((entity.specialAccesses && entity.specialAccesses.includes(NATO_TEXT)) ||
                    this.isSpecialAccessInContracts(entity, NATO_TEXT)) {
                    totalNATOSubs++;
                }
                if ((entity.specialAccesses && entity.specialAccesses.includes(CNWDI_TEXT)) ||
                    this.isSpecialAccessInContracts(entity, CNWDI_TEXT)) {
                    totalCNWDISubs++;
                }
                if ((entity.docTypesPending && entity.docTypesPending.length > 0)) {
                    for (let docTypeStr of entity.docTypesPending) {
                        if (issue2EntityCountMap.has(docTypeStr)) {
                            issue2EntityCountMap.set(
                                docTypeStr,
                                issue2EntityCountMap.get(docTypeStr) + 1);
                        } else {
                            issue2EntityCountMap.set(docTypeStr, 1);
                        }
                    }
                } else {
                    compliantEntityCount++;
                }
            }
            if (reqDocTypes) {
                // Now see if any of the req doc type is not in the map. This means this is completely satisfied. Show it as complete
                for (let reqDocType of reqDocTypes) {
                    if (!issue2EntityCountMap.has(reqDocType)) {
                        issue2EntityCountMap.set(reqDocType, 0);
                    }
                }
            }
        }
        return {compliantEntityCount, totalEntities, issue2EntityCountMap, totalNATOSubs, totalCNWDISubs};
    };

    render() {
        const {compliantEntityCount, totalEntities, issue2EntityCountMap, totalNATOSubs, totalCNWDISubs} = this.arrangeCompliantEntities();
        const {entityPlural, entitySingular, linkToEntity} = this.getEntityInfo();
        let nonCompliantEntityCount = totalEntities - compliantEntityCount;


        let {jsxArray, compliantItems, totalItems} = this.renderIssuesProgress(issue2EntityCountMap, totalEntities, totalNATOSubs, totalCNWDISubs);
        let totalCompliantItems = totalCNWDISubs+totalNATOSubs+totalEntities;
        const data = [
            {value: compliantItems, color: '#082d5b'},
            {value: totalCompliantItems, color: '#d9534f'}
        ]
        const {entityType} = this.props;
        const {OrgWithSummary} = this.state;
        console.log(`Subject Summary is ${OrgWithSummary}`)
        console.log(`Compliance is ${compliantItems} and total is ${totalItems}`)
        return (
            <Fragment>


                <div className="col-lg-12 row">
                    <Card.Header>
                    <Link className="white-text" to={`/org/dash/summary/${String(entityType).toLowerCase()}`}><h4 className="white">{entitySingular} Compliance Summary</h4></Link>
                        
                    </Card.Header>

                    <Card.Body>

                        <ProgressBar className="h-15px" striped variant="success" now={compliantItems*100/totalItems}/>

                        {(entityType === "SUBJECT" && OrgWithSummary) ? <Fragment>
                            <div className="total_fac">
                                <h4 className="align-left"><Link className="white-text"
                                    to={`${linkToEntity}/total/${ComplianceType.ALL}`}>Total {entityPlural} : {nonCompliantEntityCount + compliantEntityCount}</Link>
                                </h4>
                                <h6 className="align-left"><span
                                    className="nce_width">Total Subject by Eligibility</span>:{' ' + OrgWithSummary.eligibilitySummary}</h6>
                                <h6 className="align-left"><span
                                    className="nce_width">Subject by Access</span>:{' ' + OrgWithSummary.accessSummary}</h6>
                                <h6 className="align-left"><span
                                    className="nce_width">Subjects by Compliance</span>: Compliant :{' ' + compliantEntityCount},   Non-Compliant :{' ' + nonCompliantEntityCount}</h6>
                            </div>
                        </Fragment> : <Fragment>
                            <div className="total_fac">
                                <h4 className="align-left"><Link className="white-text"
                                    to={`${linkToEntity}/total/${ComplianceType.ALL}`}>Total {entityPlural} : {nonCompliantEntityCount + compliantEntityCount}</Link>
                                </h4>

                                <h6 className="align-left"><span
                                    className="nce_width">Compliant Entity</span>={'' + compliantEntityCount}</h6>
                                <h6 className="align-left"><span
                                    className="nce_width">Non Compliant Entity</span>={'' + nonCompliantEntityCount}</h6>
                            </div>
                        </Fragment>}
                

                        {/* <Card.Title>
                            <Row>
                                <Col xs={5}>
                                    <h6 className="white-text">Compliance Requirement</h6>
                                </Col>
                                <Col xs={3}>
                                    <h6 className="white-text"># Compliant</h6>
                                </Col>
                                <Col xs={4}>
                                    <h6 className="white-text">Progress</h6>
                                </Col>
                            </Row>
                        </Card.Title> */}
                        {/* {jsxArray} */}
                    </Card.Body>
                </div>


            </Fragment>
        )
    }

    renderIssuesProgress(issue2EntityCountMap, totalEntities, totalNATOSubs, totalCnwdiSubs) {
        const {linkToEntity} = this.getEntityInfo();
        let jsxArray = [];
        let compliantItems = 0;
        let totalItems = 0;
        issue2EntityCountMap.forEach((count, name) => {
            if (name.includes(NATO_TEXT)){
                compliantItems = compliantItems + (totalNATOSubs - count);
                totalItems += totalNATOSubs;
            }
            if (name.includes(CNWDI_TEXT)){
                compliantItems = compliantItems + (totalCnwdiSubs - count);
                totalItems += totalCnwdiSubs;
            }
            if (!name.includes(NATO_TEXT) && !name.includes(CNWDI_TEXT)){
                compliantItems = compliantItems + (totalEntities - count);
                totalItems += totalEntities;
            }
            jsxArray.push(
                <Row key={name}>
                    <Col xs={5}>
                        <Link className="white-text"
                              to={`${linkToEntity}/byDocType/${name}/${ComplianceType.NON_COMPLIANT}`}>{name}</Link>
                    </Col>
                    {name.includes(NATO_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="white-text">
                            {totalNATOSubs - count + "/" + totalNATOSubs}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar now={(totalNATOSubs - count) * 100 / totalNATOSubs}/>
                        </Col>
                    </Fragment>
                    }
                    {name.includes(CNWDI_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="white-text">
                            {totalCnwdiSubs - count + "/" + totalCnwdiSubs}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar now={(totalCnwdiSubs - count) * 100 / totalCnwdiSubs}/>
                        </Col>
                    </Fragment>
                    }
                    {!name.includes(NATO_TEXT) && !name.includes(CNWDI_TEXT) &&
                    <Fragment>
                        <Col xs={3} className="white-text">
                            {totalEntities - count + "/" + totalEntities}
                        </Col>
                        <Col xs={4}>
                            <ProgressBar now={(totalEntities - count) * 100 / totalEntities}/>
                        </Col>
                    </Fragment>
                    }
                </Row>
            )
        })
        return {jsxArray, compliantItems, totalItems};
    }

    RenderCB(props) {
        return (

            <div className="prog_div">{props.children}</div>
        );
    }
}

function mapStateToProps({smos, contracts, subjects}) {
    return {
        facilities: smos,
        contracts: contracts,
        subjects: subjects
    }
}

export const EntityDashboard = connect(mapStateToProps, {
    fetchActivePersonsByOrg,
    fetchOrgContracts,
    fetchOrgFacilities
})(EntityDashboardComp);

EntityDashboard.propTypes = {
    entityType: PropTypes.oneOf(['SUBJECT', 'FACILITY', 'CONTRACT']).isRequired
};