import React from "react";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {Link} from "react-router-dom";
import {DateUtils} from "../../../../actions/common_utils";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {ConditionalDisplay} from "../../../util/shouldDisplay";
import {fetchAsset} from "../../../../actions/asset_actions";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { AssetList } from "./asset_list";
import {Form} from "react-bootstrap";

class AssetDetailComp extends React.Component {
    constructor(props) {
        super(props);

        this.goToEditPage = this.goToEditPage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            page: 1,
            sizePerPage: 10
        }

    }

    componentDidMount() {
        this.fetchTheAssetsAsync();
    }

    fetchTheAssetsAsync = async () => {
        let asset = await fetchAsset(this.props.match.params.id);
        this.setState({
            asset
        })
    }

    goToEditPage() {
        this.props.history.push(`/org/dash/contract/${this.props.match.params.id}/update`);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/asset">
    //                 Assets
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Asset {this.state.asset?this.state.asset.itemNumber:''}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    handleDelete() {
        // this.props.deleteContract(this.props.match.params.id, () => {
        //     fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
        //     this.props.history.push("/org/dash/contract");
        // });

    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'checkedOutSubjectName',
            text: 'Borrower Name',
            formatter: (cell) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'checkedOutDate',
            text: 'Date Borrowed',
            formatter: (cell) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'returnedDate',
            text: 'Return Date',
            formatter: (cell) => DateUtils.getDateString(cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'checkinComments',
            text: 'Return Comments',
            formatter: (cell) => cell,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    render() {
        let {asset, page, sizePerPage} = this.state;

        if (!asset) {
            return (
                <div>Loading...</div>
            );
        }

        const paramId = this.props.match.params.id;
        console.log(asset.assetManagementStatus);

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {ConditionalDisplay(
                <div className="title_flex mb-3">
                    <h3 className="page_title_left">Asset Details</h3>
                    <ButtonToolbar>
                        <Link to={`/org/dash/asset/${paramId}/update`} className="btn btn-primary">
                         <span className="fa fa-square-pen" aria-hidden="true"/> Edit
                        </Link>
                        {/*<DeleteConfirmationModal deleteEntity="Contract" handleDeleteCallback={this.handleDelete}/>*/}
                    </ButtonToolbar>
                </div>
                )}
                
                <CollapsibleCard header="General" defaultOpen>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Item Number
                        </Col>
                        <Col sm={3} md={4}>
                            {asset.itemNumber}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Serial Number
                        </Col>
                        <Col sm={3} md={4}>
                        {asset.serialNumber}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Brand
                        </Col>
                        <Col sm={3} md={4}>
                       {asset.brand}
                        </Col>
                        <Col sm={3} md={2} className="form-label">
                            Description
                        </Col>
                        <Col sm={3} md={4}>
                        {asset.description}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Is Badge
                        </Col>
                        <Col sm={3} md={4}>
                        {asset.badge?'Yes':'No'}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Asset Management (If lost/stolen etc)
                        </Col>
                        <Col sm={3} md={4}>
                        {(asset.assetManagementStatus ? asset.assetManagementStatus.displayName : '')}
                        </Col>
                    </Row>
                
                    
                    {asset.checkedOutSubjectName &&
                    <Row className={'mb-3'}>
                        <Col sm={3} md={2} className="form-label">
                            Checked Out By:
                        </Col>
                        <Col sm={3} md={4}>
                        {asset.checkedOutSubjectName} 
                        </Col>
                    </Row>
                    }
                </CollapsibleCard>
                {asset.borrowers &&
                <CollapsibleCard header="Log" defaultOpen>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="subjectId"
                                    data={asset.borrowers}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize: asset.borrowers.length
                                    })}
                                    columns={this.getColumns()} filter={filterFactory()}/>
                </CollapsibleCard>
                }
            </div>
        );
    }
}

export const AssetDetail = connect(null, null)(AssetDetailComp);
