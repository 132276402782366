/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {FormikDatePickerComponent} from "../../../../../components/formik/formik_date_picker";
import {CommonUtils} from "../../../../../actions/common_utils";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage3Comp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contracts: []
        };

        this.minDate = new Date();
        this.minDate.setMonth(this.minDate.getMonth() - 23);
    }

    componentDidMount() {
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage, orgs} = this.props;

        return (
            <Formik
                initialValues={{
                    contractNo: contract.contractNo?contract.contractNo:undefined,
                    subContractNo: contract.subContractNo?contract.subContractNo:undefined,
                    endDate: contract.endDate?contract.endDate:undefined,
                    contractType: contract.contractType?contract.contractType:undefined,
                    primeContractNo: contract.primeContractNo?contract.primeContractNo:undefined,
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        contractNo: Yup.string().required('Contract Number is required'),
                        primeContractNo: Yup.string().test(
                            'prime-contract-no-req-test',
                            'Please enter a prime contract number',
                            function (value) {
                                if (this.parent.contractType !== 'SUB'){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        )
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Card>
                            <Card.Header>Section 2</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Contract Number</Form.Label>
                                        <Form.Control required type="text" name={'contractNo'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter contract number'}
                                                      isInvalid={touched.contractNo && !!errors.contractNo}
                                                      value={values.contractNo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contractNo}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId={"contractType"}>
                                        <Form.Label>Contract Type</Form.Label>
                                        <Form.Control required as="select" name={'contractType'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.contractType && !!errors.contractType}
                                                      value={values.contractType}
                                        >
                                            {CommonUtils.getOptionsForSelect([
                                                {id: "PRIME", name: "Prime Contract"},
                                                {id: "SUB", name: "Sub-Contract"},
                                                {id: "OTHER", name: "Solicitation or Other"}])}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contractType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    {values.contractType !== 'SUB' &&
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Sub Contract Number</Form.Label>
                                        <Form.Control required type="text" name={'subContractNo'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter contract number'}
                                                      isInvalid={touched.subContractNo && !!errors.subContractNo}
                                                      value={values.subContractNo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.subContractNo}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }
                                    {values.contractType === 'SUB' &&
                                    <Form.Group as={Col} md="4" controlId={"primeContractNo"}>
                                        <Form.Label>Prime Contract Number</Form.Label>
                                        <Form.Control required type="text" name={'primeContractNo'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur} placeholder={'Enter prime contract number'}
                                                      isInvalid={touched.primeContractNo && !!errors.primeContractNo}
                                                      value={values.primeContractNo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.primeContractNo}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }


                                    <Form.Group as={Col} md="4" controlId={'endDate'}>
                                        <Form.Label>End Date</Form.Label>
                                        <Field
                                            id="endDate"
                                            name="endDate"
                                            minDate={this.minDate}
                                            component={FormikDatePickerComponent}
                                            placeholder={"Due Date (MM/DD/YYYY)"}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.endDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                               
                            </Card.Body>
                            <Card.Footer>
                            <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                                    handleDeleteCallback={this.handleCancel}/>
                                </ButtonToolbar>  
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        );
    }
}

export const ContractNewPage3 = ContractNewPage3Comp;

ContractNewPage3.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};

