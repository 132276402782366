/**
 * Created by pshivaraman on 4/7/18.
 */

import React from "react";
import {reduxForm} from "redux-form";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {connect} from "react-redux";
import {Steps} from "primereact/components/steps/Steps";
import {ContractNewPage1} from "./contract_new_wizard/contract_new_page_1";
import {ContractNewPage2} from "./contract_new_wizard/contract_new_page_2";
import {ContractNewPage3} from "./contract_new_wizard/contract_new_page_3";
import {ContractNewPage4} from "./contract_new_wizard/contract_new_page_4";
import {ContractNewPage5} from "./contract_new_wizard/contract_new_page_5";
import {ContractNewPage6} from "./contract_new_wizard/contract_new_page_6";
import {ContractNewPage7} from "./contract_new_wizard/contract_new_page_7";
import {ContractNewPage8} from "./contract_new_wizard/contract_new_page_8";
import {ContractNewPage9} from "./contract_new_wizard/contract_new_page_9";
import {ContractNewPage10} from "./contract_new_wizard/contract_new_page_10";
import ContractNewPage11 from "./contract_new_wizard/contract_new_page_11";
import "./contract.css";
import {createOrgContract, fetchOrgContracts} from "../../../../actions/contract_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {PARENT_TASK_ORDER_CONTRACT} from "../../../../actions/action_constants";
import { Card } from "react-bootstrap";
class ContractNew extends React.Component {
    constructor(props) {
        super(props);

        let stepItemLabels= [
            {label: "General"},
            {label: "Clearance and Safeguarding"},
            {label: "Specification Details"},
            {label: "Specification Details Cont."},
            {label: "Contractors and Performance"},
            {label: "Access Requirements"},
            {label: "Release and Security"},
            {label: "GCA Details"},
            {label: "Certification"},
            {label: "Distribution and Entities"},
            {label: "Review and Submit"},
            ];
        this.state = {
            stepNo: 0,
            contract: {
                status: "Pending",
                agency: "none",
                contractType: "PRIME",
                specType: 'orig',
                followsOn: false,
                finalDD254: false,
                inspections: false,
                addSecurity: false
            },
            stepItemLabels: stepItemLabels,
            stepItems: [
                stepItemLabels[0],
                stepItemLabels[1],
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
                {label: ""},
            ]
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     let {contract} = this.state;
    //     if (nextProps.match.params.id) {
    //         contract.contractType = "SUB";
    //         contract.primeContractId = this.props.match.params.id;
    //     }
    //     console.log('Parent contract id is ', nextProps.match.params.taskOrderParentContractId)
    //     if (nextProps.match.params.taskOrderParentContractId) {
    //         let parentContract = sessionStorage.getItem(PARENT_TASK_ORDER_CONTRACT);
    //         if (parentContract){
    //             contract = {...parentContract};
    //             contract.id = null;
    //             contract.contractNo = null;
    //         }
    //         contract.taskOrderParentContractId = nextProps.match.params.taskOrderParentContractId;
    //     }
    // }

    componentDidMount() {
        let {contract} = this.state;
        if (this.props.match.params.id) {
            contract.contractType = "SUB";
            contract.primeContractId = this.props.match.params.id;
        }
        console.log('Parent contract id is ', this.props.match.params.taskOrderParentContractId)
        if (this.props.match.params.taskOrderParentContractId) {
            let parentContract = JSON.parse(sessionStorage.getItem(PARENT_TASK_ORDER_CONTRACT));
            console.log('Parent contract is ', parentContract)
            if (parentContract){
                Object.assign(contract, parentContract)
                contract.id = null;
                contract.contractNo = '';
            }
            contract.taskOrderParentContractId = this.props.match.params.taskOrderParentContractId;
            console.log('Contract obj is ', contract)
        }
    }

    onSubmit(values) {
        let {contract} = this.state;
        console.log('Submitted values for final step is ', values)
        contract = {...contract, ...values};
        this.props.createOrgContract(contract, () => {
            this.props.history.push("/org/dash/contract");
        });
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/contract"
    //                             onClick={() => fetchOrgContracts(sessionStorage.getItem(CUR_ORG))}>
    //                 Contracts
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Contract
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getStepLabelsRuntime = (goBack) => {
        let increment = 1;
        if (goBack){
            increment = -1;
        }
        let {stepItemLabels, stepNo} = this.state;
        let newStep = stepNo + increment;
        let stepItems = ['','','','','','','','','',''];
        stepItems[newStep] = stepItemLabels[newStep]
        if (newStep+1 < 10) {
            stepItems[newStep + 1] = stepItemLabels[newStep + 1]
        }
        if (newStep-1 >= 0) {
            stepItems[newStep-1] = stepItemLabels[newStep-1]
        }

        return stepItems;
    }

    getSteps() {
        const {stepItems} = this.state;

        const onActiveIndexChange = (e) => this.setState({stepNo: e.index});
        return (
            <Steps model={stepItems} activeIndex={this.state.stepNo}
                   activeIndexChange={onActiveIndexChange.bind(this)} className="contract-steps"/>
        )
    }

    onStepComplete = (values) => {
        console.log('Submitted values for step is ', values)
        let {contract} = this.state;
        let stepItems = this.getStepLabelsRuntime();
        console.log('Contract is ', contract);
        this.setState({
            contract: {...contract, ...values},
            stepNo: this.state.stepNo + 1,
            stepItems: stepItems
        })
        console.log('Contract after update is ', this.state.contract)
    }

    nextPage() {
        let stepItems = this.getStepLabelsRuntime();
        this.setState({
            stepNo: this.state.stepNo + 1,
            stepItems: stepItems
        })
    }

    previousPage() {
        let stepItems = this.getStepLabelsRuntime(true);
        this.setState(
            {
                stepNo: this.state.stepNo - 1,
                stepItems: stepItems
            })
    }

    renderStepContent() {

        switch (this.state.stepNo) {
            case 0:
                return <ContractNewPage1 onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 1:
                return <ContractNewPage2 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 2:
                return <ContractNewPage3 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 3:
                return <ContractNewPage4 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 4:
                return <ContractNewPage5 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 5:
                return <ContractNewPage6 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 6:
                return <ContractNewPage7 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 7:
                return <ContractNewPage8 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 8:
                return <ContractNewPage9 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
            case 9:
                return <ContractNewPage10 previousPage={this.previousPage} onStepComplete={this.onSubmit} contract={this.state.contract}/>;
            // case 10:
            //     return <ContractNewPage11 previousPage={this.previousPage} onStepComplete={this.onStepComplete} contract={this.state.contract}/>;
        }
    }

    render() {
        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <h3 className="page_title_left mb-4">New Contract</h3>
                <Card>
                    <Card.Body>
                   <div className="mt-4 mb-4">{this.getSteps()}</div> 
                    {this.renderStepContent()}
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default reduxForm({
    form: 'ContractNewForm'
})(
    connect(null, {createOrgContract})(ContractNew)
);