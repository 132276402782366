/**
 * Upload component for csv files
 */
import React from "react";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonToolbar, Card, Col, Form} from "react-bootstrap";
import {FormikFieldDropzone} from "../../../../components/formik/formik_field_dropzone";
import PropTypes from 'prop-types';

class UploadDialogForOtherEntitiesComp extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.state = {
            showModal: false,
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({showModal: false});
    }

    onSubmit = (values) => {
        // expect only one document here
        this.props.onUpload(Array.from(values.documents)[0]);
        this.close();
    }

    render() {
        const {accept, entityBeingUploaded} = this.props;
        return (
            <React.Fragment>
                <Button onClick={this.open} variant="success" className="btn btn-primary">
                    <span className="fa fa-cloud-upload" aria-hidden="true"/> Upload {entityBeingUploaded}
                </Button>
                <Modal size="lg" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{entityBeingUploaded} Upload Form</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            documents: "",
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                documents: Yup.mixed().test(
                                    'Need-file',
                                    'There must be a file uploaded',
                                    function (value) {
                                        return value && value.length > 0;
                                    }
                                ).nullable().required('You must upload a document')
                            })

                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Select CSV to Upload</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'documents'}>
                                                <Field
                                                    name="documents"
                                                    component={FormikFieldDropzone}
                                                    accept={accept}
                                                    multiple={false}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                    <Card.Footer>
                                    <ButtonToolbar>
                                            <Button variant="primary" type="submit">Upload</Button>
                                            <Button variant="danger" onClick={this.close}>Cancel</Button>
                                        </ButtonToolbar>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>);
    }
}

export const UploadDialogForOtherEntities = connect(null, null)(UploadDialogForOtherEntitiesComp);

UploadDialogForOtherEntities.propTypes = {
    accept: PropTypes.array.isRequired,
    onUpload: PropTypes.func.isRequired,
    entityBeingUploaded: PropTypes.string.isRequired
}