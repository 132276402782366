/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {Link} from "react-router-dom";
import {validate_contract_new} from "./contract_new_validate";
import {reduxForm} from "redux-form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";

class ContractNewPage10 extends React.Component {
    render(){
        const {handleSubmit, previousPage} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <h4>Review and Submit</h4>
                <ButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Link to="/org/dash/contract" className="btn btn-danger">Cancel</Link>
                </ButtonToolbar>
                <Pager>
                    <PagerItem><Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button></PagerItem>
                </Pager>
            </form>
        );
    }
}

export default reduxForm({
    form: 'ContractNewForm',          // <------ same form name
    destroyOnUnmount: false,          // <------ preserve form data
    forceUnregisterOnUnmount: true,   // <------ unregister fields on unmount
    validate: validate_contract_new
})(ContractNewPage10);