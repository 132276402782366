/**
 * Created by shiva on 5/29/18.
 */

import React from "react"
import {connect} from "react-redux"
import SubjectUploadHeader from "../../dash/subject/subject_upload_header";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import {fetchCurOrg} from "../../../../actions/org_actions"
import excel2PropMap from '../../../../mappingFiles/excel2PropertyMap.json'
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import Form from "react-bootstrap/Form";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {bulkUploadDocControl} from "../../../../actions/doc_control_actions";

const uploadDataStr = 'Upload Data';
export const BULK_UPLOAD_ACCEPT_OPTION = 'Accept';
export const BULK_UPLOAD_IGNORE_OPTION = 'Ignore';
class DocControlUploadListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);

        this.state = {
            selected: [],
            initializeSelect: false,
            columnPreferences: {}
        }
    }

    componentDidMount() {
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Document Control
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    uploadData = async () => {
        const {selected} = this.state;
        if (selected && selected.length !== 0) {
            let filteredDocControls = this.props.docControlsUploaded.filter(x => selected.includes(x.controlNumber));
            console.log('Going to upload ', filteredDocControls);
            try{
                // make sure you convert workingpaper to true or false
                for (let docControl of filteredDocControls){
                    docControl.workingPaper = (docControl.workingPaper === 'Yes');
                    docControl.classificationLevel = (!docControl.classificationLevel || docControl.classificationLevel === "")?'NONE':docControl.classificationLevel;
                    docControl.destructionType = docControl.destructionType?docControl.destructionType.toUpperCase():null;
                }
                await bulkUploadDocControl(filteredDocControls);
                this.props.showSuccessGrowl("Document Control Upload", "The document control list have been uploaded successfully!");
                this.props.history.push('/org/dash/docControl');
            } catch (error) {
                this.props.showErrorGrowl(`Document Control Upload", "Unexpected error uploading document control list. Error is ${error}`);
            }
        } else {
            this.props.showErrorGrowl("No Document Control Selected", "Select at least 1 Document Control to upload.");
        }
    }

    headerFormatterCallback1 = (column, colIndex, {sortElement, filterElement}) => {

        let selectOptions = [];
        let columnOptions = [BULK_UPLOAD_ACCEPT_OPTION, BULK_UPLOAD_IGNORE_OPTION];
        for (let opt of columnOptions) {
            selectOptions.push(<option key={opt}
                                       value={opt}>{opt}</option>)
        }

        const changeFunc = (val) => {
            let {columnPreferences} = this.state;
            columnPreferences[column.dataField] = val.target.value;
            this.setState({columnPreferences: columnPreferences});
        };
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div>
                    {column.text}
                    {/*{sortElement}*/}
                </div>
                <div>
                    <Form.Control name={`${column.text}`} as="select" onChange={changeFunc}>
                        {selectOptions}
                    </Form.Control>
                </div>
            </div>
        );
    }

    renderHeader() {
        let {columnPreferences} = this.state;
        return SubjectUploadHeader({fieldMapObject: excel2PropMap.docControlMap, headerFormatterCallback: this.headerFormatterCallback1, columnPreferences: columnPreferences, noColor: true});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setInitialSelection();
    }

    setInitialSelection = () => {
        const {docControlsUploaded} = this.props;
        if (!docControlsUploaded || docControlsUploaded.length === 0) {
            return;
        }
        let initSelected = [];
        for (let docControl of docControlsUploaded) {
            initSelected.push(docControl.controlNumber);
        }
        this.setState({
            selected: initSelected,
            initializeSelect: true
        });
    }

    render() {
        const {docControlsUploaded} = this.props;
        if (!docControlsUploaded){
            return <div>Loading...</div>
        }
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            nonSelectable: [],
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    if (row.controlNumber && (row.controlNumber !== '')){
                        this.setState(() => ({
                            selected: [...this.state.selected, row.controlNumber]
                        }));
                    }
                } else {
                    this.setState(() => ({
                        selected: this.state.selected.filter(x => x !== row.controlNumber)
                    }));
                }
            },
            onSelectAll: (isSelect, rows) => {
                const ids = rows.map(r => r.controlNumber).filter(controlNumber => (controlNumber !== null && controlNumber !== ''));
                if (isSelect) {
                    this.setState(() => ({
                        selected: ids
                    }));
                } else {
                    this.setState(() => ({
                        selected: []
                    }));
                }
            }
        };
        const pagination = paginationFactory({
            sizePerPageList: [
                {text: '10', value: 10},
                {text: '20', value: 20},
                {text: '30', value: 30}
            ]
        });

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="float-right">
                    <ButtonToolbar>
                        <Button variant="primary" onClick={this.uploadData}>{uploadDataStr}</Button>
                    </ButtonToolbar>
                </div>
                <h3>Uploaded Data</h3>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="controlNumber"
                                data={docControlsUploaded ? docControlsUploaded : []} columns={this.renderHeader()} pagination={pagination}
                                selectRow={selectRow} wrapperClasses={"table-responsive"}/>
            </div>
        )
    }
}

function mapStateToProps({docControlsUploaded}) {
    return {
        docControlsUploaded
    }
}

export const DocControlUploadList = connect(mapStateToProps, {
    showSuccessGrowl,
    showErrorGrowl
})(DocControlUploadListComponent);
