/**
 * Created by pshivaraman on 6/22/18.
 */

import React from "react";
import {getAccessReqsByAccessor} from "../../../../../actions/fieldValue_actions";
import {Pager, PagerItem} from "../../../../../components/bootstrap_addons/pager";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Card, Col, Form} from "react-bootstrap";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class ContractNewPage6Comp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            access_reqs: [],
            contractor_access_reqs: []
        }
    }

    componentDidMount() {
        getAccessReqsByAccessor("contract", accessReqsResp => {
            this.setState({access_reqs: accessReqsResp.data});
        });

        getAccessReqsByAccessor("contractor", contractorAccessReqsResp => {
            this.setState({contractor_access_reqs: contractorAccessReqsResp.data});
        });
    }

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        this.props.onStepComplete(values);
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {contract, previousPage} = this.props;

        const avail_access_reqs = this.state.access_reqs.map((accessReq) => {
            return {
                value: accessReq.id,
                label: accessReq.name
            }
        });

        const avail_contractor_access_reqs = this.state.contractor_access_reqs.map((accessReq) => {
            return {
                value: accessReq.id,
                label: accessReq.name
            }
        });

        return (
            <Formik
                initialValues={{
                    accessReqd: contract.accessReqd?contract.accessReqd:undefined,
                    otherAccessReqd: contract.otherAccessReqd?contract.otherAccessReqd:undefined,
                    contractorAccessReqd: contract.contractorAccessReqd?contract.contractorAccessReqd:undefined,
                    contractorOtherAccessReqd: contract.contractorOtherAccessReqd?contract.contractorOtherAccessReqd:undefined
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        otherAccessReqd: Yup.string().test(
                            'other-access-req-test',
                            'Please explain',
                            function (value) {
                                if (!this.parent.accessReqd || !this.parent.accessReqd.includes("other")){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        contractorOtherAccessReqd: Yup.string().test(
                            'cont-other-access-req-test',
                            'Please explain',
                            function (value) {
                                if (!this.parent.contractorAccessReqd || !this.parent.contractorAccessReqd.includes("other")){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit} className="new_contract_form">
                        <Card>
                            <Card.Header>Section 10</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'accessReqd'}>
                                        <Form.Label>Contract Requires Access To:</Form.Label>
                                        <Field
                                            id="accessReqd"
                                            name="accessReqd"
                                            component={FormikFieldDualListBox}
                                            options={avail_access_reqs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.accessReqd}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {!!(values.accessReqd && values.accessReqd.includes("other")) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Other Access Required (specify)</Form.Label>
                                            <Form.Control required as="textarea" name={'otherAccessReqd'}
                                                onChange={handleChange}
                                                onBlur={handleBlur} placeholder={'Please specify..'}
                                                isInvalid={touched.otherAccessReqd && !!errors.otherAccessReqd}
                                                value={values.otherAccessReqd}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.otherAccessReqd}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                }
                            </Card.Body>
                            <hr></hr>
                            <Card.Header>Section 11</Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={'contractorAccessReqd'}>
                                        <Form.Label>Performing this contract, the contractor will:</Form.Label>
                                        <Field
                                            id="contractorAccessReqd"
                                            name="contractorAccessReqd"
                                            component={FormikFieldDualListBox}
                                            options={avail_contractor_access_reqs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contractorAccessReqd}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                {!!(values.contractorAccessReqd && values.contractorAccessReqd.includes("other")) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>Other Access Required (specify)</Form.Label>
                                            <Form.Control required as="textarea" name={'contractorOtherAccessReqd'}
                                                onChange={handleChange}
                                                onBlur={handleBlur} placeholder={'Please specify..'}
                                                isInvalid={touched.contractorOtherAccessReqd && !!errors.contractorOtherAccessReqd}
                                                value={values.contractorOtherAccessReqd}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contractorOtherAccessReqd}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                }
                            </Card.Body>

                            <Card.Footer>
                                <ButtonToolbar>
                                    <Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button>
                                    <Button variant={'outline-primary'} className="pager-button" disabled={isSubmitting} type="submit">Next</Button>
                                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Contract"
                                        handleDeleteCallback={this.handleCancel} />
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>
                        
                    </Form>
                )}
            </Formik>
        );
                            // return (
        //     <form onSubmit={handleSubmit}>
        //         <h4>Section 10</h4>
        //         <Field name="accessReqd" label="Contract Requires Access To:" canFilter
        //                component={FieldRBDualListBox} options={avail_access_reqs}/>
        //         {this.props.showAccessReqd &&
        //         <Field name="otherAccessReqd" label="Other Access Required (specify)"
        //                component={FieldRBTextArea}/>
        //         }
        //
        //         <h4>Section 11</h4>
        //         <Field name="contractorAccessReqd" label="Contractor Requires Access To:" canFilter
        //                component={FieldRBDualListBox} options={avail_contractor_access_reqs}/>
        //         {this.props.showContractorAccessReqd &&
        //         <Field name="contractorOtherAccessReqd" label="Other Access Required (specify)"
        //                component={FieldRBTextArea}/>
        //         }
        //         <Pager>
        //             <PagerItem><Button variant={'outline-primary'} className="pager-button" onClick={previousPage}>Previous</Button></PagerItem>
        //             <PagerItem><Button variant={'outline-primary'} className="pager-button" type="submit">Next</Button></PagerItem>
        //         </Pager>
        //     </form>
        // );
    }
}

export const ContractNewPage6 = ContractNewPage6Comp;

ContractNewPage6.propTypes = {
    onStepComplete: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired
};
