import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { FieldMultiSelectDropdown } from "../../../../components/redux_form/field_components_rb";
import { Picky } from 'react-picky';
import { FormikFieldMultiSelectDropDown } from "../../../../components/formik/formik_multiselectdropdown";
import { CommonUtils } from "../../../../actions/common_utils";




export const Contract_Filter = ({ filters, onSubmit ,agencies}) => {

    const [ShowHover, setShowHover] = useState(false);
    const [agenciesList, setAgenciesList] = useState(agencies);
    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])
    useEffect(() => {
        setAgenciesList([
            { id: "", name: "Select Agency" },
            { id: "None", name: "None" },
            ...agencies.map(i => {
                return { id: i.name, name: i.name }
            })
        ])
    }, [agencies])

    const OnResetClick = () => { 

        onSubmit({ 
            contractType: "",
            status: "",
            agencyData: ""

        })
        setShowHover(false)
    }

    const contractOptions = [
        {id: "", name: "Select Status"},
        {id: "Active", name: "Active"},
        {id: "Pending", name: "Pending"},
        {id: "Archived", name: "Archived"},
        {id: "Closed", name: "Closed"}
    ]

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur 
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}> 
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label col-form-label">Contract Type</label>
                                        <Form.Control
                                            type="text"
                                            name={"contractType"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Contract Type"}
                                            value={values.contractType}
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                    <label className="form-label col-form-label">Status</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"status"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.status && !!errors.status
                                            }
                                            value={values.status}
                                        >
                                            {CommonUtils.getOptionsForSelect(contractOptions)}
                                        </Form.Control>
                                    </div>

                                    <div className="form-group mb-3">
                                    <label className="form-label col-form-label">Agency</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"agencyData"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={
                                                touched.status && !!errors.status
                                            }
                                            value={values.agencyData}
                                        >
                                            {CommonUtils.getOptionsForSelect(agenciesList)}
                                        </Form.Control>
                                    </div>

                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick();
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}