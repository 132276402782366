/**
 * Created by pshivaraman on 3/29/18.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../actions/session_constants";
import OrgSideNav from "./org_sidenav";
import {fetchCurOrg} from "../../../actions/org_actions";
import "../../../assets/css/components/sidebar.scss";
import { ChatBotComponent } from "../../../components/chatbotComponent";

export default function (ComposedComponent) {
    class OrgDash extends Component {
        constructor(props) {
            super(props);

            this.state = {
                sideBarOpen: false
            }
        }

        componentWillMount() {
            // If current organization is not set, send user to switch organization page.
            if (!sessionStorage.getItem(CUR_ORG)) {
                this.props.history.push('/');
            }
            this.props.fetchCurOrg();

        }

        componentWillUpdate(newprops) {
            if (!sessionStorage.getItem(CUR_ORG)) {
                this.props.history.push('/');
            }
        }

        onSideBarToggle(e) {
            e.preventDefault();
            if (this.state.sideBarOpen) {
                this.setState({
                    sideBarOpen: false
                });
            } else {
                this.setState({
                    sideBarOpen: true
                });
            }
        }

        render() {
            const glyph = this.state.sideBarOpen ? "glyphicon-chevron-left" : "glyphicon-chevron-right";
            const wrapperClass = this.state.sideBarOpen ? "toggled" : "";
            return (
                <div id="wrapper" className={wrapperClass}>
                    <OrgSideNav/>
                    <button type="button" className="btn btn-primary btn-xs sidebar-toggle hide-on-mobile"
                            onClick={this.onSideBarToggle.bind(this)}>
                        {/* <i className={`glyphicon ${glyph}`}/> */}
                       {wrapperClass=="toggled" ?<i className="fa fa-long-arrow-alt-left"></i>:<i className="fa fa-long-arrow-alt-right"></i>} 
                    </button>
                    <button type="button" className="btn btn-primary btn-xs sidebar-toggle show-on-mobile"
                            onClick={this.onSideBarToggle.bind(this)}>
                        {/* <i className={`glyphicon ${glyph}`}/> */}
                       {wrapperClass=="toggled" ? <i className="fa fa-long-arrow-alt-right"></i>:<i className="fa fa-long-arrow-alt-left"></i>} 
                    </button>
                    <div id="page-content-wrapper">
                        <ComposedComponent {...this.props} />
                    </div>
                    <ChatBotComponent />
                </div>
            )
        }
    }

    function mapStateToProps({cur_org}) {
        return {
            cur_org
        };
    }

    return connect(mapStateToProps, {fetchCurOrg})(OrgDash);
}