/**
 * Created by pshivaraman on 3/30/18.
 */

import React from "react";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchActivePersonsByOrg} from "../../../../actions/subject_actions";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {getFacilityLevels, getTimezones} from "../../../../actions/fieldValue_actions";
import {createFacility, fetchOrgFacilities} from "../../../../actions/facility_actions";
import {fetchOrgContracts} from "../../../../actions/contract_actions";
import {
    FieldRBCountrySelector,
    FieldRBDualListBox,
    FieldRBInput,
    FieldRBRegionSelector,
    FieldRBSelectWithKeys
} from "../../../../components/redux_form/field_components_rb";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class SmoNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            timezones: [],
        };
    }

    componentDidMount() {
        this.state = {
            levels: []
        };
        getFacilityLevels((response) => {
            this.setState({
                levels: response.data
            });
        });

        getTimezones((response) => {
            this.setState({
                timezones: response.data.map(tz => {
                    return {
                        id: tz.id,
                        name: tz.text
                    };
                }),
            });
        });


        this.props.fetchActivePersonsByOrg();
        this.props.fetchOrgContracts(sessionStorage.getItem(CUR_ORG));
    }

    onSubmit(values) {
        this.props.createFacility(sessionStorage.getItem(CUR_ORG), values, () => {
            this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG));
            this.props.history.push("/org/dash/facility");
        });
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/facility"
    //                             onClick={() => fetchOrgFacilities(sessionStorage.getItem(CUR_ORG))}>
    //                 Locations
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Location
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit, sel_country, subjects, org_contracts} = this.props;

        const avail_subjects = subjects ? subjects.map((subject) => {
            return {
                value: subject.id,
                label: `${subject.personFirstName} ${subject.personLastName}`
            }
        }).sort((a, b) => a.label.localeCompare(b.label)) : [];

        const avail_contracts = org_contracts ? org_contracts.map((contract) => {
            return {
                value: contract.contractId,
                label: contract.name
            }
        }).sort((a, b) => a.label.localeCompare(b.label)) : [];

        return (
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                {/* {this.getBreadCrumb()} */}
                <div className="new_facility">

                    <div className="section_title">
                        <h4>New Location</h4>
                    </div>

                    <div className="col-lg-12 row m-0 pt-2 pb-4">
                        <div className="col-lg-4">
                            <Field name="name" required="required" component={FieldRBInput} horizontal label="Name"/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="cageCode" component={FieldRBInput} horizontal label="Cage Code" maxLength="15"/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="level" component={FieldRBSelectWithKeys} horizontal options={this.state.levels}
                            label="Location Level"/>
                        </div>

                        <div className="col-lg-4">
                            <Field name="safeguardLevel" component={FieldRBSelectWithKeys} horizontal options={this.state.levels}
                            label="Safeguarding Level"/>
                        </div>

                    </div>
                </div>

                <div className="new_facility">
                    <div className="section_title">
                        <h4>Primary Address</h4>
                    </div>
                
                    <div className="col-lg-12 row m-0 pt-2 pb-4">
                        <div className="col-lg-4">
                            <Field name="suite" component={FieldRBInput} horizontal label="Suite No. or PO Box"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="street" required="required" component={FieldRBInput} horizontal label="Street Address"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="city" required="required" component={FieldRBInput} horizontal label="City"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="country" component={FieldRBCountrySelector} horizontal label="Country"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="state" component={FieldRBRegionSelector} horizontal country={sel_country} label="Region"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="zipcode" required="required" component={FieldRBInput} horizontal
                            label="Postal or Zip Code"/>
                        </div>
                    </div>
                </div>

                <div className="new_facility">
                    <div className="section_title">
                        <h4>Point of Contact Information</h4>
                    </div>
                    
                    <div className="col-lg-12 row m-0 pt-2 pb-4">
                        <div className="col-lg-4">
                            <Field name="pocName" required="required" component={FieldRBInput} horizontal label="POC Name"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="pocPhone" required="required" component={FieldRBInput} horizontal label="POC Phone Number" type="tel"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="pocEmail" required="required" component={FieldRBInput} horizontal label="POC Email Address" type="email"/>
                        </div>
                        <div className="col-lg-4">
                            <Field name="timezone" component={FieldRBSelectWithKeys} horizontal label="Timezone"
                                options={this.state.timezones}/>
                        </div>
                    </div>
                </div>
                
                <div className="new_facility">
                    <div className="section_title">
                        <h4>Personnel</h4>
                    </div>

                    <div className="col-lg-12 mt-10 row m-0 pt-2 pb-4">
                        <Field name="servicingSubjects" label="Location Management Personnel/Serviced" canFilter
                            component={FieldRBDualListBox}
                            options={avail_subjects}/>
                        <Field name="owningSubjects" label="Location Management Personnel/Owned" canFilter
                            component={FieldRBDualListBox}
                            options={avail_subjects}/>
                    </div>
                </div>
                
                <div className="new_facility">
                    <div className="section_title">
                        <h4>Contracts</h4>
                    </div>

                    <div className="col-lg-12 mt-10 row m-0 pt-2 pb-4">
                    <Field name="contracts" label="Contracts" canFilter component={FieldRBDualListBox}
                        options={avail_contracts}/>

                    </div>
                </div>

                <ButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    {/* <Link to="/org/dash/facility" className="btn btn-danger">Cancel</Link> */}
                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Location"
                                                         handleDeleteCallback={this.handleCancel}/>
                </ButtonToolbar>
            </Form>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (!values.street) {
        errors.street = "Street Address is required!";
    }
    if (!values.city) {
        errors.city = "City is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.zipcode) {
        errors.zipcode = "Postal or Zip Code is required!";
    }
    if (!values.pocName) {
        errors.pocName = "Point of Contact Name is required!";
    }
    if (!values.pocEmail) {
        errors.pocEmail = "Point of Contact Email Address is required!";
    }
    if (!values.pocPhone) {
        errors.pocPhone = "Point of Contact Phone Number is required!";
    }

    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.SmoNewForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        sel_country: selCountry,
        subjects: state.subjects,
        org_contracts: state.org_contracts,
    }
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'SmoNewForm',
    initialValues: {
        country: 'US'
    }
})(
    connect(mapStateToProps, {createFacility, fetchOrgFacilities, fetchActivePersonsByOrg, fetchOrgContracts})(SmoNew)
);
