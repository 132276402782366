import React, {Component, Fragment} from "react";
import {Button, ButtonToolbar, Card, Col, Form, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {createEmailTask, createEmailTaskPrecheck} from "../../../../actions/task_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {getActiveOrgPersons} from "../../../../actions/subject_actions";
import {createAsset} from "../../../../actions/asset_actions";
import {Formik} from "formik";
import {CommonUtils} from "../../../../actions/common_utils";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {getAssetManagementStatus} from "../../../../actions/fieldValue_actions";
import {queryChatbot} from "../../../../actions/chatbot_actions";

class AssetNewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            isBadgeOrNotOptions: [{
                id: "no",
                name: "No"
            }, {
                id: "yes",
                name: "Yes"
            }]
        }
    }
    

    componentDidMount() {
        this.getSubjects();

        getAssetManagementStatus((response) => {
            let assetStatusList = [
                {
                    id: "",
                    name: "Select a Status"
                }
            ];
            response.data.forEach((assetStatus) => {
                assetStatusList.push({
                    id: assetStatus.id,
                    name: assetStatus.displayName
                });
            });
            this.setState({
                assetManagementStatusOptions: assetStatusList
            })
        })
    }

    onSubmit = async (values) => { 
        try {
            if (values.assetManagementStatus === ''){
                values.assetManagementStatus = null;
            }
            if (values.badge && values.badge.toUpperCase() === 'YES'){
                values.badge = true;
            } else {
                values.badge = false;
            }
            await createAsset(values);
            this.props.history.push("/org/dash/asset");
        } catch (e) {
            console.log('Error creating asset', e);
            this.props.showErrorGrowl("Unexpected error occurred", "Error creating asset.");
        }
    }

    getSubjects() {
        getActiveOrgPersons((response) => {
            let newEntityIds = [
                {
                    id: "",
                    name: "Select Subject"
                }
            ];
            response.data.forEach((subject) => {
                newEntityIds.push({
                    id: subject.id,
                    name: `${subject.personFirstName?subject.personFirstName:''} ${subject.personLastName}`
                });
            });

            this.setState({
                availEntities: newEntityIds,
            });
        });
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/asset">
    //                 Assets
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 New Asset
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    close = () => {
        this.setState({showModal: false, warningMessage: null});
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {handleSubmit} = this.props;
        const {warningMessage, showModal, availEntities, isBadgeOrNotOptions, assetManagementStatusOptions} = this.state;

        return (
            <Formik
                initialValues={{
                    "itemNumber": "",
                    "description": "",
                    "brand": "",
                    "serialNumber": "",
                    "checkedOutSubjectId": "",
                    "badge": "",
                    "assetManagementStatus": ""
                }}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        itemNumber: Yup.string().required('Enter the item number'),
                        description: Yup.string().required('Please enter a description'),
                        serialNumber: Yup.string().test(
                            'serialNumber-asset-test',
                            'Please enter a Serial Number',
                            function (value) {
                                if (this.parent.badge){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                        checkedOutSubjectId: Yup.string().test(
                            'checkedout-subject-id-test',
                            'Please select a subject',
                            function (value) {
                                if (this.parent.badge === "" || this.parent.badge === 'no'){
                                    return true;
                                }
                                return value && (value !== "");
                            }
                        ),
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* <h3>New Asset</h3> */}
                            <Card>
                                <Card.Header>New Asset Creation Form</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId={'itemNumber'}>
                                            <Form.Label>Item Number</Form.Label>
                                            <Form.Control required type="text" name={'itemNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the Item Number'}
                                                          isInvalid={touched.itemNumber && !!errors.itemNumber}
                                                          value={values.itemNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.itemNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId={'serialNumber'}>
                                            <Form.Label>Serial Number</Form.Label>
                                            <Form.Control required type="text" name={'serialNumber'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the Serial Number'}
                                                          isInvalid={touched.serialNumber && !!errors.serialNumber}
                                                          value={values.serialNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.serialNumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'brand'}>
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control type="text" name={'brand'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Brand name: Ex: iPhone/Samsung etc or Badge Level in case of badge'}
                                                          isInvalid={touched.brand && !!errors.brand}
                                                          value={values.brand}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.brand}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={'4'} controlId={'description'}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control required type="text" name={'description'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter Description'}
                                                          isInvalid={touched.description && !!errors.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'badge'}>
                                            <Form.Label>Is it a Badge</Form.Label>
                                            <Form.Control required as="select" name={'badge'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.badge && !!errors.badge}
                                                          value={values.badge}>
                                                {CommonUtils.getOptionsForSelect(isBadgeOrNotOptions)}

                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Checked Out By:</Form.Label>
                                            <Form.Control as="select" name={'checkedOutSubjectId'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'(Optional) Select who checked out the asset'}
                                                          isInvalid={touched.checkedOutSubjectId && !!errors.checkedOutSubjectId}
                                                          value={values.checkedOutSubjectId}
                                            >
                                                {CommonUtils.getOptionsForSelect(availEntities)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.checkedOutSubjectId}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId={'assetManagementStatus'}>
                                            <Form.Label>Asset Management (If lost/stolen etc)</Form.Label>
                                            <Form.Control as="select" name={'assetManagementStatus'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'(Optional) Select if your device was stolen/lost/destroyed/other'}
                                                          isInvalid={touched.assetManagementStatus && !!errors.assetManagementStatus}
                                                          value={values.assetManagementStatus}>
                                                {CommonUtils.getOptionsForSelect(assetManagementStatusOptions)}

                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    
                                   
                                   
                                    <Fragment>
                                        <Modal show={showModal} onHide={this.close}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>{"Asset creation confirmation"}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>{warningMessage}</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button onClick={()=>{
                                                    resetForm();
                                                    this.close();
                                                }} variant={"danger"}>No</Button>
                                                <Button onClick={this.onConfirm} variant={"primary"}>Yes</Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            {/* <Link to="/org/dash/asset" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="New Asset Creation Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                        </ButtonToolbar>
                                    </Fragment>
                                </Card.Body>
                            </Card>
                    </Form>
                )}
            </Formik>
        )
    }
}

export const AssetNew = connect(null, {showSuccessGrowl, showErrorGrowl})(AssetNewComponent);