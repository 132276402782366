import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { getDocTypes } from "../../../../actions/fieldValue_actions";
import { getLocComplianceDoc, downloadDocument } from "../../../../actions/doc_actions";

class FacilityDocListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeFilter: null,
        };
    }

    getColumns = () => {
        const { facilityNameDocumentMap } = this.state;
        return [{
            dataField: "facilityName",
            text: "Facility Name",
            formatter: (cell, row)=> row
        },
        {
            dataField: "fileName",
            text: "Document Name",
            formatter: (cell, row)=>facilityNameDocumentMap[row].name
        },
        {
            dataField: "effectiveDate",
            text: "Document Effective Date",
            formatter: (cell, row)=>facilityNameDocumentMap[row].latestDocumentEffectiveDate
        },
        {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar className="p-0" style={{ flexWrap: "nowrap" }}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(facilityNameDocumentMap[row].latestRevision.awsS3ObjectKey)}>
                        <span aria-hidden="true" style={{ paddingRight: 10 }} title={"Download Document"} aria-label="Download Document">
                            <i className="fa fa-circle-down"></i>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }];
    }

    getLocCompDoc = async (docTypeData) => {
        const payload = {
            typeFilter: docTypeData?.id
        };
        const response = await getLocComplianceDoc(payload);
        this.setState({ facilityNameDocumentMap: response});
    }

    componentDidMount() {
        getDocTypes((response) => {
            if (response.data) {
                const docTypeData = response.data.find((val) => val.type === this.props.match.params.docType);
                if (docTypeData) {
                    this.getLocCompDoc(docTypeData);
                }
            }
        });
    }

    render() {
        const { facilityNameDocumentMap } = this.state;

        if (!facilityNameDocumentMap || !Object.keys(facilityNameDocumentMap)) {
            return <div>Loading...</div>;
        }
        return (
            <div>
                <Fragment>
                    <div className="d-flex">
                        <h3 className="page_title_left">Documents</h3>
                    </div>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={Object.keys(facilityNameDocumentMap)} columns={this.getColumns()} filter={filterFactory()} />
                </Fragment>
            </div>
        );
    }
}

export const FacilityDocList = connect(null, { getDocTypes, getLocComplianceDoc })(FacilityDocListComp);
