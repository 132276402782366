/**
 * Created by pshivaraman on 4/7/18.
 */

import React from 'react';
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {fetchOrgFacilities, fetchOrgFacility, updateOrgFacility} from "../../../../actions/facility_actions";
import {
    FieldRBCountrySelector,
    FieldRBDualListBox,
    FieldRBInput,
    FieldRBRegionSelector,
    FieldRBSelectWithKeys
} from "../../../../components/redux_form/field_components_rb";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';

class FacilityUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.cancelUpdate = this.cancelUpdate.bind(this);
    }

    onSubmit(values) {
        if (values.level && values.level.id){
            values.level = values.level.id;
        }
        if (values.safeguardLevel && values.level.id){
            values.level = values.level.id;
        }
        this.props.updateOrgFacility(values, this.props.match.params.id, () => {
            this.cancelUpdate();
        });
    }

    cancelUpdate() {
        this.props.reset();
        this.didFormInit = false;
        this.props.history.push(`/org/dash/facility/${this.props.match.params.id}`);
    }

    componentDidMount() {
        this.didFormInit = false;
        this.props.fetchOrgFacility(this.props.match.params.id);
    }

    componentDidUpdate() {
        if (this.props.smo.id && this.didFormInit === false && this.props.smo.id === this.props.match.params.id) {
            this.props.initialize(this.props.smo); // This initializes the form with the values from the contract object
            // Names need to match the form field name
            this.didFormInit = true;
        }
    }

    getPersonOptions() {
        if (this.props.smo.id && this.props.smo.avail_subjects) {
            return this.props.smo.avail_subjects.map((subject) => {
                return {
                    value: person.id,
                    label: `${person.personFirstName} ${person.personLastName}`
                }
            });
        }
        return [];
    }

    getContractOptions() {
        if (this.props.smo.id && this.props.smo.avail_contracts) {
            return this.props.smo.avail_contracts.map((contract) => {
                return {
                    value: contract.contractId,
                    label: contract.name
                }
            });
        }
        return [];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/facility"
    //                             onClick={() => fetchOrgFacilities(sessionStorage.getItem(CUR_ORG))}>
    //                 Locations
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Location {this.props.smo.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {handleSubmit, sel_country, smo} = this.props;

        if (!smo || !smo.id) {
            return (
                <div>Loading...</div>
            );
        }
        if (smo.level && smo.level.id){
            smo.level = smo.level.id;
        }
        if (smo.safeguardLevel && smo.safeguardLevel.id){
            smo.safeguardLevel = smo.safeguardLevel.id;
        }
        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                {/* <h3>Edit Location</h3> */}
              
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

                    <div className='new_facility'>
                        <div className='section_title'><h4>Edit Location</h4></div>
                        <div className='col-lg-12 row m-0 pt-2 pb-4'>
                            <div className='col-lg-3'>
                                <Field name="name" component={FieldRBInput} horizontal label="Name" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="cageCode" component={FieldRBInput} horizontal label="Cage Code" maxLength="15" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="level" component={FieldRBSelectWithKeys} horizontal options={smo.levels}
                                    label="Location Level" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="safeguardLevel" component={FieldRBSelectWithKeys} horizontal options={smo.levels}
                                    label="Safeguarding Level" />
                            </div>
                        </div>
                    </div>

                    <div className='new_facility'>
                        <div className='section_title'><h4> Primary Address</h4></div>
                        <div className='col-lg-12 row m-0 pt-2 pb-4'>
                            <div className='col-lg-3'>
                                <Field name="suite" component={FieldRBInput} horizontal label="Suite No. or PO Box" />
                            </div>

                            <div className='col-lg-3'>
                                <Field name="street" component={FieldRBInput} horizontal label="Street Address" />
                            </div>

                            <div className='col-lg-3'>
                                <Field name="city" component={FieldRBInput} horizontal label="City" />
                            </div>

                            <div className='col-lg-3'>
                                <Field name="country" component={FieldRBCountrySelector} horizontal label="Country" />
                            </div>

                            <div className='col-lg-3'>
                                <Field name="state" component={FieldRBRegionSelector} horizontal country={sel_country}
                                    label="Region" />
                            </div>

                            <div className='col-lg-3'>
                                <Field name="zipcode" component={FieldRBInput} horizontal label="Postal or Zip Code" />
                            </div>
                        </div>
                    </div>


                    <div className='new_facility'>
                        <div className='section_title'><h4>Point of Contact Information</h4></div>
                        <div className='col-lg-12 row m-0 pt-2 pb-4'>
                            <div className='col-lg-3'>
                                <Field name="pocName" component={FieldRBInput} horizontal label="POC Name" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="pocPhone" component={FieldRBInput} horizontal label="POC Phone Number" type="tel" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="pocEmail" component={FieldRBInput} horizontal label="POC Email Address" type="email" />
                            </div>
                            <div className='col-lg-3'>
                                <Field name="timezone" component={FieldRBSelectWithKeys} horizontal label="Timezone"
                                    options={smo.timezones} />
                            </div>
                        </div>
                    </div>
                    
                    {/*<h4>Personnel</h4>*/}
                    {/*<Field name="servicingSubjects" label="Subjects Serviced" canFilter*/}
                           {/*component={FieldRBDualListBox}*/}
                           {/*options={this.getPersonOptions()}/>*/}
                    {/*<Field name="owningSubjects" label="Subjects Owned" canFilter component={FieldRBDualListBox}*/}
                           {/*options={this.getPersonOptions()}/>*/}

                    {/*<h4>Contracts</h4>*/}
                    {/*<Field name="contracts" label="Contracts" canFilter component={FieldRBDualListBox}*/}
                           {/*options={this.getContractOptions()}/>*/}

                    <ButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="danger" type="button" onClick={this.cancelUpdate}>Cancel</Button>
                    </ButtonToolbar>
                </Form>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (!values.street) {
        errors.street = "Street Address is required!";
    }
    if (!values.city) {
        errors.city = "City is required!";
    }
    if (!values.country) {
        errors.country = "Country is required!";
    }
    if (!values.zipcode) {
        errors.zipcode = "Postal or Zip Code is required!";
    }
    if (!values.pocName) {
        errors.pocName = "Point of Contact Name is required!";
    }
    if (!values.pocEmail) {
        errors.pocEmail = "Point of Contact Email Address is required!";
    }
    if (!values.pocPhone) {
        errors.pocPhone = "Point of Contact Phone Number is required!";
    }

    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.FacilityUpdateForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        smo: state.org_facility,
        sel_country: selCountry,
    }
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'FacilityUpdateForm',
    initialValues: {
        country: 'US'
    }
})(
    connect(mapStateToProps, {updateOrgFacility, fetchOrgFacility, fetchOrgFacilities})(FacilityUpdate)
);