/**
 * Created by pshivaraman on 6/22/18.
 */

export const validate_contract_new = (values) => {
    const errors = {};

    console.log('Values are ', values);
    if (!values.name) {
        errors.name = "Name is required!";
    }
    if (!values.status) {
        errors.status = "Status is required!";
    }
    if (!values.contractNo) {
        errors.contractNo = "Contract Number is required!";
    }
    if (!values.agency) {
        errors.agency = "Agency is required!";
    }

    if (values.specType && !values.specDate) {
        errors.specDate = "Specification Date is required!";
    }
    if (values.followsOn && !values.followsOnContractNo) {
        errors.followsOnContractNo = "Preceding Contract Number is required!";
    }
    if (values.finalDD254) {
        if (!values.finalReqDate){
            errors.finalReqDate = "Contractor Request Date is required!";
        }
        if (!values.finalReqPeriod){
            errors.finalReqPeriod = "Authorization Period is required!";
        }
    }
    return errors;
};


